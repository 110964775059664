import React, { useRef, useState } from "react";
import { Button, Card, CircularProgress, TextField } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "react-query";
import REQUESTS from "../../../../../net/requests";
import QUERY_KEYS from "../../../../../net/query-keys";
import ServerError from "../../../ServerError/ServerError";
import MastersItem from "../MastersItem";

const MastersList = () => {


  const mastersRequest = useQuery(QUERY_KEYS.MASTERS, REQUESTS.getMasters, { refetchOnMount: true });

  if (mastersRequest.isLoading) {
    return <CircularProgress />
  }
  if (mastersRequest.isError) {
    return <ServerError/>
  }

  const masters = mastersRequest.data;

  return (
    <>
      {
        masters.filter(master => !master.wasDeleted).map(master => <MastersItem key={master.id} master={master} />)
      }
    </>
  );
}

export default MastersList;