import LANGS from "../constants/langs";
import LocalStorageKeys from "../constants/localStorageKeys";
import { RestService } from "../net/RestService";

const findLang = () => {
  const title = localStorage.getItem(LocalStorageKeys.LANG);
  if (!title) {
    return undefined
  }
  return Object.values(LANGS).find(v => v.title.toLowerCase() === title)
}

const initialState = {
  user: null,
  master: null,
  lang: findLang() ?? LANGS.PL
};

export default initialState;
