import { styled, Typography } from "@mui/material";
import React from "react";
import styles from "./AdvicesPage.module.css"
import { useTranslation } from "react-i18next";
import PageLayout from "../../components/layouts/PageLayout";
import AdvicePreview from "./AdvicePreview";
import advices from "../../constants/advices";
import URLS from "../../constants/urls";

const AdvicesPage = () => {
  const { t } = useTranslation();

  return (
    <PageLayout>
      <div className={styles.box}>
        <Typography className={styles.title} variant="h4" align="center" >{ t('topMenuAdvices') }</Typography>

        <div className={styles.list}>
          { advices.map(item => <AdvicePreview key={item.titleKey} imageUrl={item.imageUrl} title={t(item.titleKey)} url={URLS.advicePage(item.urlPart)} /> ) }
        </div>
      </div>
    </PageLayout>
  );
};

export default AdvicesPage;
