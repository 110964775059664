import { Button, CircularProgress, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import styles from './RecordNote.module.css';
import { useState } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import RecordNoteDialog from "./RecordNoteDialog";
import QUERY_KEYS from "../../../../net/query-keys";
import REQUESTS from "../../../../net/requests";
import ServerError from "../../../widgets/ServerError/ServerError";
import { masterDisplayName } from "../../../../utils/formatUtils";

const RecordNote = ({ note, recordId, scheduleId, cacheKey }) => {
  const user = useSelector((state) => state?.user);
  const lang = useSelector((state) => state?.lang).title.toLowerCase();
  const [open, setOpen] = useState(false)
  const { t } = useTranslation();

  
  const mastersRequest = useQuery(QUERY_KEYS.MASTERS, REQUESTS.getMasters);

  if (mastersRequest.isLoading) {
    return <CircularProgress />
  }
  if (mastersRequest.isError) {
    return <ServerError/>
  }

  const masters = mastersRequest.data;

  const openNote = () => {
    setOpen(true)
  }

  const buildMasterName = (userId) => {
    const master = masters.find(master => master.userId === userId)
    return master ? `${masterDisplayName(master, lang)}:` : "Admin:"
  }

  return <div className={styles.box}>
    <div className={styles.caption}>
      <Typography>{ user.id === note.userId ? t('profileNoteYour') : buildMasterName(note.userId) }</Typography>
    </div>
    <div className={styles.note}>
      <Typography>{ !note || note.text === "" ? t('profileNoteEmpty') : (note?.text ?? t('profileNoteEmpty'))}</Typography>
      { user.id === note.userId && <div><Button variant="contained" sx={{ borderRadius: 12, textTransform: 'none' }} onClick={openNote}>{ t('profileNoteEdit') }</Button></div> }
    </div>
    { open && <RecordNoteDialog open={open} onClose={() => setOpen(false)} note={note} recordId={recordId} scheduleId={scheduleId} cacheKey={cacheKey} /> }
  </div>
}

export default RecordNote;