import React, { useRef, useState } from "react";
import { Button, ButtonGroup, Card, Checkbox, CircularProgress, List, MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { useMutation, useQuery } from "react-query";
import REQUESTS from "../../../../net/requests";
import QUERY_KEYS from "../../../../net/query-keys";
import ConsentsList from "../ConsentsList";
import ServerError from "../../ServerError/ServerError";
import UserListItem from "../UserListItem";
import styles from "./UsersList.module.css"
import SourcePlatform from "../../../../constants/sourcePlatform";

const UsersList = () => {

  const [source, setSource] = useState(null)
  const usersRequest = useQuery(QUERY_KEYS.USERS, REQUESTS.getUsers, { refetchOnMount: true })

  if (usersRequest.isLoading) {
    return <CircularProgress />
  }
  if (usersRequest.isError) {
    return <ServerError/>
  }

  const users = usersRequest.data.sort((a, b) => a.id - b.id)

  const handleChange = (event) => {
    setSource(event.target.value === "" ? null : parseInt(event.target.value))
  }

  return (
    <div className={styles.box}>
      <div>
        <ToggleButtonGroup
          color="primary"
          value={source}
          exclusive
          onChange={handleChange}
        >
          <ToggleButton sx={{textTransform: 'none'}} all value={null}>Все</ToggleButton>
          <ToggleButton sx={{textTransform: 'none'}} value={SourcePlatform.UNKNOWN}>Нет</ToggleButton>
          <ToggleButton sx={{textTransform: 'none'}} value={SourcePlatform.INST}>Instagram</ToggleButton>
          <ToggleButton sx={{textTransform: 'none'}} value={SourcePlatform.GOOGLE}>Google</ToggleButton>
          <ToggleButton sx={{textTransform: 'none'}} value={SourcePlatform.OTHER}>Другая</ToggleButton>
        </ToggleButtonGroup>
        <Typography sx={{mt:2}}>Пользователей: {users.filter(item => source === null || item.sourcePlatform === source).length}</Typography>
      </div>
      <List dense={true}>
        { users.filter(item => source === null || item.sourcePlatform === source).map(user => <UserListItem key={user.id} user={user} />) }
      </List>
    </div>
  );

}

export default UsersList;