import { combineReducers } from "redux";
import user from "./user";
import master from "./master";
import lang from "./lang";

const appReducer = combineReducers({
  user, master, lang
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
