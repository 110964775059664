import * as React from 'react';
import "./DatesList.css";
import { useSelector, useDispatch } from "react-redux";
import { IconButton, Grow, Paper, Popper, MenuItem, ClickAwayListener, MenuList, Fade, Dialog, DialogTitle, List, ListItem, ListItemButton, ListItemAvatar, Avatar, ListItemText, DialogContent, CircularProgress } from "@mui/material";

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DateItem from '../DateItem';
import ServerError from '../../widgets/ServerError/ServerError';
import DatesBox from '../DatesBox';
import DatesListBox from '../DatesListBox';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useFreeSlots } from '../../../net/net-hooks';

const DAY = 24 * 60 * 60 * 1000;
const FIND_RANGE = 90 * DAY;
const RESET_TIMEOUT = 1000;

dayjs.extend(utc)
dayjs.extend(timezone)

const buildDays = (freeSlots) => {
  const dates = [];
  if (!freeSlots || freeSlots.length === 0) {
    console.log("day picker, buildDays returns []");
    return dates;
  }
  console.log("day picker, first day:", dayjs(dayjs().format("YYYY-MM-DD")));
  console.log("day picker, first day2:", dayjs.utc(dayjs.utc().format("YYYY-MM-DD")).valueOf());
  console.log("day picker, first day3:", new Date(dayjs.utc(dayjs.utc().format("YYYY-MM-DD")).valueOf()));
  let time = dayjs.utc(dayjs.utc().format("YYYY-MM-DD")).valueOf();
  let lastDayTime = 0
  freeSlots.forEach(slot => {
    if (slot > lastDayTime) {
      lastDayTime = slot;
    }
  });
  const lastDay = dayjs.utc(dayjs.utc(lastDayTime).format("YYYY-MM-DD")).valueOf();
  console.log("day picker, last:", new Date(lastDay));
  while (time <= lastDay) {
    dates.push(time);
    time += DAY;
  }
  return dates;
}

const findFirstTime = (freeSlots) => {
  if (freeSlots.length > 0) {
    let firstDayTime = freeSlots[0];
    freeSlots.forEach(slot => {
      if (slot < firstDayTime) {
        firstDayTime = slot;
      }
    });
    return firstDayTime;
  }
  return null;
}

const findExistTime = (freeSlots) => {
  if (freeSlots.length > 0) {
    let firstDayTime = freeSlots[0];
    freeSlots.forEach(slot => {
      if (slot < firstDayTime) {
        firstDayTime = slot;
      }
    });
    return firstDayTime;
  }
  return null;
}

const DatesList = ({ selectedDay, master, service, emptySlots, dayListener }) => {
  const startDate = dayjs(dayjs().format("YYYY-MM-DD")).valueOf();
  const endDate = startDate + FIND_RANGE;
  const listRef = React.useRef()
  const slotsRef = React.useRef([])
  const prevMasterRef = React.useRef()

  const handleDaySelection = (day) => {
    if (dayListener && selectedDay !== day) {
      dayListener({ day, times: slotsRef.current});
    }
  }

  console.log('start find slots for startDate:', startDate, ', endDate:', endDate);
  const freeSlotsRequest = useFreeSlots(service, master, startDate, endDate)

  if (freeSlotsRequest.isLoading) {
    return <CircularProgress />
  }
  if (freeSlotsRequest.isError) {
    return <ServerError/>
  }

  const freeSlots = freeSlotsRequest.data.times.filter(v => v > Date.now());
  slotsRef.current = freeSlots;
  const dates = buildDays(freeSlots);

  if (!selectedDay) {
    const firstTime = findFirstTime(freeSlots);
    const firstDay = dayjs.utc(dayjs.utc(firstTime).format("YYYY-MM-DD")).valueOf()
    handleDaySelection(firstDay);
  } else if (prevMasterRef.current !== master) {
    // const firstTime = findExistTime(selectedDay, freeSlots);
    // const firstDay = dayjs.utc(dayjs.utc(firstTime).format("YYYY-MM-DD")).valueOf()
    // handleDaySelection(firstDay);
    
    if (dayListener) {
      dayListener({ day: selectedDay, times: slotsRef.current});
    }
  }

  if (emptySlots && freeSlots.length === 0) {
    emptySlots()
  }

  prevMasterRef.current = master;

  const handleBoxResize = (width) => {
    // TODO change width of each item
  };

  const mouseMove = () => {
    const firstDay = 0;
  }

  const factory = (clickListener) => {
    return dates.map(date => {
      return <DateItem key={date} date={date} selected={date === selectedDay} onClick={() => clickListener(date)}/>
    })
  }

  return (
    <>
    <DatesBox>
      <div id='book-dialog-dates-list'>
        <IconButton onClick={() => { listRef.current.scroll({ left: listRef.current.scrollLeft - (listRef.current.offsetWidth), behavior: 'smooth'}) }}><ChevronLeftIcon/></IconButton>
        <DatesListBox dates={dates} selectedDay={selectedDay} setSelectedDay={handleDaySelection} listRef={listRef} factory={factory}>
        { 
          // dates.map(date => {
          //   return <DateItem key={date} date={date} selected={date === selectedDay} onClick={() => setSelectedDay(date)}/>
          // })
        }
        </DatesListBox>
        
        <IconButton onClick={() => { listRef.current.scroll({ left: listRef.current.scrollLeft + (listRef.current.offsetWidth), behavior: 'smooth'}) }}><ChevronRightIcon/></IconButton>
      </div>
      </DatesBox>
    </>
  );
};

export default DatesList;
