import * as React from 'react';
import { Button, Checkbox, FormControlLabel, IconButton, TextField, Typography, styled } from "@mui/material";
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';

dayjs.extend(isToday);

const ProfileMasterNotifications = ({ settings, onChange }) => {
  const [emailEnabled, setEmailEnabled] = React.useState(settings.emailNotification);
  const [recordCreated, setRecordCreated] = React.useState(settings.recordCreated);
  const [recordDeleted, setRecordDeleted] = React.useState(settings.recordDeleted);
  const [recordMoved, setRecordMoved] = React.useState(settings.recordMoved);
  const [workTimeAdded, setWorkTimeAdded] = React.useState(settings.workTimeAdded);
  const [workTimeRemoved, setWorkTimeRemoved] = React.useState(settings.workTimeRemoved);
  const [recordConfirmation, setRecordConfirmation] = React.useState(settings.recordConfirmation);

  const notify = (data) => {
    if (onChange) {
      const state = {
        emailNotification: emailEnabled,
        recordCreated,
        recordDeleted,
        recordMoved,
        workTimeAdded,
        workTimeRemoved,
        recordConfirmation
      }
      onChange({
        ...state,
        ...data
      })
    }
  }
  const handleEmailChange = (event) => {
    setEmailEnabled(event.target.checked);
    if (!event.target.checked) {
      setRecordCreated(settings.recordCreated);
      setRecordDeleted(settings.recordDeleted);
      setRecordMoved(settings.recordMoved);
      setWorkTimeAdded(settings.workTimeAdded);
      setWorkTimeRemoved(settings.workTimeRemoved);
      setRecordConfirmation(settings.recordConfirmation);
    }
    notify({ emailNotification: event.target.checked });
  }
  const handleRecordCreated = (event) => {
    setRecordCreated(event.target.checked);
    notify({ recordCreated: event.target.checked });
  }
  const handleRecordDeleted = (event) => {
    setRecordDeleted(event.target.checked);
    notify({ recordDeleted: event.target.checked });
  }
  const handleRecordMoved = (event) => {
    setRecordMoved(event.target.checked);
    notify({ recordMoved: event.target.checked });
  }
  const handleWorkTimeAdded = (event) => {
    setWorkTimeAdded(event.target.checked);
    notify({ workTimeAdded: event.target.checked });
  }
  const handleWorkTimeRemoved = (event) => {
    setWorkTimeRemoved(event.target.checked);
    notify({ workTimeRemoved: event.target.checked });
  }
  const handleRecordConfirmation = (event) => {
    setRecordConfirmation(event.target.checked);
    notify({ recordConfirmation: event.target.checked });
  }

  return (
    <div>
      <Typography sx={{ marginTop: 2 }}>Master notifications:</Typography>
      <FormControlLabel checked={emailEnabled} onChange={handleEmailChange} control={<Checkbox />} label="Email notifications" />
      <Typography sx={{ marginTop: 1 }}>Allow next types of notifications:</Typography>
      <FormControlLabel disabled={!emailEnabled} checked={recordCreated} onChange={handleRecordCreated} control={<Checkbox />} label="New Record" />
      <FormControlLabel disabled={!emailEnabled} checked={recordDeleted} onChange={handleRecordDeleted} control={<Checkbox />} label="Record is deleted" />
      <FormControlLabel disabled={!emailEnabled} checked={recordMoved} onChange={handleRecordMoved} control={<Checkbox />} label="Record time is changed" />
      <FormControlLabel disabled={!emailEnabled} checked={recordConfirmation} onChange={handleRecordConfirmation} control={<Checkbox />} label="Record confirmations" />
      <FormControlLabel disabled={!emailEnabled} checked={workTimeAdded} onChange={handleWorkTimeAdded} control={<Checkbox />} label="Work time is added" />
      <FormControlLabel disabled={!emailEnabled} checked={workTimeRemoved} onChange={handleWorkTimeRemoved} control={<Checkbox />} label="Work time is removed" />

    </div>
  );
};

export default ProfileMasterNotifications;
