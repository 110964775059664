
import dayjs from 'dayjs';
import styles from './ServiceWidget.module.css'
import { Avatar, Typography, useMediaQuery } from '@mui/material';
import TranslationTypes from '../../../../constants/translationTypes';
import { useSelector } from 'react-redux';

const ServiceWidget = ({ record, withPhoto, centerOnSmall }) => {
  const lang = useSelector(state => state.lang).title.toLowerCase();
  const maxW700 = useMediaQuery('(max-width:720px)') && centerOnSmall;
  
  const findText = (type) => record.translations.find(v => v.type === type && v.lang === lang)?.text ?? ""
  const serviceTitle = findText(TranslationTypes.SERVICE_TITLE);
  
  return <div className={styles.serviceBox}>
    { withPhoto && <Avatar alt={ serviceTitle } src={ record.serviceImage } sx={{ width: 40, height: 40 }} /> }
    <div className={styles.serviceName}>
      <Typography sx={{ fontWeight: 700 }} align={ maxW700 ? 'center' : ''}>{ serviceTitle }</Typography>
      <Typography align={ maxW700 ? 'center' : ''}>{ findText(TranslationTypes.SERVICE_SUBTITLE) }</Typography>
    </div>
  </div>;
}

export default ServiceWidget;