import { useMutation, useQueryClient } from 'react-query';
import styles from './ConfirmStatusWidget.module.css'
import { Button, Icon, IconButton, Typography } from '@mui/material';
import REQUESTS from '../../../../../net/requests';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import QUERY_KEYS from '../../../../../net/query-keys';
import { logAndReset } from '../../../../../utils/requestUtils';
import ConfirmationDialog from '../../../../../components/dialogs/ConfirmationDialog';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import DeleteIcon from '@mui/icons-material/Delete';

const ONE_DAY = 24 * 60 * 60 * 1000;

const ConfirmStatusWidget = ({ record }) => {
  const user = useSelector((state) => state?.user);
  const [showDialog, setShowDialog] = useState(false);

  const removeMutation = useMutation(REQUESTS.removeRecord(record.id))
  const confirmMutation = useMutation(REQUESTS.confirmRecordByClient(record.id))
  const queryClient = useQueryClient();

  const askConfirmation = (!record.hasInitUserConfirmation) || (!record.hasFinalUserConfirmation && record.startTime - Date.now() <= ONE_DAY)
  
  const handleDelete = () => {
    removeMutation.mutate({}, {
      onSuccess: (data, error, variables, context) => {
        console.log(`Removed!`);
        
        setTimeout(() => {
          queryClient.invalidateQueries(QUERY_KEYS.getUserRecords(record.clientId));
          removeMutation.reset()
        }, 3000)
      },
      onError: (error, variables, context) => {
        console.log(`onError:`, JSON.stringify(error))
        logAndReset(removeMutation);
      }
    });
  }

  const handleConfirm = () => {
    confirmMutation.mutate({}, {
      onSuccess: (data, error, variables, context) => {
        setTimeout(() => {
          queryClient.invalidateQueries(QUERY_KEYS.getUserRecords(record.clientId));
          confirmMutation.reset()
        }, 3000)
      },
      onError: (error, variables, context) => {
        console.log(`onError:`, JSON.stringify(error))
        logAndReset(confirmMutation);
      }
    });
  }

  return <div className={styles.box}>
    { record.hasMasterConfirmation ? <AlarmOnIcon color="success"/> : <AccessAlarmIcon /> }
    {/* <Typography>{ record.hasMasterConfirmation ? "Confirmed by master" : "Waiting for master confirmation" }</Typography> */}
  </div>;
}

export default ConfirmStatusWidget;