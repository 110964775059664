import React from "react";
import { Avatar, Button, CircularProgress, Paper, TextField, Typography } from '@mui/material';
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import URLS from "../../../../../constants/urls";
import QUERY_KEYS from "../../../../../net/query-keys";
import REQUESTS from "../../../../../net/requests";
import ServerError from "../../../../widgets/ServerError/ServerError";
import styles from './QuestionsView.module.css'
import { useTranslation } from "react-i18next";

const QuestionsView = ({ profile }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state?.user);

  const history = useHistory();

  const userId = profile ? profile.id : user.id;
  const answersStateRequest = useQuery(QUERY_KEYS.getAnswersState(userId), REQUESTS.getAnswersState(userId), { refetchOnMount: true })

  
  const openQuestionnaire = () => {
    history.push(URLS.questionnairePage(userId))
  }
  
  if (answersStateRequest.isLoading) {
    return <CircularProgress />
  }
  if (answersStateRequest.isError) {
    return <ServerError/>
  }

  const answersState = answersStateRequest.data;

  return (
    <>
    {
      !answersState.hasAnswers ?
        <div className={styles.box}>
          <Typography>{ t('questionnaireShare') }</Typography>
          <Button variant="outlined" onClick={openQuestionnaire}>{ t('questionnaireLetsGo') }</Button>
        </div> :
        
          answersState.outdated ? 
            <div className={styles.box}>
              <Typography>{ t('questionnaireNewQuestions') }</Typography>
              <Button variant="outlined" onClick={openQuestionnaire}>{ t('questionnaireLetsGo') }</Button>
            </div> :

              <div className={styles.box}>
                <Typography>{ t('questionnaireEditAnswers') }</Typography>
                <Button variant="outlined" onClick={openQuestionnaire}>{ t('questionnaireLetsGo') }</Button>
              </div>
    }
      
    </>
  );
};

export default QuestionsView;