import React from "react";
import styles from "./NotFoundPage.module.css"
import PageLayout from "../../components/layouts/PageLayout";

const NotFoundPage = () => {
  return (
    <PageLayout fullscreen={true}>
      <div className={styles.notFoundPage}>
        <p>404</p>
        <p>NOT FOUND</p>
      </div>
    </PageLayout>
  );
};

export default NotFoundPage;
