import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { IconButton, Dialog, DialogTitle, DialogContent, CircularProgress, Button, Divider, styled, FormControl, InputLabel, Select, MenuItem, TextField } from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import dayjs, { Dayjs } from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import QUERY_KEYS from '../../../net/query-keys';
import REQUESTS from '../../../net/requests';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { logAndReset } from '../../../utils/requestUtils';
import { useState } from 'react';
import ServerError from '../../widgets/ServerError/ServerError';
import { useTranslation } from 'react-i18next';

dayjs.extend(isToday);

const BlurryDialog = styled(Dialog)(({ theme }) => ({
  backdropFilter: "blur(5px)"
}));

const AddServiceToMasterDialog = ({ onClose, open, master }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state?.user);
  const lang = useSelector((state) => state?.lang).title.toLowerCase();

  const [service, setService] = useState("")
  const [minPrice, setMinPrice] = useState("0")
  const [maxPrice, setMaxPrice] = useState("0")
  const [time, setTime] = useState("60")

  const queryClient = useQueryClient();
  const addServiceMutation = useMutation(REQUESTS.addServiceToMaster)
  const servicesRequest = useQuery(QUERY_KEYS.SERVICES, REQUESTS.getServices, { refetchOnMount: true });

  if (servicesRequest.isLoading) {
    return <CircularProgress />
  }
  if (servicesRequest.isError) {
    return <ServerError/>
  }

  const services = servicesRequest.data;

  const handleAddService = () => {
    const data = {
      masterId: master.id,
      serviceId: service.id,
      minPrice: Number(minPrice),
      maxPrice: Number(maxPrice),
      timeRequirements: Number(time)
    }
    console.log('adding service to master:', data);
    addServiceMutation.mutate(data, {
      onSuccess: (data, error, variables, context) => {
        console.log('service list is updated!')
        queryClient.invalidateQueries(QUERY_KEYS.getMasterServices(master.id));
        setTimeout(() => {
          addServiceMutation.reset()
        }, 3000)
      },
      onError: () => {
        logAndReset(addServiceMutation)
      }
    });
  }

  const handleClose = (event) => {
    onClose();
  };

  const handleChange = (event) => {
    setService(event.target.value);
  };

  const findText = (titles) => titles.find(translation => translation.lang === lang)?.text;

  return (
    <>
      <BlurryDialog onClose={handleClose} open={open} fullWidth={true} maxWidth="sm">
        <DialogTitle sx={{ m: 0, paddingRight:7 }}>
          { t('addService') }
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 12,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent >
          <div>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Service</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={service}
                label="Service"
                onChange={handleChange}
              >
                {
                  services.map(item => <MenuItem value={item}>{ `${findText(item.titles)} - ${findText(item.subtitles)}` }</MenuItem>)
                }
              </Select>
              <TextField value={minPrice} onChange={event => setMinPrice(event.target.value)} label="min price" type="number" sx={{marginTop: 2}} />
              <TextField value={maxPrice} onChange={event => setMaxPrice(event.target.value)} label="max price" type="number" sx={{marginTop: 2}} />
              <TextField value={time} onChange={event => setTime(event.target.value)} label="time" type="number" sx={{marginTop: 2}} />
            </FormControl>

            {
              addServiceMutation.isLoading ? ('Adding master...') :
                addServiceMutation.isError ? <div>An error occurred: { addServiceMutation.error.message }</div> : 
                  addServiceMutation.isSuccess ? <div>Service was added!</div> : 
                    <Button variant='outlined' onClick={handleAddService} sx={{marginTop: 2}} >Add</Button>
            }
            
          </div>
        </DialogContent>
      </BlurryDialog>
    </>
  );
};

export default AddServiceToMasterDialog;
