import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import styles from "./PhoneLink.module.css"
import { Link, Typography } from "@mui/material";

const PhoneLink = ({ color, fontSize }) => {
  const value = "+48 512 699 160";
  return (
    <Link href={ `tel:${value}` } className={ styles.box }>
      <LocalPhoneIcon sx={{ color }}/>
      <Typography sx={{ color, fontSize, textDecoration: 'underline' }}>{ value }</Typography>
    </Link>
  );
};

export default PhoneLink;