import { Button, Card, CardActions, CardContent, Stack, styled, TextField, Typography } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { blue } from '@mui/material/colors';
import { useQuery } from "react-query";
import QUERY_KEYS from "../../net/query-keys";
import REQUESTS from "../../net/requests";
import BookSteps from "../../constants/bookSteps";
import SelectService from "../../components/widgets/Book/SelectService";
import SelectMaster from "../../components/widgets/Book/SelectMaster";
import styles from "./RestorePage.module.css"
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom/cjs/react-router-dom";
import URLS from "../../constants/urls";
import GoogleIcon from "../../svg/ic_google.svg"
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useDispatch } from "react-redux";
import { loginUser } from "../../utils/loginUtils";
import PageLayout from "../../components/layouts/PageLayout";

const Text = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.onBackground,
  padding: theme.spacing(1)
}));

const GoogleButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(blue[500]),
  backgroundColor: blue[500],
  '&:hover': {
    backgroundColor: blue[700],
  },
}));

const RestorePage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [email, setEmail] = useState("")
  const [emailSent, setEmailSent] = useState(false)
  const [error, setError] = useState()
  const dispatch = useDispatch();

  const [bookState, setBookState] = useState({ step: BookSteps.SELECT_SERVICE })
  const emailRef = useRef();
  const passwordRef = useRef();

  const handleRestore = async () => {
    const auth = getAuth();
    try {
      await sendPasswordResetEmail(auth, email);
      setEmailSent(true);
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log("errorCode:", errorCode,"errorMessage:", errorMessage);
      
      setError(error.code)
      setTimeout(() => {
        setError(null);
      }, 3000);
    }
  }

  return (
    <PageLayout fullscreen={true}>
      <div className={styles.registrationPage}>
          <Card sx={{ minWidth: 275, padding: 2 }}>
            <CardContent>
              <Stack
                component="form"
                spacing={2}
                noValidate
                autoComplete="off"
              >
                <Typography align='center'>{ t('authRestorePassword') }</Typography>
                <TextField type="email" label={ t('authFormEmail') } value={email} onChange={event => setEmail(event.target.value)}/>
                {
                  emailSent ? 
                    <Typography>{ t('authRestoreEmailSent') }</Typography> :
                    <Button size="large" variant="outlined" onClick={handleRestore}>{ t('authRestore') }</Button>
                }
                {
                  error && <Typography align='center'>{ error }</Typography>
                }
                <Button size="large" variant="outlined" onClick={() => history.replace(URLS.LOGIN)}>{ t('authBackToLogin') }</Button>
              </Stack>
            </CardContent>
          </Card>
        
      </div>
    </PageLayout>
  );
};

export default RestorePage;
