const RELEASES_PATH = '/releases'
const PROFILES = "/profiles"
const CONSENTS = "/consents"
const CATEGORIES = "/categories"
const QUESTIONNAIRE = "/questionnaire"
const REGISTRATION = "/register"
const ADVICES = "/advices"

const URLS = {
  ROOT_URL: "/",
  NOT_FOUND: "/404",
  ANY: "*",
  SUBSCRIPTIONS: `/subscriptions`,
  GIFTS: `/gifts`,
  SERVICES: "/services",
  PROFILE: `${PROFILES}/:id`,
  CONTACTS: "/contacts",
  CONSENTS: "/consents/:id",
  CATEGORY: "/categories/:id",
  LOGIN: "/login",
  RESTORE_PASSWORD: "/restore",
  REGISTRATION: "/register",
  QUESTIONNAIRE: "/questionnaire/:id",
  CODE_CONFIRM: "/confirm",
  CODE_REJECT: "/reject",
  RECORDS_HISTORY: "/records",
  ADVICES: ADVICES,

  profileOf: (userId) => `${PROFILES}/${userId}`,
  categoryOf: (categoryId) => `${CATEGORIES}/${categoryId}`,
  consentPage: (consentId) => `${CONSENTS}/${consentId}`,
  questionnairePage: (userId) => `${QUESTIONNAIRE}/${userId}`,
  advicePage: (part) => `${ADVICES}/${part}`,
  invitePage: (inviteCode) => `https://facetime-cosmetology.pro${REGISTRATION}?code=${inviteCode}`
};

export const Pages = {
  getReleasePage: (id) => `${RELEASES_PATH}/${id}`
}

export default URLS;
