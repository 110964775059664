import React, { useRef, useState } from "react";
import styles from "./History.module.css"
import { Paper, styled } from "@mui/material";
import HistoryList from "./HistoryList";
import ExpandMoreItem from "../../../components/widgets/ControlPanelItems/ExpandMoreItem2";

const ProfilePaper = styled(Paper)(({ theme }) => ({
  maxWidth: 1100,
  width: "100%",
  paddingLeft: 16,
  paddingRight: 16,
  padding: theme.spacing(2),
  marginTop: 16,
  ...theme.typography.body2,
  textAlign: 'center',
}));

const History = ({ profile }) => {

  return (
    <div className={styles.box}>
      <ProfilePaper variant="outlined" sx={{ borderRadius: 2 }}>
        <ExpandMoreItem text="History" sx={{ padding: 0}}>

          <HistoryList profile={profile}/>

        </ExpandMoreItem>
      </ProfilePaper>
    </div>
  );
}

export default History;