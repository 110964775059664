import ReactGA from "react-ga4";

ReactGA.initialize([{
  trackingId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}, {
  trackingId: "AW-16574414217"
}]);


const trackPathForAnalytics = ({ path, title }) => {
  ReactGA.send({ hitType: "pageview", page: path, title });
};

export const trackConversion = () => {
  if (process.env.REACT_APP_ENV === "production") {
    console.log("trackConversion");
    ReactGA.gtag('event', 'conversion', {'send_to': 'AW-16574414217/GYSqCLHQqLIZEIn7pd89'});
  }
};

export const trackBookingPage = (page, key) => {
  //if (process.env.REACT_APP_ENV === "production") {
    const event = { category: "booking_dialog", action: page, label: key }
    console.log("trackBookingPage", event);
    ReactGA.event(event);
  //}
};

export default trackPathForAnalytics;