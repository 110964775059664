import { TextField, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import styles from "./TextWithInputView.module.css"
import { useState } from "react";

const TextWithInputView = ({ question, answer, onChange, ref, enabled }) => {

  const [value, setValue] = useState(answer?.payload.value ? answer.payload.value : "");
  const lang = useSelector(state => state.lang);

  const text = question.translations.find(v => v.lang === lang.title.toLowerCase())?.text ?? ""

  if (ref) {
    ref.current = { questionId: question.id, value }
  }

  const handleChange = (event) => {
    setValue(event.target.value);
    if (onChange) {
      onChange(event.target.value)
    }
  }

  return (
    <div className={styles.box}>
      <Typography>{ text }</Typography>
      <TextField 
        value={value} 
        onChange={handleChange} 
        disabled={!enabled} 
        multiline 
        fullWidth 
        InputProps={{
          rows: 4,
          multiline: true,
          inputComponent: 'textarea'
        }}
      />
    </div>
  );
};

export default TextWithInputView;