import { Checkbox, FormControlLabel, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import styles from "./TextWithYesNoView.module.css"
import { useState } from "react";
import { useTranslation } from "react-i18next";

const TextWithYesNoView = ({ question, answer, onChange, enabled }) => {

  const [value, setValue] = useState(answer?.payload?.value === true ? "yes" : answer?.payload?.value === false ? "no" : "")
  const lang = useSelector(state => state.lang);
  const { t } = useTranslation()

  const text = question.translations.find(v => v.lang === lang.title.toLowerCase())?.text ?? ""

  const handleChange = (event) => {
    if (enabled === true) {
      setValue(event.target.value);
      if (onChange) {
        onChange(event.target.value === "yes")
      }
    }
  }

  return (
    <div className={styles.box}>
      <Typography>{ text }</Typography>
      <RadioGroup row value={value} onChange={handleChange} >
        <FormControlLabel value="no" control={<Radio />} label={ t('questionnaireNo') } />
        <FormControlLabel value="yes" control={<Radio />} label={ t('questionnaireYes') } />
      </RadioGroup>
    </div>
  );
};

export default TextWithYesNoView;