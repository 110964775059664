import React from "react";
import "./ServerError.css";

const ServerError = ({ children }) => {

  return (
    <>
      <h2>Error</h2>
    </>
  );
};

export default ServerError;