import * as React from 'react';
import "./ConsentsBlock.css";
import { Checkbox, CircularProgress } from "@mui/material";
import Text from '../../widgets/Text';
import { red } from '@mui/material/colors';
import ConsentTypes from '../../../constants/consentTypes';
import QUERY_KEYS from '../../../net/query-keys';
import REQUESTS from '../../../net/requests';
import ConsentBlockItem from '../ConsentBlockItem';
import { useQuery } from 'react-query';
import ServerError from '../../widgets/ServerError/ServerError';

const ConsentsBlock = ({ policyRef, error }) => {
  const [accepted, setAccepted] = React.useState()
  
  const consentsRequest = useQuery(QUERY_KEYS.getConsentForType(ConsentTypes.RECORD), REQUESTS.getConsentForType(ConsentTypes.RECORD), { refetchOnMount: true })
  
  if (consentsRequest.isLoading) {
    return <CircularProgress />
  }
  if (consentsRequest.isError) {
    return <ServerError/>
  }

  const consents = consentsRequest.data.consents;
  const translations = consentsRequest.data.translations;

  consents.forEach(consent => {
    if (policyRef.current[consent.key] === undefined) {
      policyRef.current[consent.key] = { checked: false, required: consent.required };
    }
    if (error[consent.key] === undefined) {
      error[consent.key] = false;
    }
  })

  return (
    <>
      <div id='consents-block'>
        {
          consents.map(consent => <ConsentBlockItem policyRef={policyRef} error={error} consent={consent} translations={translations.filter(v => v.itemId === consent.id)} />)
        }
        
      </div>
    </>
  );
};

export default ConsentsBlock;
