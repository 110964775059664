import React, { useRef, useState } from "react";
import { Button, Card, TextField, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import styles from "./MastersItem.module.css"
import MasterServices from "../MasterServices";
import REQUESTS from "../../../../../net/requests";
import QUERY_KEYS from "../../../../../net/query-keys";
import { logAndReset } from "../../../../../utils/requestUtils";
import AddServiceToMasterDialog from "../../../../dialogs/AddServiceToMasterDialog";
import ExpandMoreItem from "../../ExpandMoreItem";
import { useTranslation } from "react-i18next";
import EditMasterDialog from "../../../../dialogs/EditMasterDialog";
import { useSelector } from "react-redux";
import TranslationTypes from "../../../../../constants/translationTypes";
import ConfirmDeleteMasterDialog from "../../../../dialogs/ConfirmDeleteMasterDialog";

const MastersItem = ({ master }) => {

  const lang = useSelector((state) => state?.lang).title.toLowerCase();
  const [showDialog, setShowDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const addServiceMutation = useMutation(REQUESTS.addServiceToMaster)
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  
  const handleAddService = () => {
    const data = {
    }
    console.log('adding service to master:', data);
    addServiceMutation.mutate(data, {
      onSuccess: (data, error, variables, context) => {
        console.log('service list is updated!')
        queryClient.invalidateQueries(QUERY_KEYS.getMasterServices(master.id));
        setTimeout(() => {
          addServiceMutation.reset()
        }, 3000)
      },
      onError: () => {
        logAndReset(addServiceMutation)
      }
    });
  }

  const findName = () => master.translations.find(t => t.lang === lang && t.type === TranslationTypes.MASTER_NAME)?.text ?? master.name;
  const findSurname = () => master.translations.find(t => t.lang === lang && t.type === TranslationTypes.MASTER_SURNAME)?.text ?? master.surname;

  return (
    <div className={styles.box}>
      <div className={styles.masterInfo}> 
        <img src={master.imageUrl} className={styles.image}/>
        <div className={styles.info}>
          <Typography>master id: { master.id }, user id: { master.userId }</Typography>
          <Typography>name: { findName() }</Typography>
          <Typography>surname: { findSurname() }</Typography>
          <Typography>profession: { master.profession }</Typography>
          <Typography>description: { master.description }</Typography>
        </div>
      </div>
      <div className={styles.panel}>
        <div><Button variant="outlined" onClick={() => setShowEditDialog(true)}>{ t('edit') }</Button></div>
        <div><Button variant="outlined" onClick={() => setShowDialog(true)}>{ t('addService') }</Button></div>
        <div><Button variant="outlined" onClick={() => setShowDeleteConfirmation(true)}>{ t('removeMaster') }</Button></div>
        <ExpandMoreItem text="Available Services">
          <MasterServices master={master}/>
        </ExpandMoreItem>
        
      </div>
      <AddServiceToMasterDialog onClose={() => setShowDialog(false)} open={showDialog} master={master} />
      { showEditDialog && <EditMasterDialog onClose={() => setShowEditDialog(false)} open={showEditDialog} master={master} /> }
      { showDeleteConfirmation && <ConfirmDeleteMasterDialog onClose={() => setShowDeleteConfirmation(false)} open={showDeleteConfirmation} master={master} /> }
    </div>
  );
}

export default MastersItem;