import MailOutlineIcon from '@mui/icons-material/MailOutline';
import styles from "./EmailLink.module.css"
import { Link, Typography } from "@mui/material";

const EmailLink = ({ color, fontSize }) => {
  const email = "facetimecosmetology@gmail.com";
  return (
    <Link href={ `mailto:${email}` } className={ styles.box }>
      <MailOutlineIcon sx={{ color }}/>
      <Typography sx={{ color, fontSize, textDecoration: 'underline' }}>{ email }</Typography>
    </Link>
  );
};

export default EmailLink;