import React from "react";
import styles from "./TopMenu.module.css";
import URLS from "../../../constants/urls";
import { Link } from "react-router-dom";
import { Button, Menu, MenuItem } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import QUERY_KEYS from "../../../net/query-keys";
import REQUESTS from "../../../net/requests";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import TopMenuButton from "./button/TopMenuButton";
import MenuFullscreen from "./fullscreen/MenuFullscreen";
import LANGS from "../../../constants/langs";
import OrderDialog from "../../dialogs/OrderDialog";
import { bookButtonStyle } from "../../../styles/mainTheme";
import BookButtonWhite from "../BookButtonWhite";

const TopMenu = ({ children }) => {
  const [showSubServices, setShowSubServices] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [fullscreenMenuOpen, setFullscreenMenuOpen] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(false);
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()
  const lang = useSelector(state => state.lang)
  const user = useSelector(state => state.user);

  const categoriesRequest = useQuery(QUERY_KEYS.CATEGORIES, REQUESTS.getCategories)

  function handleClick(event) {
    console.log("onMouseOver");
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const openPage = (category) => {
    history.push(URLS.categoryOf(category.id));
  }

  const handleOpen = (isOpen) => {
    setFullscreenMenuOpen(isOpen)
  }

  const openBookDialog = () => {
    setFullscreenMenuOpen(false)
    setShowDialog(true);
    // if (user) {
    //   setShowDialog(true);
    // } else {
    //   history.push(URLS.LOGIN, { from: location.pathname });
    // }
  }

  const isConsultation = (category) => category.translations.find(translation => translation.lang === LANGS.EN.title.toLowerCase())?.text.indexOf('Consultation')

  const subServices = <>
  </>
  return (
    <>
      <ul className={styles.topMenuBox}>
        <li className={styles.topMenuItem}><Link to={URLS.ROOT_URL}>{t('topMenuMain')}</Link></li>
        <li className={styles.topMenuItem} onMouseLeave={() => setShowSubServices(false)}>
          <Link to={URLS.SERVICES} onMouseOver={() => setShowSubServices(true)} >{t('topMenuServices')}</Link>
          {
            showSubServices && categoriesRequest.isLoading === false && categoriesRequest.isError === false && 
            <ul className={styles.topMenuBoxServices}>
              {
                categoriesRequest.data.map(category => 
                  isConsultation(category) !== -1 ? null :
                    <li onClick={() => openPage(category)}>
                      <p>{ category.translations.find(translation => translation.lang === lang.title.toLowerCase())?.text }</p>
                    </li>
                )
              }
            </ul>
          }
        </li>
        {
          categoriesRequest.isLoading === false && categoriesRequest.isError === false && categoriesRequest.data.map(category => 
            isConsultation(category) !== -1 ? 
              <li className={styles.topMenuItem} onClick={() => openPage(category)}>
                <Link to={URLS.categoryOf(category.id)}>{ category.translations.find(translation => translation.lang === lang.title.toLowerCase())?.text }</Link>
              </li> 
              : null
          )
        }
        <li className={styles.topMenuItem}><Link to={URLS.SUBSCRIPTIONS}>{t('topMenuSubscriptions')}</Link></li>
        <li className={styles.topMenuItem}><Link to={URLS.GIFTS}>{t('topMenuGiftCards')}</Link></li>
        <li className={styles.topMenuItem}><Link to={URLS.ADVICES}>{t('topMenuAdvices')}</Link></li>
        <li className={styles.topMenuItem}><Link to={URLS.CONTACTS}>{t('topMenuContacts')}</Link></li>
        <li className={styles.topMenuItem}><BookButtonWhite onClick={openBookDialog} /></li>
      </ul>
      <div className={styles.topMenuButton}>
        <TopMenuButton isOpen={fullscreenMenuOpen} onOpen={handleOpen}/>
      </div>
      {
        fullscreenMenuOpen && <div className={styles.topMenuFullscreen}><MenuFullscreen onClose={handleOpen} onBookClicked={openBookDialog}/></div>
      }
      
      { showDialog && <OrderDialog onClose={() => setShowDialog(false)} open={showDialog} /> }
    </>
  );
};

export default TopMenu;