import { ListItem, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import dayjs from 'dayjs';
import styles from "./HistoryItem.module.css"
import TranslationTypes from "../../../../../constants/translationTypes";
import { masterDisplayName } from "../../../../../utils/formatUtils";

const ONE_DAY = 24 * 60 * 60 * 1000;

const HistoryItem = ({ record }) => {
  const user = useSelector((state) => state?.user);
  const lang = useSelector(state => state.lang).title.toLowerCase();

  const [showDialog, setShowDialog] = useState(false);

  const findText = (type) => record.translations.find(v => v.type === type && v.lang === lang)?.text ?? ""

  const masterName = masterDisplayName(record.master, lang);
  const serviceTitle = `${findText(TranslationTypes.SERVICE_TITLE)} - ${findText(TranslationTypes.SERVICE_SUBTITLE)}`;

  return (
    <ListItem sx={{ padding: 0, marginTop: 1 }}>
      <div className={styles.row}>
        <Typography>{dayjs(record.startTime).format("DD.MM.YYYY")}: {dayjs(record.startTime).format("HH:mm")} - {dayjs(record.endTime).format("HH:mm")}</Typography>
        <Typography>{ masterName }</Typography>
        <Typography>{ serviceTitle }</Typography>
      </div>
    </ListItem>
  );
};

export default HistoryItem;
