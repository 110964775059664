import React, { useRef, useState } from "react";
import ExpandMoreItem from "../../ControlPanelItems/ExpandMoreItem";
import DisableNextDayRecords from "./DisableNextDayRecords";

const SiteSettings = () => {
  return (
    <>
      <ExpandMoreItem text="Settings">
        <DisableNextDayRecords />
        
      </ExpandMoreItem>
    </>
  );
}

export default SiteSettings;