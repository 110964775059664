import React from "react";
import styles from "./CategoryItem.module.css";
import { Link } from "react-router-dom";
import { Button, TextField, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";
import URLS from "../../../constants/urls";
import { useSelector } from "react-redux";
import ENDPOINTS from "../../../net/endpoints";

const CategoryItem = ({ category, onClick }) => {
  const { t } = useTranslation();
  const lang = useSelector(state => state.lang);

  const translation = category.translations.find(v => v.lang === lang.title.toLowerCase());

  return (
    <>
      <div className={styles.item}>
        <Link to={URLS.categoryOf(category.id)}>
          <img className={styles.categoryImage} src={ category.fileId ? ENDPOINTS.getFileUrl(category.fileId) : "/img/no_category.jpg"} />
          <div className={styles.shadow}>
            <Typography className={styles.categoryTitle} variant="p">{ translation.text }</Typography>
          </div>
        </Link>
      </div>
    </>
  );
};

export default CategoryItem;