import * as React from 'react';
import dayjs from 'dayjs';
import styles from './DateItem.module.css'
import LANGS from '../../../constants/langs';
import { useSelector } from 'react-redux';

const plDays = ['Ndz', 'Pon', 'Wt', 'śr', 'Czw', 'Pt', 'Sob']
const enDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
const ruDays = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']

const allDays = {
  [LANGS.EN.title]: enDays,
  [LANGS.PL.title]: plDays,
  [LANGS.RU.title]: ruDays,
}

const DateItem = ({ date, selected, onClick, children }) => {
  const lang = useSelector((state) => state.lang).title;

  const days = allDays[lang];

  return (
    <>
      <div className={ styles.box + ' ' + (selected ? styles.boxItemSelected : styles.boxItemUnselected) } onClick={onClick}>
        <p className={ styles.day }>{ days[dayjs(date).get('day')] }</p>
        <p>{ dayjs(date).format('DD') + '.' + dayjs(date).format('MM') }</p>
      </div>
    </>
  );
};

export default DateItem;
