import { Avatar, Typography } from "@mui/material";
import styles from './SelectMasterPage.module.css';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { masterDisplayName } from "../../../../../utils/formatUtils";
import { trackBookingPage } from "../../../../../ga";

const SelectMasterPage = ({ build, masters, masterServices, selectedId, onClick }) => {

  const lang = useSelector((state) => state?.lang).title.toLowerCase();
  const [availableMasters, setAvailableMasters] = useState(build());

  const lastMasterId = 1;
  const byIdSort = (one, two) => {
    if (one.id === lastMasterId) return 1;
    if (two.id === lastMasterId) return -1;
    return one.id - two.id
  }
  const nameIf = (condition, name) => (condition ? " " + name : "")
  const isDisabled = (master) => !availableMasters.find(item => item.id === master.id);

  useEffect(() => {
    trackBookingPage("MastersPage");
  }, []);

  return (
    <>
      <div >
        <div className={styles.masters}>
          {
            masters
              .sort(byIdSort)
              .map(master => <div key={master.id} onClick={() => { if (!isDisabled(master)) onClick(master) }} className={styles.masterItemBox + nameIf(selectedId === master.id, styles.selectedMaster) + nameIf(isDisabled(master), styles.disabledMaster)}>
                <Avatar alt="Remy Sharp" src={master.imageUrl} />
                <Typography>{ masterDisplayName(master, lang) }</Typography>
              </div>)
          }
        </div>
      </div>
    </>
  );
};

export default SelectMasterPage;