import React, { useRef, useState } from "react";
import ExpandMoreItem from "../../ControlPanelItems/ExpandMoreItem";
import AnalyticsInfo from "./AnalyticsInfo";

const Analytics = () => {
  return (
    <>
      <ExpandMoreItem text="Analytics">
        <AnalyticsInfo />

      </ExpandMoreItem>
    </>
  );
}

export default Analytics;