
export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validatePhone = (phone) => {
  const clearPhone = phone.replaceAll(' ', '')
  if (clearPhone.startsWith('+48')) {
    return clearPhone.length === 12 && clearPhone.match(/^[+]\d+$/)
  }
  return clearPhone.match(/^[+]\d+$/) !== null
}