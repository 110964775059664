import React, { useRef, useState } from "react";
import ExpandMoreItem from "../ExpandMoreItem";
import MastersList from "./MastersList";

const Masters = () => {
  return (
    <>
      <ExpandMoreItem text="Masters">
        <MastersList />

      </ExpandMoreItem>
    </>
  );
}

export default Masters;