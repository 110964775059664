import React from "react";
import styles from "./Footer.module.css";
import { Button, TextField, Typography } from '@mui/material';
import AccountInfo from "../AccountInfo";
import TopMenu from "../TopMenu/TopMenu";
import CountrySelect from "../CountrySelect";
import Logo from "../../../svg/facetime_logo.svg"
import { Link } from "react-router-dom";
import URLS from "../../../constants/urls";
import InstagramLink from "../../../pages/ContactsPage/InstagramLink";
import EmailLink from "../../../pages/ContactsPage/EmailLink";
import PhoneLink from "../../../pages/ContactsPage/PhoneLink";
import PrivacyPolicyLink from "./PrivacyPolicyLink";
import { useTranslation } from "react-i18next";
import CreateFeedbackDialog from "../../dialogs/CreateFeedbackDialog";

const Footer = ({ children }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.box}>
        <div className={styles.items}>

          <div className={styles.item}>
            <div className={styles.subitem}>
              <Typography sx={{ color: 'onBlackSurface.footerText', fontSize: '1.1em' }}>{ t('footerWorkTimeTitle') }</Typography>
              <Typography sx={{ color: 'onBlackSurface.footerText', fontSize: '0.9em' }}>{ t('footerWorkTime') }</Typography>
              <Typography sx={{ color: 'onBlackSurface.footerText', fontSize: '0.9em' }}>Warszawa, Minska 9</Typography>
              <PrivacyPolicyLink color={'onBlackSurface.footerText'} fontSize='0.9em' />
            </div>
          </div>

          <div className={styles.item}>
            <div className={styles.subitem}>
              <Typography sx={{ color: 'onBlackSurface.footerText', fontSize: '1.1em' }}>{ t('footerContactsTitle') }</Typography>
              <PhoneLink color='onBlackSurface.footerText' fontSize='0.9em' />
            </div>

            <div className={styles.subitem}>
              <EmailLink color='onBlackSurface.footerText' fontSize='0.9em' />
            </div>
          </div>
          
          <div className={styles.item + ' ' + styles.subitem}>
            <Typography sx={{ color: 'onBlackSurface.footerText', fontSize: '1.1em' }}>{ t('footerNetworksTitle') }</Typography>
            <InstagramLink color={'onBlackSurface.footerText'} fontSize='0.9em' />
            <CreateFeedbackDialog />
          </div>
          
        </div>
      </div>
    </>
  );
};

export default Footer;