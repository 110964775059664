import * as React from 'react';
import styles from "./DaySchedule.module.css";
import { CircularProgress, Divider, styled } from "@mui/material";
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { useQuery } from 'react-query';
import QUERY_KEYS from '../../../../net/query-keys';
import REQUESTS from '../../../../net/requests';
import ServerError from '../../../../components/widgets/ServerError/ServerError';
import Record from './Record';

dayjs.extend(isToday);

const DaySchedule = ({ scheduleIds }) => {

  const cacheKey = QUERY_KEYS.recordsOfSchedules(scheduleIds);
  const recordsRequest = useQuery(cacheKey, REQUESTS.getRecordsOfSchedules(scheduleIds), { refetchOnMount: true });

  if (recordsRequest.isLoading) {
    return <CircularProgress />
  }
  if (recordsRequest.isError) {
    return <ServerError/>
  }
  console.log('recordsRequest', recordsRequest.data);
  
  var records = []
  var clients = []
  var services = []
  var allNotes = []
  const schedules = {}
  recordsRequest.data.forEach(dataItem => {
    dataItem.records.forEach(v => {
      records.push(v)
      schedules[v.id] = dataItem.id;
    })
    dataItem.clients.forEach(v => clients.push(v))
    dataItem.services.forEach(v => services.push(v))
    dataItem.recordNotes.forEach(v => allNotes.push(v))
  });
  records = records.sort((a, b) => a.startTime - b.startTime);

  const recordDetails = (id) => {
    const record = records.find((v, i, a) => v.id === id)
    const client = clients.find((v, i, a) => v.id === record.clientId)
    const service = services.find((v, i, a) => v.id === record.serviceId)
    const notes = allNotes.filter((v, i, a) => v.recordId === id)
    return <Record record={record} client={client} service={service} id={id} notes={notes} scheduleId={schedules[id]} cacheKey={cacheKey}/>
  }

  return (
    <div className={styles.scheduleRecords}>
      {
        records.length > 0 ? 
          records.map(record => <React.Fragment key={record.id}>
            <Divider orientation="horizontal" variant="middle" flexItem />
            { recordDetails(record.id) }
            </React.Fragment>) 
          : <p>No records here</p>
      }
    </div>
  );
};

export default DaySchedule;
