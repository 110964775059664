import { Typography } from "@mui/material";
import React from "react";
import styles from "./UserConfirmInfoWidget.module.css"

const ONE_DAY = 24 * 60 * 60 * 1000;

const UserConfirmInfoWidget = ({ record }) => {
  return (
    <div className={styles.column}>
      <Typography>Init confirmation: { record.hasInitUserConfirmation ? "Yes" : "No" }</Typography>
      <Typography>Final confirmation: { (record.startTime - Date.now() > ONE_DAY) ? "Waiting" : (record.hasFinalUserConfirmation ? "Yes" : "No") }</Typography>
    </div>
  );
};

export default UserConfirmInfoWidget;
