import React, { useRef, useState } from "react";
import styles from "./CategoriesList.module.css";
import ExpandMoreItem from "../ExpandMoreItem";
import { Button, Card, Checkbox, CircularProgress, Divider, MenuItem, Select, TextField } from "@mui/material";
import Text from "../../Text";
import ConsentTypes from "../../../../constants/consentTypes";
import { useMutation, useQuery } from "react-query";
import REQUESTS from "../../../../net/requests";
import { logAndReset } from "../../../../utils/requestUtils";
import QUERY_KEYS from "../../../../net/query-keys";
import ConsentsList from "../ConsentsList";
import ServerError from "../../ServerError/ServerError";
import CategoriesListItem from "../CategoriesListItem";

const CategoriesList = () => {

  const categoriesRequest = useQuery(QUERY_KEYS.CATEGORIES_ALL, REQUESTS.getAllCategories, { refetchOnMount: true })

  if (categoriesRequest.isLoading) {
    return <CircularProgress />
  }
  if (categoriesRequest.isError) {
    return <ServerError/>
  }

  const categories = categoriesRequest.data.sort((a, b) => a.id - b.id)

  return (
    <div className={styles.categoriesList}>
      { categories.map(category => <div key={category.id}><CategoriesListItem category={category} /></div>) }
    </div>
  );

}

export default CategoriesList;