import React, { useRef, useState } from "react";
import QUERY_KEYS from "../../../../../net/query-keys";
import REQUESTS from "../../../../../net/requests";
import dayjs, { Dayjs } from 'dayjs';
import MetricCard from "./MetricCard";
import { useQuery, useQueryClient } from "react-query";
import { CircularProgress } from "@mui/material";
import ServerError from "../../../ServerError/ServerError";
import styles from "./AnalyticsInfo.module.css"
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AnalyticsCards from "./AnalyticsCards";
import 'dayjs/locale/ru';
import AnalyticsPopular from "./AnalyticsPopular";
import AnalyticsMasters from "./AnalyticsMasters";

const startOfMonth = dayjs(dayjs().format("YYYY-MM-DD")).valueOf()

const AnalyticsInfo = () => {
  const [startTime, setStartTime] = useState(dayjs().startOf("month"));
  const [endTime, setEndTime] = useState(dayjs());

  // startTime: Date;
  // endTime: Date;
  // newRecords: number;
  // canceledRecords: number;
  // newUsers: number;
  // totalVisitors: number;

  const handleStartTimeChanged = (time) => { 
    setStartTime(time); 
  }

  const handleEndTimeChanged = (time) => { 
    setEndTime(time); 
  }

  return (
    <div className={styles.box}>
      <div className={styles.dates}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
          <DatePicker label="start time" disablePast={false} ampm={false} timeSteps={{ minutes: 15 }} value={startTime} onChange={handleStartTimeChanged}/>
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
          <DatePicker label="end time" disablePast={false} ampm={false} timeSteps={{ minutes: 15 }} value={endTime} onChange={handleEndTimeChanged}/>
        </LocalizationProvider>
      </div>
      <div className={styles.cards}>
        <AnalyticsCards startTime={startTime.valueOf()} endTime={endTime.valueOf()} />
      </div>
      <div className={styles.cards}>
        <AnalyticsPopular startTime={startTime.valueOf()} endTime={endTime.valueOf()} />
      </div>
      <div className={styles.cards}>
        <AnalyticsMasters startTime={startTime.valueOf()} endTime={endTime.valueOf()} />
      </div>
    </div>
  );
}

export default AnalyticsInfo;