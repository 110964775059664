import React, { useRef, useState } from "react";
import { Button, Card, TextField } from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import { logAndReset } from "../../../../../utils/requestUtils";
import QuestionTypes from "../../../../../constants/questionTypes";
import REQUESTS from "../../../../../net/requests";
import ExpandMoreItem from "../../ExpandMoreItem";
import QUERY_KEYS from "../../../../../net/query-keys";

const QuestionsToolbox = () => {


  const createQuestionMutation = useMutation(REQUESTS.postQuestionType)
  const queryClient = useQueryClient();
  
  const handleAddQuestion = (type) => {
    const data = {
      type
    }
    console.log('create question item:', data);
    createQuestionMutation.mutate(data, {
      onSuccess: (data, error, variables, context) => {
        console.log('question is created!')
        queryClient.invalidateQueries(QUERY_KEYS.QUESTIONS)
        setTimeout(() => {
          createQuestionMutation.reset()
        }, 3000)
      },
      onError: () => {
        logAndReset(createQuestionMutation)
      }
    });
  }

  return (
    <>
      {
        createQuestionMutation.isLoading ? "in progress..." :
          createQuestionMutation.isError ? "error" :
            <div>
              <Button variant="outlined" onClick={() => handleAddQuestion(QuestionTypes.SIMPLE_TEXT)}>Add simple text</Button>
              <Button variant="outlined" onClick={() => handleAddQuestion(QuestionTypes.TEXT_WITH_INPUT)}>Add Text with input</Button>
              <Button variant="outlined" onClick={() => handleAddQuestion(QuestionTypes.TEXT_WITH_YES_NO)}>Add Text with Yes/No options</Button>
            </div>
      }
    </>
  );
}

export default QuestionsToolbox;