import React, { useRef, useState } from "react";
import { Avatar, Button, Card, CardActions, CardHeader, Checkbox, CircularProgress, Hidden, IconButton, Menu, MenuItem, TextField, Typography, useMediaQuery } from "@mui/material";
import styles from './ServiceItem.module.css'
import { getPrice, getTimeRequirements } from "../../../../../dialogs/utils";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { useMutation, useQueryClient } from "react-query";
import REQUESTS from "../../../../../../net/requests";
import QUERY_KEYS from "../../../../../../net/query-keys";
import { logAndReset } from "../../../../../../utils/requestUtils";
import AddCategoryDialog from "../../../../../dialogs/AddCategoryDialog";
import EditServiceDialog from "../../../../../dialogs/EditServiceDialog";

const ServiceItem = ({ service }) => {
  const { t } = useTranslation();
  const lang = useSelector(state => state.lang).title.toLowerCase();
  const [menuAnchor, setMenuAnchor] = useState(null)
  const queryClient = useQueryClient();

  const [showEditServiceDialog, setShowEditServiceDialog] = useState(false)
  const [showCategoriesDialog, setShowCategoriesDialog] = useState(false)
  const [showEditDialog, setShowEditDialog] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const maxW700 = useMediaQuery('(max-width:700px)');
  
  const title = service.titles.find(v => v.lang === lang)?.text ?? "";
  const subtitle = service.subtitles.find(v => v.lang === lang)?.text ?? "";
  const description = service.descriptions.find(v => v.lang === lang)?.text ?? "";
  const time = getTimeRequirements(service.timeRequirementsMin, service.timeRequirementsMax)
  const price = getPrice(maxW700)

  
  const featuredMutation = useMutation(service.featured ? REQUESTS.deleteFeatured(service.id) : REQUESTS.setFeatured(service.id));
  const deleteServiceMutation = useMutation(REQUESTS.deleteService(service.id))

  const handleMenuClick = (event) => {
    setMenuAnchor(event.currentTarget);
    setMenuOpen(true);
  }

  const handleShowCategories = () => {
    setShowCategoriesDialog(true);
    setMenuOpen(false);
  }

  const handleEdit = () => {
    setMenuOpen(false);
    setShowEditServiceDialog(true);
  }

  const handleDelete = () => {
    setMenuOpen(false);
    deleteServiceMutation.mutate({}, {
      onSuccess: (data, error, variables, context) => {
        console.log('service is deleted!')
        queryClient.invalidateQueries(QUERY_KEYS.SERVICES);
        setTimeout(() => {
          deleteServiceMutation.reset()
        }, 3000)
      },
      onError: () => {
        logAndReset(deleteServiceMutation)
      }
    });
  }

  const handleCloseDialog = () => {
    setShowEditDialog(false);
    setShowCategoriesDialog(false);
    setShowEditServiceDialog(false);
  }

  const handleFavoriteChange = (event) => {
    console.log("favorite:", event.target.checked);
    featuredMutation.mutate({}, {
      onSuccess: (data, error, variables, context) => {
        console.log('done!')
        queryClient.invalidateQueries(QUERY_KEYS.SERVICES);
        featuredMutation.reset();
      },
      onError: () => {
        logAndReset(featuredMutation)
      }
    });
  }

  const handleDeleteService = () => {
    console.log('delete service:', service.id);
    
  }

  console.log("service", service);
  return (
    <>
      <div className={styles.serviceCard}>
        <Card sx={{ padding: 2, minWidth: 200 }}>
          {
            deleteServiceMutation.isLoading ? <CircularProgress /> : <>
                <div className={styles.header}>
                <div align="center" >
                  <Avatar src={service.imageUrl} sx={{ width: 112, height: 112 }} />
                </div>

                <div className={styles.headerFavorite}>
                  <Checkbox
                    icon={<BookmarkBorderIcon />}
                    checkedIcon={<BookmarkIcon />}
                    checked={service.featured}
                    onChange={handleFavoriteChange}
                  />
                </div>    

                <div className={styles.headerMenu}>
                  <IconButton aria-label="settings" onClick={handleMenuClick}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                        id="basic-menu"
                        anchorEl={menuAnchor}
                        open={menuOpen}
                        onClose={() => setMenuOpen(false)}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        <MenuItem onClick={handleShowCategories}>Show Categories</MenuItem>
                        <MenuItem onClick={handleEdit}>Edit</MenuItem>
                        <MenuItem onClick={handleDelete}>Delete</MenuItem>
                      </Menu>
                </div>
              </div>
              <Typography align="center" sx={{ padding: 1, marginTop: 2 }}>{ `${service.id}: ${title}` }</Typography>
              <Typography align="center" sx={{ padding: 1 }}>{ subtitle }</Typography>
              <Typography align="center" sx={{ margin: 1, overflow: "hidden", maxHeight: 72, fontSize: 16 }}>{ description }</Typography>
              <Typography>{ `${ time } ${ t('timeMin') }` }</Typography>
              <Typography>{ price }</Typography>
            </>

          }
          

          { showCategoriesDialog && <AddCategoryDialog service={service} open={showCategoriesDialog} onClose={handleCloseDialog}/> }
          { showEditServiceDialog && <EditServiceDialog service={service} open={showEditServiceDialog} onClose={handleCloseDialog}/> }
        </Card>
      </div>
      
    </>
  );
}

export default ServiceItem;