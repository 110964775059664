import React, { useRef, useState } from "react";
import ExpandMoreItem from "../ExpandMoreItem";
import QuestionsToolbox from "./QuestionsToolbox";
import QuestionList from "./QuestionList";

const Questions = () => {
  return (
    <>
      <ExpandMoreItem text="Questionnaire">
        <QuestionsToolbox />
        <QuestionList />

      </ExpandMoreItem>
    </>
  );
}

export default Questions;