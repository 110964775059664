import React from "react";
import "./ProfileInfo.css";
import { Avatar, Button, Paper, TextField, styled } from '@mui/material';
import { useSelector } from "react-redux";
import Text from "../Text";
import ROLES from "../../../constants/roles";
import QuestionsButton from "./QuestionsButton";

const ProfilePaper = styled(Paper)(({ theme }) => ({
  maxWidth: 1100,
  width: "100%",
  paddingLeft: 16,
  paddingRight: 16,
  padding: theme.spacing(2),
  marginTop: 16,
  ...theme.typography.body2,
  textAlign: 'center',
}));

const ProfileInfo = ({ profile }) => {
  const user = useSelector((state) => state?.user);

  let roleValue
  switch (profile.role) {
    case ROLES.MODERATOR:
      roleValue = "Moderator"
      break;
    case ROLES.ADMIN: 
      roleValue = "Admin"
      break;
    default:
      roleValue = "Client"
      break;
  }

  let infoItems = [
    { id: 1, key: "ID", value: profile.id},
    { id: 2, key: "Email", value: profile.email},
  ]
  if (user?.role == ROLES.ADMIN) {
    infoItems = [...infoItems, { id: 3, key: "Role", value: roleValue} ]
  }

  return (
    <>
      <div id="profile-info">
        <ProfilePaper variant="outlined" sx={{ borderRadius: 2 }}>
          <div id="profile-info-user">
            <div id="profile-info-user-avatar">
              <Avatar alt={user?.displayName} src={user?.avatar} sx={{ width: 112, height: 112 }} />
            </div>

            <div id="profile-info-user-info">
              <div id="profile-info-user-info-keys">
                { infoItems.map(item => <Text key={item.id}>{item.key}</Text>)}
              </div>
              <div id="profile-info-user-info-values">
                { infoItems.map(item => <Text key={item.id}>{item.value}</Text>)}
              </div>
            </div>

            { user && profile.id === user.id && <QuestionsButton /> }
          </div>
          
          
        </ProfilePaper>
      </div>
    </>
  );
};

export default ProfileInfo;