import { Button, Card, CardActions, CardContent, CircularProgress, Stack, styled, TextField, Typography } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import QUERY_KEYS from "../../../net/query-keys";
import REQUESTS from "../../../net/requests";
import TranslationTypes from "../../../constants/translationTypes";
import URLS from "../../../constants/urls";
import ConsentTypes from "../../../constants/consentTypes";
import ServerError from "../../../components/widgets/ServerError/ServerError";


const RegisterConsent = () => {
  const lang = useSelector(state => state.lang).title.toLowerCase()
  const dispatch = useDispatch();
  const consentsRequest = useQuery(QUERY_KEYS.getConsentForType(ConsentTypes.REGISTRATION), REQUESTS.getConsentForType(ConsentTypes.REGISTRATION), { refetchOnMount: true })

  if (consentsRequest.isLoading) {
    return <CircularProgress />
  }
  if (consentsRequest.isError) {
    return <ServerError/>
  }

  const consents = consentsRequest.data.consents;
  const consent = consents.find(v => v.type = ConsentTypes.REGISTRATION);
  const translations = consentsRequest.data.translations;

  if (!consent) {
    return <></>
  }
  
  const translationItem = translations.find(item => item.lang === lang && item.type === TranslationTypes.CONSENT_TITLE && item.itemId === consent?.id)
  let translation = translationItem ? translationItem.text : ""

  let text
  const startIndex = translation.indexOf('[')
  const endIndex = translation.indexOf(']')

  if (startIndex === -1 || endIndex === -1) {
    text = <a href={URLS.consentPage(consent.id)} target="_blank">{ translation.replaceAll("[", "").replaceAll("]", "") }</a>
  } else {
    text = <><span>{ translation.substring(0, startIndex) }</span> <a href={URLS.consentPage(consent.id)} target="_blank">{ translation.substring(startIndex + 1, endIndex) }</a><span> { translation.substring(endIndex + 1) }</span></>
  }

  return (
    <div>
      <Typography sx={{ maxWidth: 240, fontSize: 14}} align="center">
        { text }
      </Typography>
    </div>
  );
};

export default RegisterConsent;
