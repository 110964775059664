import React, { useRef, useState } from "react";
import "./EditConsentItem.css";
import ExpandMoreItem from "../ExpandMoreItem";
import { Button, Card, Checkbox, CircularProgress, MenuItem, Select, TextField } from "@mui/material";
import Text from "../../Text";
import ConsentTypes from "../../../../constants/consentTypes";
import { useMutation, useQuery } from "react-query";
import REQUESTS from "../../../../net/requests";
import { logAndReset } from "../../../../utils/requestUtils";
import QUERY_KEYS from "../../../../net/query-keys";
import ServerError from "../../ServerError/ServerError";
import EditConsentItemText from "../EditConsentItemText";

const EditConsentItem = ({ consent }) => {

  const [showCreatePanel, setShowCreatePanel] = useState(false)
  const [type, setType] = useState(ConsentTypes.RECORD)
  const keyRef = useRef()
  const requiredRef = useRef()

  const consentsRequest = useQuery(QUERY_KEYS.CONSENTS, REQUESTS.getAllConsents)

  if (consentsRequest.isLoading) {
    return <CircularProgress />
  }
  if (consentsRequest.isError) {
    return <ServerError />
  }


  return (
    <>
      <Card sx={{ marginTop: 0, padding: 2 }} >
        <div className="edit-consent-item-box">
          <Text>id: { consent.id }, key: { consent.key }, type: { consent.type }</Text>
          <EditConsentItemText lang="en" consentId={consent.id} />
          <EditConsentItemText lang="pl" consentId={consent.id} />
          <EditConsentItemText lang="ru" consentId={consent.id} />
        </div>
      </Card>
    </>
  );
}

export default EditConsentItem;