import React, { useRef, useState } from "react";
import { Button, Card, TextField, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import { logAndReset } from "../../../../../../utils/requestUtils";
import REQUESTS from "../../../../../../net/requests";
import styles from "./EditSimpleText.module.css"
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import LANGS from "../../../../../../constants/langs";

const findTranslation = (question, lang) => {
  const langValue = lang.title.toLowerCase();
  return question.translations.find(v => v.lang === langValue);
}

const EditSimpleText = ({ question }) => {

  const lang = useSelector(state => state.lang)
  
  const [enText, setEnText] = useState(findTranslation(question, LANGS.EN)?.text ?? null);
  const [plText, setPlText] = useState(findTranslation(question, LANGS.PL)?.text ?? null);
  const [ruText, setRuText] = useState(findTranslation(question, LANGS.RU)?.text ?? null);
  const [result, setResult] = useState(null);


  const updateQuestionMutation = useMutation(REQUESTS.putQuestion(question.id))
  const queryClient = useQueryClient();
  
  const handleSave = () => {
    const data = {
      enText,
      plText,
      ruText
    }
    console.log('update question item:', data);
    updateQuestionMutation.mutate(data, {
      onSuccess: (data, error, variables, context) => {
        console.log('question is updated!');
        setResult(true)
        setTimeout(() => {
          updateQuestionMutation.reset();
          setResult(null)
        }, 3000);
      },
      onError: () => {
        logAndReset(updateQuestionMutation);
      }
    });
  }

  return (
    <div className={styles.box}>
      <Typography>Simple Text</Typography>
      <TextField value={enText ?? ""} onChange={event => setEnText(event.target.value)} label="en" />
      <TextField value={plText ?? ""} onChange={event => setPlText(event.target.value)} label="pl" />
      <TextField value={ruText ?? ""} onChange={event => setRuText(event.target.value)} label="ru" />
      {
        result ? "Saved!" :
          updateQuestionMutation.isLoading ? "Saving..." :
            updateQuestionMutation.isError ? "Error" :
              <Button onClick={handleSave}>Save</Button>
      }
    </div>
  );
}

export default EditSimpleText;