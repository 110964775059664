import React, { useState } from "react";
import styles from "./LinkFakeUsersDialog.module.css";
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemButton, ListItemText, MenuItem, TextField, Typography, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useMutation, useQuery, useQueryClient } from "react-query";
import QUERY_KEYS from "../../../net/query-keys";
import REQUESTS from "../../../net/requests";
import ServerError from "../../widgets/ServerError/ServerError";
import { logAndReset } from "../../../utils/requestUtils";

const BlurryDialog = styled(Dialog)(({ theme }) => ({
  backdropFilter: "blur(5px)"
}));

const LinkFakeUsersDialog = ({ open, onClose, targetUser }) => {

  const [filter, setFilter] = useState("")
  const [selectedUser, setSelectedUser] = useState()
  const usersRequest = useQuery(QUERY_KEYS.USERS, REQUESTS.getUsers, { refetchOnMount: true })
  const mergeMutation = useMutation(REQUESTS.mergeUsers)
  const queryClient = useQueryClient();

  if (usersRequest.isLoading) {
    return <CircularProgress />
  }
  if (usersRequest.isError) {
    return <ServerError/>
  }

  const users = usersRequest.data.filter(v => v.isFake === true)
  // const users = []; 
  // for (let i = 0; i < 50; i++) {
  //   users.push(users1[0])
  // }
  const names = {};
  users.forEach(user => {
    names[user.id] = `${user.id}: ${user.isFake ? user.fakeName + ' [fake]' : user.displayName}`
  });

  const handleClose = () => {
    onClose();
  }

  const handleSelect = (user) => {
    setSelectedUser(user)
  }

  const handleCancel = () => {
    setSelectedUser(undefined)
  }

  const handleReplace = () => {
    const data = {
      userId: targetUser.id,
      fakeId: selectedUser.id
    }
    mergeMutation.mutate(data, {
      onSuccess: (data, error, variables, context) => {
        console.log(`selectedUser was removed successfully!`)
        queryClient.invalidateQueries(QUERY_KEYS.USERS);
        
        setTimeout(() => {
          onClose();
        }, 3000)
      },
      onError: () => {
        logAndReset(mergeMutation);
      }
    });
  }

  const items = filter.length > 1 ? users.filter(v => names[v.id].toLowerCase().indexOf(filter.toLowerCase()) !== -1) : users;

  const confirmationPage = <>
    <DialogContent >
      <Typography>do you want to replace { selectedUser ? `<id: ${selectedUser.id}, name: ${selectedUser.fakeName}>` : '' } with { `<id: ${targetUser.id}, name: ${targetUser.displayName}>` }</Typography>
      {
        mergeMutation.isLoading ? <Typography>Loading...</Typography> : 
          mergeMutation.isError ? <Typography>Error: {mergeMutation.error.message}</Typography> : 
            mergeMutation.isSuccess ? <Typography>Record was removed!</Typography> : 
              <div>
                <Button variant="outlined" sx={{ marginTop: 1, marginLeft: 1 }} onClick={handleReplace}>Replace</Button>
                <Button variant="outlined" sx={{ marginTop: 1, marginLeft: 1 }} onClick={handleCancel}>Cancel</Button>
              </div>
      }
    </DialogContent>
  </>

  return (
    <>
      <BlurryDialog onClose={handleClose} open={open} fullWidth={true} maxWidth="md">
        <DialogTitle sx={{ m: 0, paddingRight:7 }} id="customized-dialog-title">
          { `id: ${targetUser.id}, ${targetUser.displayName}, ${targetUser.email}` }
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 12,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        
        { 
          selectedUser ? confirmationPage :
            <DialogContent >
              <TextField value={filter} onChange={event => setFilter(event.target.value)}label="Filter" />
              <List>
                {
                  items.map(user => 
                    <ListItem key={user.id} disablePadding>
                      <ListItemButton onClick={() => handleSelect(user)}>
                        <ListItemText primary={ names[user.id] } />
                      </ListItemButton>
                    </ListItem>
                  )
                }
              </List>
            </DialogContent>
        }
      </BlurryDialog>
    </>
  );
};

export default LinkFakeUsersDialog;