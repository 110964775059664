import React, { useState } from "react";
import styles from "./SelectUserDialog.module.css";
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemButton, ListItemText, MenuItem, TextField, Typography, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useQuery } from "react-query";
import QUERY_KEYS from "../../../net/query-keys";
import REQUESTS from "../../../net/requests";
import ServerError from "../../widgets/ServerError/ServerError";
import { userDisplayName } from "../../../utils/formatUtils";

const BlurryDialog = styled(Dialog)(({ theme }) => ({
  backdropFilter: "blur(5px)"
}));

const SelectUserDialog = ({ open, onClose, onChange }) => {

  const [filter, setFilter] = useState("")
  const usersRequest = useQuery(QUERY_KEYS.USERS, REQUESTS.getUsers, { refetchOnMount: true })

  if (usersRequest.isLoading) {
    return <CircularProgress />
  }
  if (usersRequest.isError) {
    return <ServerError/>
  }

  const users = usersRequest.data 
  // const users = []; 
  // for (let i = 0; i < 50; i++) {
  //   users.push(users1[0])
  // }
  const names = {};
  users.forEach(user => {
    names[user.id] = `${ user.id }: ${ userDisplayName(user, true) }`
  });

  const handleClose = () => {
    onClose();
  }

  const items = filter.length > 1 ? users.filter(v => names[v.id].toLowerCase().indexOf(filter.toLowerCase()) !== -1) : users;

  return (
    <>
      <BlurryDialog onClose={handleClose} open={open} fullWidth={true} maxWidth="md">
        <DialogTitle sx={{ m: 0, paddingRight:7 }} id="customized-dialog-title">
          Select user
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 12,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent >
          <TextField value={filter} onChange={event => setFilter(event.target.value)}label="Filter" />
          <List>
            {
              items.map(user => 
                <ListItem key={user.id} disablePadding>
                  <ListItemButton onClick={() => onChange(user)}>
                    <ListItemText primary={ names[user.id] } />
                  </ListItemButton>
                </ListItem>
              )
            }
          </List>
        </DialogContent>
      </BlurryDialog>
    </>
  );
};

export default SelectUserDialog;