import React, { useRef, useState } from "react";
import styles from "./ServicesList.module.css";
import ExpandMoreItem from "../../ExpandMoreItem";
import { Button, Card, Checkbox, CircularProgress, MenuItem, Select, TextField } from "@mui/material";
import Text from "../../../Text";
import ConsentTypes from "../../../../../constants/consentTypes";
import { useMutation, useQuery } from "react-query";
import REQUESTS from "../../../../../net/requests";
import { logAndReset } from "../../../../../utils/requestUtils";
import QUERY_KEYS from "../../../../../net/query-keys";
import ConsentsList from "../../ConsentsList";
import ServerError from "../../../ServerError/ServerError";
import CategoriesListItem from "../../CategoriesListItem";
import ServicesListItem from "../../ServicesListItem";
import ServiceItem from "./ServiceItem";

const ServicesList = () => {

  const servicesRequest = useQuery(QUERY_KEYS.SERVICES, REQUESTS.getServices)

  if (servicesRequest.isLoading) {
    return <CircularProgress />
  }
  if (servicesRequest.isError) {
    return <ServerError/>
  }

  const services = servicesRequest.data.sort((a, b) => a.id - b.id)

  return (
    <div className={styles.services}>
      { services.map(service => <ServiceItem key={service.id} service={service} />) }
    </div>
  );

}

export default ServicesList;