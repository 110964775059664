import React from "react";
import styles from "./ControlPanel.module.css";
import { Avatar, Button, CardContent, Collapse, Paper, TextField, styled } from '@mui/material';
import { useSelector } from "react-redux";
import Text from "../Text";
import ROLES from "../../../constants/roles";
import Consents from "../ControlPanelItems/Consents";
import Categories from "../ControlPanelItems/Categories";
import Services from "../ControlPanelItems/Services";
import Questions from "../ControlPanelItems/Questions";
import Masters from "../ControlPanelItems/Masters";
import Analytics from "./Analytics";
import SiteSettings from "./SiteSettings";

const ProfilePaper = styled(Paper)(({ theme }) => ({
  maxWidth: 1100,
  width: "100%",
  paddingLeft: 16,
  paddingRight: 16,
  padding: theme.spacing(2),
  marginTop: 16,
  ...theme.typography.body2,
  textAlign: 'center',
}));

const ControlPanel = ({ profile }) => {
  const user = useSelector((state) => state?.user);

  let roleValue
  switch (user.role) {
    case ROLES.MODERATOR:
      roleValue = "Moderator"
      break;
    case ROLES.ADMIN:
      roleValue = "Admin"
      break;
    default:
      roleValue = "Client"
      break;
  }

  let infoItems = [
    { id: 1, key: "Name", value: user.displayName },
    { id: 2, key: "Email", value: user.email },
  ]
  if (user.role == ROLES.ADMIN) {
    infoItems = [...infoItems, { id: 3, key: "Role", value: roleValue }]
  }

  return (
    <>
      <div className={styles.box}>
        <ProfilePaper variant="outlined" sx={{ borderRadius: 2 }}>
          <Text>Control Panel</Text>
          <Consents />
          <Categories />
          <Services />
          <Questions />
          <Masters />
          <SiteSettings />
          <Analytics />


        </ProfilePaper>
      </div>
    </>
  );
};

export default ControlPanel;