import { Typography } from "@mui/material";
import React from "react";
import styles from "./AdvicePreview.module.css"
import { Link } from "react-router-dom";

const AdvicePreview = ({ imageUrl, title, url }) => {

  return (
    <div className={styles.item}>
      <Link to={url}>
        <img className={styles.categoryImage} src={imageUrl} />
        <div className={styles.shadow}>
          <Typography className={styles.categoryTitle} variant="p">{ title }</Typography>
        </div>
      </Link>
    </div>
  );
};

export default AdvicePreview;
