import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { IconButton, Dialog, DialogTitle, DialogContent, CircularProgress, Button, Divider, styled, FormControl, InputLabel, Select, MenuItem, TextField, Typography } from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import dayjs, { Dayjs } from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import QUERY_KEYS from '../../../net/query-keys';
import REQUESTS from '../../../net/requests';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { logAndReset } from '../../../utils/requestUtils';
import { useState } from 'react';
import ServerError from '../../widgets/ServerError/ServerError';
import { useTranslation } from 'react-i18next';

dayjs.extend(isToday);

const BlurryDialog = styled(Dialog)(({ theme }) => ({
  backdropFilter: "blur(5px)"
}));

const RisksDialog = ({ onClose, open, serviceId }) => {
  const user = useSelector((state) => state?.user);
  const lang = useSelector((state) => state?.lang).title.toLowerCase();
  const { t } = useTranslation();

  const risksRequest = useQuery(QUERY_KEYS.risks(serviceId), REQUESTS.getRisks(serviceId));

  const handleClose = (event) => {
    onClose();
  };

  return (
    <>
      <BlurryDialog onClose={handleClose} open={open} fullWidth={true} maxWidth="sm">
        <DialogTitle sx={{ m: 0, paddingRight:7 }}>{ t('risksExtraInformation') }</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 12,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent >
          {
            risksRequest.isLoading ? <CircularProgress /> :
              risksRequest.isError ? <ServerError/> :
                <div>
                  <Typography variant='h6' display="block">{ t('risksRecommendations') }</Typography>
                  <Typography display="block" style={{ wordWrap: "break-word", "white-space": "pre-wrap" }}> { risksRequest.data.recomendations.find(v => v.lang === lang)?.text ?? "-" } </Typography>
                  <br/>
                  <Typography variant='h6' display="block">{ t('risksRisks') }</Typography>
                  <Typography display="block" style={{ wordWrap: "break-word", "white-space": "pre-wrap" }}> { risksRequest.data.risks.find(v => v.lang === lang)?.text ?? "-" } </Typography>
                  {
                    risksRequest.data.recsAfter.length > 0 && <>
                      <br/>
                      <Typography variant='h6' display="block">{ t('additionalRecomendations') }</Typography>
                      <Typography display="block" style={{ wordWrap: "break-word", "white-space": "pre-wrap" }}> { risksRequest.data.recsAfter.find(v => v.lang === lang)?.text ?? "-" } </Typography>
                    </>
                  }
                </div>
          }
        </DialogContent>
      </BlurryDialog>
    </>
  );
};

export default RisksDialog;
