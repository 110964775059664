import { CircularProgress, List, Paper, styled, Typography } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import styles from "./UnconfirmedRecords.module.css"
import { useParams } from 'react-router';
import { useSelector } from "react-redux";
import ServerError from "../../../components/widgets/ServerError/ServerError";
import QUERY_KEYS from "../../../net/query-keys";
import REQUESTS from "../../../net/requests";
import UnconfirmedRecord from "./UnconfirmedRecord";
import { useTranslation } from "react-i18next";
import ExpandMoreItem from "../../../components/widgets/ControlPanelItems/ExpandMoreItem2";


const ProfilePaper = styled(Paper)(({ theme }) => ({
  maxWidth: 1100,
  width: '100%',
  paddingLeft: 16,
  paddingRight: 16,
  padding: theme.spacing(2),
  marginTop: 16,
  ...theme.typography.body2,
  textAlign: 'center'
}));

const UnconfirmedRecords = ({ profile }) => {
  const user = useSelector((state) => state?.user);
  const master = useSelector((state) => state?.master);
  const { t } = useTranslation();
  
  const recordsRequest = useQuery(QUERY_KEYS.getUnconfirmedRecords(master?.id ?? 0), REQUESTS.getUnconfirmedRecords(master?.id ?? 0), { refetchOnMount: true })
  
  if (recordsRequest.isLoading) {
    return <CircularProgress />
  }
  if (recordsRequest.isError) {
    return <ServerError/>
  }

  const records = recordsRequest.data.sort((a, b) => a.startTime - b.startTime)

  return (
    <div className={styles.box}>
      <ProfilePaper variant="outlined" sx={{ borderRadius: 2 }}>
        <ExpandMoreItem text={ t('profileConfirmations') } sx={{ padding: 0}}>
          <div>
            {
              records && records.length === 0 && <div className={styles.empty}><Typography sx={{ paddingBottom: 5 }}>{ t('profileNoRecords') }</Typography></div>
            }

            {
              records && records.length > 0 &&
              <List dense={true}>
                { records.map(record => <UnconfirmedRecord key={record.id} record={record} />) }
              </List>
            }
          </div>
        </ExpandMoreItem>
      </ProfilePaper>
    </div>
  );
};

export default UnconfirmedRecords;
