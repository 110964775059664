import React, { useRef, useState } from "react";
import styles from "./Services.module.css";
import ExpandMoreItem from "../ExpandMoreItem";
import { Button, Card, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import REQUESTS from "../../../../net/requests";
import { logAndReset } from "../../../../utils/requestUtils";
import QUERY_KEYS from "../../../../net/query-keys";
import CategoriesList from "../CategoriesList";
import ServicesList from "./ServicesList";
import { useTranslation } from "react-i18next";

const Services = () => {

  const { t } = useTranslation();
  const [showCreatePanel, setShowCreatePanel] = useState(false)
  
  const [enTitle, setEnTitle] = useState('')
  const [plTitle, setPlTitle] = useState('')
  const [ruTitle, setRuTitle] = useState('')
  const [enSubTitle, setEnSubTitle] = useState('')
  const [plSubTitle, setPlSubTitle] = useState('')
  const [ruSubTitle, setRuSubTitle] = useState('')
  const [enText, setEnText] = useState('')
  const [plText, setPlText] = useState('')
  const [ruText, setRuText] = useState('')
  const [recomendationsEn, setRecomendationsEn] = useState('')
  const [recomendationsPl, setRecomendationsPl] = useState('')
  const [recomendationsRu, setRecomendationsRu] = useState('')
  const [risksEn, setRisksEn] = useState('')
  const [risksPl, setRisksPl] = useState('')
  const [risksRu, setRisksRu] = useState('')
  const [afterEn, setAfterEn] = useState('')
  const [afterPl, setAfterPl] = useState('')
  const [afterRu, setAfterRu] = useState('')
  const [file, setFile] = useState()
  const [isPrivate, setPrivate] = useState(false)
  const [published, setPublished] = useState(false)

  const createServiceMutation = useMutation(REQUESTS.postService)
  const queryClient = useQueryClient();
  
  const handleCreateService = () => {
    const data = {
      titleEn: enTitle,
      titlePl: plTitle,
      titleRu: ruTitle,
      subtitleEn: enSubTitle,
      subtitlePl: plSubTitle,
      subtitleRu: ruSubTitle,
      descriptionEn: enText,
      descriptionPl: plText,
      descriptionRu: ruText,
      risksEn,
      risksPl,
      risksRu,
      recomendationsEn,
      recomendationsPl,
      recomendationsRu,
      afterEn,
      afterPl,
      afterRu,
      file,
      published,
      private: isPrivate
    }
    console.log('create category:', data);
    createServiceMutation.mutate(data, {
      onSuccess: (data, error, variables, context) => {
        console.log('category is created!')
        queryClient.invalidateQueries(QUERY_KEYS.SERVICES);
        setTimeout(() => {
          createServiceMutation.reset()
        }, 3000)
      },
      onError: () => {
        logAndReset(createServiceMutation)
      }
    });
  }

  const handleFileChange = (event) => {
    if (event.target.files) {
      console.log(event.target.files[0].name);
      setFile(event.target.files[0]);
    }
  }

  return (
    <>
      <ExpandMoreItem text="Services">
        <ServicesList />
        
        <Button variant="outlined" onClick={() => setShowCreatePanel(!showCreatePanel)}>{showCreatePanel ? 'Hide' : 'Add'} new Service</Button>
        {
          showCreatePanel && <Card sx={{ marginTop: 0, padding: 2 }} >
            <div className={styles.servicesPanelBox}>
              <div className={styles.checks}>
                <FormControlLabel control={<Checkbox checked={isPrivate} onChange={event => setPrivate(event.target.checked)} />} label="Private" />
                <FormControlLabel control={<Checkbox checked={published} onChange={event => setPublished(event.target.checked)} />} label="Published" />
              </div>
              <TextField label="Title [en]" value={enTitle} onChange={event => setEnTitle(event.target.value)} />
              <TextField label="Title [pl]" value={plTitle} onChange={event => setPlTitle(event.target.value)} />
              <TextField label="Title [ru]" value={ruTitle} onChange={event => setRuTitle(event.target.value)} />
              <TextField label="SubTitle [en]" value={enSubTitle} onChange={event => setEnSubTitle(event.target.value)} />
              <TextField label="SubTitle [pl]" value={plSubTitle} onChange={event => setPlSubTitle(event.target.value)} />
              <TextField label="SubTitle [ru]" value={ruSubTitle} onChange={event => setRuSubTitle(event.target.value)} />
              <TextField multiline label="Text [en]" value={enText} onChange={event => setEnText(event.target.value)} />
              <TextField multiline label="Text [pl]" value={plText} onChange={event => setPlText(event.target.value)} />
              <TextField multiline label="Text [ru]" value={ruText} onChange={event => setRuText(event.target.value)} />
              <TextField multiline label="Recomendations [en]" value={recomendationsEn} onChange={event => setRecomendationsEn(event.target.value)} />
              <TextField multiline label="Recomendations [pl]" value={recomendationsPl} onChange={event => setRecomendationsPl(event.target.value)} />
              <TextField multiline label="Recomendations [ru]" value={recomendationsRu} onChange={event => setRecomendationsRu(event.target.value)} />
              <TextField multiline label="Risks [en]" value={risksEn} onChange={event => setRisksEn(event.target.value)} />
              <TextField multiline label="Risks [pl]" value={risksPl} onChange={event => setRisksPl(event.target.value)} />
              <TextField multiline label="Risks [ru]" value={risksRu} onChange={event => setRisksRu(event.target.value)} />
              <TextField multiline label={ `${ t('additionalRecomendations') } [en]` } value={afterEn} onChange={event => setAfterEn(event.target.value)} />
              <TextField multiline label={ `${ t('additionalRecomendations') } [pl]` } value={afterPl} onChange={event => setAfterPl(event.target.value)} />
              <TextField multiline label={ `${ t('additionalRecomendations') } [ru]` } value={afterRu} onChange={event => setAfterRu(event.target.value)} />
              <input accept="image/*" type="file" onChange={handleFileChange} />
              {
                createServiceMutation.isLoading ? ('Creating service...') : (
                  <>
                    { createServiceMutation.isError ? <div>An error occurred: {createServiceMutation.error.message}</div> : null }
                    { createServiceMutation.isSuccess ? <div>Service was created!</div> : <Button variant="outlined" onClick={handleCreateService}>Create Service</Button> }
                  </>
                )
              }
            </div>
          </Card>
        }

      </ExpandMoreItem>
    </>
  );
}

export default Services;