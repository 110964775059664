import { Button, styled, Typography } from "@mui/material";
import React from "react";
import styles from "./AdvicePage.module.css"
import { useTranslation } from "react-i18next";
import advices from "../../../constants/advices";
import PageLayout from "../../../components/layouts/PageLayout";
import BookButton from "../../../components/widgets/BookButton";
import BookButtonWhite from "../../../components/widgets/BookButtonWhite";
import { useHistory } from "react-router-dom";
import URLS from "../../../constants/urls";
import OrderDialog from "../../../components/dialogs/OrderDialog";

const AdvicePage = ({ advice }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [showDialog, setShowDialog] = React.useState(false);

  const handleCallClick = () => {
    console.log("call click");
    history.push(URLS.CONTACTS);
  };

  return (
    <PageLayout>
    <div className={styles.center}>
      <div className={styles.box}>
        <div className={styles.imageBox}>
          <img src={advice.imageUrl} className={styles.image}/>
        </div>
        <div className={styles.content}>
          <Typography align="center" sx={{ fontSize: 24 }}>{ t(advice.titleKey) }</Typography>
          <Typography variant="body1" sx={{ mt: 2, whiteSpace: "pre-line" }}>{ t(advice.text) }</Typography>

          <div className={styles.boxCall}>
            <Typography className={styles.categoryTitle} variant="p">{ t('hasQuestions') }</Typography>
            <BookButtonWhite titleKey="haveACall" onClick={handleCallClick}/>
            <BookButtonWhite onClick={() => setShowDialog(true)} />
          </div>
          
          { showDialog && <OrderDialog onClose={() => setShowDialog(false)} open={showDialog} /> }
        </div>
      </div>
    </div>
    </PageLayout>
  );
};

export default AdvicePage;
