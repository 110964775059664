import * as React from 'react';
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Typography, styled } from "@mui/material";
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import CloseIcon from '@mui/icons-material/Close';
import { useMutation, useQueryClient } from 'react-query';
import REQUESTS from '../../../../net/requests';
import QUERY_KEYS from '../../../../net/query-keys';
import { logAndReset } from '../../../../utils/requestUtils';

dayjs.extend(isToday);

const BlurryDialog = styled(Dialog)(({ theme }) => ({
  backdropFilter: "blur(5px)"
}));

const DeleteConfirmationDialog = ({ open, onClose, record, scheduleId }) => {

  const removeRecordMutation = useMutation(REQUESTS.removeRecord(record.id))
  const queryClient = useQueryClient();

  const handleClose = () => {
    onClose();
  }

  const handleDelete = () => {
    removeRecordMutation.mutate({}, {
      onSuccess: (data, error, variables, context) => {
        console.log(`Record was removed successfully!`)
        queryClient.invalidateQueries(QUERY_KEYS.recordsOfSchedule(scheduleId));
        
        setTimeout(() => {
          onClose();
        }, 3000)
      },
      onError: () => {
        logAndReset(removeRecordMutation);
      }
    });
  }

  return (
    <BlurryDialog onClose={handleClose} open={open}  maxWidth="sm">
      <DialogTitle sx={{ m: 0, paddingRight:7 }} id="customized-dialog-title">
        Confirmation
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 12,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ paddingTop: 0}}>
        <div>
          <Typography sx={{marginBottom: 2}}>Are you sure to delete the record?</Typography>
          {
            removeRecordMutation.isLoading ? <Typography>Loading...</Typography> : 
              removeRecordMutation.isError ? <Typography>Error: {removeRecordMutation.error.message}</Typography> : 
                removeRecordMutation.isSuccess ? <Typography>Record was removed!</Typography> : 
                  <div>
                    <Button variant="outlined" onClick={handleDelete}>Delete</Button>
                    <Button variant="outlined" onClick={handleClose} sx={{ marginLeft: 2}}>Cancel</Button>
                  </div>
          }
        </div>
      </DialogContent>
    </BlurryDialog>
  );
};

export default DeleteConfirmationDialog;
