import OptionButton from "../../OptionButton";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import GroupIcon from '@mui/icons-material/Group';
import EventIcon from '@mui/icons-material/Event';
import styles from './InitPage.module.css';
import { useTranslation } from "react-i18next";
import { trackBookingPage } from "../../../../../ga";
import { useEffect } from "react";

const InitPage = ({ navigator }) => {
  const { t } = useTranslation();

  useEffect(() => {
    trackBookingPage("InitPage");
  }, []);

  return (
    <>
      <div className={styles.options}>
        <OptionButton title={ t("orderDialogServicesNextText") } icon={ <PlaylistAddIcon /> } onClick={() => navigator.openSelectServicePage() }/>  
        <OptionButton title={ t("orderDialogMastersNextText") } icon={ <GroupIcon /> } onClick={() => navigator.openSelectMasterPage() }/>
        <OptionButton title={ t("orderDialogTimesNextText") } icon={ <EventIcon /> } onClick={() => navigator.openSelectTimePage() }/>
      </div>
    </>
  );
};

export default InitPage;