import React from "react";
import styles from "./TopMenuButton.module.css";
import URLS from "../../../../constants/urls";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from "@mui/material";

const TopMenuButton = ({ isOpen, onOpen }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const lang = useSelector(state => state.lang)

  const handleOpen = (isOpen) => {
    if (onOpen) {
      onOpen(isOpen);
    }
  }

  return (
    <>
      <div className={styles.button} onClick={() => handleOpen(!isOpen)}>
        {
          isOpen ? <CloseIcon /> : <MenuIcon />
        }
        <Typography className={styles.title}>{ t('topMenuButtonName') }</Typography>
      </div>
    </>
  );
};

export default TopMenuButton;