import React, { useRef, useState } from "react";
import { Card, Typography } from "@mui/material";

const MetricCard = ({ title, value }) => {

  return (
    <>
      <Card sx={{ padding: 2, minWidth: 200 }}>
        <Typography>{ title }</Typography>
        <Typography sx={{ fontSize: 24 }}>{ value }</Typography>
      </Card>
    </>
  );
}

export default MetricCard;