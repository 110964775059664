import ACTION_LOG_OUT from "../actions/logout";
import ACTION_SAVE_MASTER_DATA from "../actions/saveMasterData";

export default function master(state = null, action) {
  switch (action.type) {
    case ACTION_SAVE_MASTER_DATA: {
      return action.master;
    }
    case ACTION_LOG_OUT: {
      return null;
    }

    default:
      return state;
  }
}
