
export const getPrice = (minPrice, maxPrice, isMobile) => {
  const priceSuffix = isMobile ? '' : ',00'
  if (!minPrice) {
    minPrice = 0;
  }
  if (!maxPrice) {
    maxPrice = 0;
  }
  if (minPrice !== 0 && maxPrice !== 0) {
    if (minPrice === maxPrice) {
      return `${minPrice}${priceSuffix} zł`;
    } else {
      return `${minPrice}${priceSuffix} zł - ${maxPrice}${priceSuffix} zł`;
    }
  } else if (maxPrice !== 0) {
    return `${maxPrice}${priceSuffix} zł`;
  } else {
    return `${minPrice}${priceSuffix} zł`;
  }
}

export const getTimeRequirements = (min, max) => {
  if (!min && !max) {
    return "??";
  }
  if (min && max) {
    if (min !== max) {
      return `${min} - ${max}`;
    } else {
      return `${min}`;
    }
  } else {
    if (!max) {
      return `${max}`;
    }
    if (!min) {
      return `${min}`;
    }
    return "???";
  }
}