import styles from './SeletTimePage.module.css';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'moment/locale/ru';
import 'moment/locale/pl';
import { useSelector } from "react-redux";
import { styled } from "@mui/material";
import { PickersDay } from "@mui/x-date-pickers";

const HighlightedDay = styled(PickersDay)(({ theme }) => ({
  "&.Mui-selected": {
    backgroundColor: theme.palette.primary.selected,
    color: theme.palette.primary.contrastText,
  },
}));

const SelectedDay = styled(PickersDay)(({ theme }) => ({
  "&.Mui-selected": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

const ServerDay = (props) => {
  const { highlightedDays = [], selectedDay = null, day, outsideCurrentMonth, ...other } = props;

  if (selectedDay && day.format("YYYY-MM-DD") === selectedDay.format("YYYY-MM-DD")) {
    return (
      <SelectedDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        selected={true}
      />
    );
  }
  const isSelected =
    !props.outsideCurrentMonth &&
    highlightedDays.includes(day.format("YYYY-MM-DD"));

  return (
    <HighlightedDay
      {...other}
      outsideCurrentMonth={outsideCurrentMonth}
      day={day}
      selected={isSelected}
    />
  );
};

const SelectDay = ({ value, onChange, highlightedDays }) => {
  
  const lang = useSelector((state) => state?.lang).title.toLowerCase();

  return (
    <>
      <div className={styles.services}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={lang}>
          <DateCalendar
            views={['day']}
            value={value} onChange={onChange}
            slots={{
              day: ServerDay,
            }}
            slotProps={{
              day: {
                highlightedDays,
                selectedDay: value
              },
            }}
            />
        </LocalizationProvider>
      </div>
    </>
  );
};

export default SelectDay;