import styles from './ConfirmActionWidget.module.css'
import { Button, Icon, IconButton, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';

const ConfirmActionWidget = ({ record, onConfirm, onDelete, askConfirmation, showTitle }) => {
  const user = useSelector((state) => state?.user);

  const deleteButton = <Button variant="outlined" aria-label="delete" size="medium" onClick={onDelete}>
    <DeleteIcon>Delete</DeleteIcon>
  </Button>

  const content = askConfirmation ? <>
    { showTitle && <Typography>Do you confirm the record?</Typography> }
    <div className={styles.row}>
      <Button variant="outlined" aria-label="delete" size="medium">
        <CheckIcon onClick={onConfirm}>Accept</CheckIcon>
      </Button>
      { deleteButton }
    </div>
  </> : deleteButton

  return <div className={styles.box}>
    { content }
  </div>;
}

export default ConfirmActionWidget;