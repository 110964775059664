import React, { useRef, useState } from "react";
import { Button, Card, CircularProgress, TextField, Typography } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "react-query";
import REQUESTS from "../../../../../net/requests";
import QUERY_KEYS from "../../../../../net/query-keys";
import ServerError from "../../../ServerError/ServerError";
import MastersItem from "../MastersItem";
import MasterServicesItem from "../MasterServicesItem";

const MasterServices = ({ master }) => {


  const servicesRequest = useQuery(QUERY_KEYS.getMasterServices(master.id), REQUESTS.getMasterServices(master.id), { refetchOnMount: true });

  if (servicesRequest.isLoading) {
    return <CircularProgress />
  }
  if (servicesRequest.isError) {
    return <ServerError/>
  }

  const services = servicesRequest.data;

  return (
    <>
      {
        services.length > 0 ? services.map(service => <MasterServicesItem key={service.id} service={service} />) : <Typography>Master doesn't provide the services</Typography>
      }
    </>
  );
}

export default MasterServices;