import * as React from 'react';
import styles from "./BookDialog.module.css";
import { useSelector, useDispatch } from "react-redux";
import { IconButton, Dialog, DialogTitle, DialogContent, CircularProgress, Button, Divider, styled } from "@mui/material";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import DatesList from '../DatesList';
import TimesList from '../TimesList';
import ServiceMasterBlock from '../ServiceMasterBlock';
import QUERY_KEYS from '../../../net/query-keys';
import REQUESTS from '../../../net/requests';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ServerError from '../../widgets/ServerError/ServerError';
import ConsentsBlock from '../ConsentsBlock';
import PriceBlock from '../PriceBlock';
import { logAndReset } from '../../../utils/requestUtils';
import ROLES from '../../../constants/roles';
import UserSelector from '../../widgets/UserSelector';
import BookFinalPage from './BookFinalPage';
import { useTranslation } from 'react-i18next';

dayjs.extend(isToday);

const DAY = 24 * 60 * 60 * 1000;

const dates = [Date.now(), Date.now() + 1 * DAY, Date.now() + 2 * DAY, Date.now() + 3 * DAY, Date.now() + 4 * DAY, Date.now() + 5 * DAY, Date.now() + 6 * DAY]

const BlurryDialog = styled(Dialog)(({ theme }) => ({
  backdropFilter: "blur(5px)"
}));

const buildTimes = (selectedDayData) => {
  if (!selectedDayData) {
    return [];
  }
  const day = selectedDayData.day;
  const items = selectedDayData.times;
  const times = [];
  items.forEach(item => {
    if (item >= day && item < day + DAY) {
      times.push(item);
    }
  });
  console.log('buildTimes:', times)
  return times;
}


const BookDialog = ({ service, onClose, open, children }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector((state) => state?.user);
  const lang = useSelector((state) => state.lang).title.toLowerCase();
  const [selectedDayData, setSelectedDayData] = React.useState()
  const [selectedTime, setSelectedTime] = React.useState()
  const [master, setMaster] = React.useState()
  const [policyError, setPolicyError] = React.useState({})
  const [recordError, setRecordError] = React.useState()
  const [noDays, setNoDays] = React.useState(false)
  const [targetUser, setTargetUser] = React.useState(null)
  const [finalPage, setFinalPage] = React.useState(false)
  const policyRef = React.useRef({})
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const mastersRequest = useQuery(QUERY_KEYS.getMastersForService(service.id), REQUESTS.getMastersForService(service.id), { refetchOnMount: true })
  const recordMutation = useMutation(REQUESTS.addRecord)
  
  
  if (mastersRequest.isLoading) {
    return <CircularProgress />
  }
  if (mastersRequest.isError) {
    return <ServerError/>
  }

  const masters = mastersRequest.data;
  if (!master && masters.length > 0) {
    setMaster(masters[0]);
    return <CircularProgress />;
  }

  if (selectedDayData && !selectedTime && selectedDayData.times.length > 0) {
    const dayItems = selectedDayData.times.filter((v, i, a) => {
      const itemDay = dayjs.utc(dayjs.utc(v).format("YYYY-MM-DD")).valueOf()
      return selectedDayData.day == itemDay;
    })
    if (dayItems.length > 0) {
      console.log("select first time slot:", dayItems[0]);
      setSelectedTime(dayItems[0]);
    }
  }



  const handleMasterChanged = (master) => {
    setMaster(master);
    setNoDays(false);
  };

  const handleEmptySlots = () => {
    setSelectedDayData(undefined);
    setSelectedTime(undefined)
    setNoDays(true)
  };

  const handleClose = (event) => {
    onClose();
  };

  const sameTimeExist = (dayData) => {
    const targetTimes = buildTimes(dayData)
    const newTime = dayData.day + (selectedTime - dayjs.utc(dayjs.utc(selectedTime).format("YYYY-MM-DD")).valueOf())
    const existTime = targetTimes.find(v => v === newTime);
    return existTime
  }

  const validateTime = (dayData) => {
    console.log('validateTime', dayData)
    console.log('validateTime2', selectedTime)
    const existTime = buildTimes(dayData).find(v => v === selectedTime);
    console.log('validateTime3', existTime)
    const sameTime = sameTimeExist(dayData)
    if (!existTime) {
      if (sameTime) {
        setSelectedTime(sameTime);
      } else {
        setSelectedTime(undefined);
      }
    }
  };

  const handleSelectedDayData = (dayData) => {
    console.log("handleSelectedDayData:", dayData);
    setSelectedDayData(dayData);
    //setSelectedTime(undefined);
    validateTime(dayData);
    setNoDays(false);
  }

  const handleContinue = () => {
    let keys = Array.from( Object.keys(policyRef.current) );
    const badKeys = keys.filter(key => policyRef.current[key].checked === false && policyRef.current[key].required === true)
    badKeys.forEach(key => {
      setPolicyError(prev => ({ ...prev, [key]: true}))
    })
    if (badKeys.length > 0) {
      return;
    }
    
    if (!selectedTime) {
      return setRecordError("You have to select time for record");
    }

    const data = {
      masterId: master.id,
      clientId: targetUser ? targetUser.id : user.id,
      serviceId: service.id,
      startTime: selectedTime,
      hasConsent: policyRef.current
    }
    console.log("record data:", data);

    recordMutation.mutate(data, {
      onSuccess: (data, error, variables, context) => {
        console.log('updated!')
        queryClient.invalidateQueries(QUERY_KEYS.findFreeSlots(master.id, service.id))
        setFinalPage(true);
      },
      onError: () => {
        logAndReset(recordMutation)
        queryClient.invalidateQueries(QUERY_KEYS.findFreeSlots(master.id, service.id))
      }
    });
  }

  const findText = translations => translations.find(v => v.lang === lang).text;
  const buildTitle = () => `${findText(service.titles)}`

  const noMasters = !master || masters.length === 0;
  const noEmptySlots = selectedDayData && selectedDayData.times.length === 0;
  return (
    <>
      <BlurryDialog onClose={handleClose} open={open} fullWidth={true} maxWidth="md">
        <DialogTitle sx={{ m: 0, paddingRight:7 }} id="customized-dialog-title">
          { finalPage ? t('bookDialogCongrats') : buildTitle() }
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 12,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent >
            {
              finalPage ? <BookFinalPage targetUser={targetUser} selectedTime={selectedTime} service={service} master={master}/> :
                noMasters ? <div className={styles.noMasters}>{ t('bookDialogNoMastersFound') }</div> :
                  noEmptySlots ? <div className={styles.noMasters}>{ t('bookDialogNoEmptySlots') }</div> :
                  <div className={styles.box}>
                    {
                      user && user.role !== ROLES.USER && <UserSelector user={targetUser} onChange={targetUser => setTargetUser(targetUser)}/>
                    }

                    {
                      //!selectedDayData || selectedDayData.times.length > 0 ?
                      !noDays ? 
                      <>
                        { 
                          master ? 
                          <DatesList selectedDay={selectedDayData ? selectedDayData.day : null} service={service} master={master} dayListener={handleSelectedDayData} emptySlots={handleEmptySlots} /> : 
                          <CircularProgress /> 
                        }
                        <TimesList times={buildTimes(selectedDayData)} selectedTime={selectedTime} onTimeChanged={(time) => setSelectedTime(time)}/>
                      </> :
                      <p>{ t('bookDialogNoEmptySlots') }</p>
                    }
                    
                    <ServiceMasterBlock service={service} masters={masters} selectedMaster={master} time={selectedTime} onMasterListener={handleMasterChanged}/>
                    
                    <ConsentsBlock policyRef={policyRef} error={policyError} />
                    <Divider />
                    { 
                      master ? 
                      <PriceBlock service={service} master={master} /> : 
                      <CircularProgress /> 
                    }
                    {
                      recordError && <p>{recordError}</p>
                    }
                    <Button variant="contained" onClick={handleContinue} disabled={noDays || !selectedTime}>{ t('bookDialogContinue') }</Button>
                  </div>
            }
            
        </DialogContent>
      </BlurryDialog>
    </>
  );
};

export default BookDialog;
