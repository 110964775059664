import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

// import '@fontsource/roboto/300.css';
// import '@fontsource/roboto/400.css';
// import '@fontsource/roboto/500.css';
// import '@fontsource/roboto/700.css';

import Routers from "./Routers";
import store from "./redux/store";

import "react-toastify/dist/ReactToastify.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./styles/index.css";
import mainTheme from "./styles/mainTheme";
import { ThemeProvider } from "@mui/material/styles";

import './components/i18n';

ReactDOM.render(
  <ThemeProvider theme={mainTheme}>
    <Provider store={store}>
      <Router>
        <Routers />
      </Router>
    </Provider>
  </ThemeProvider>,
  document.getElementById("root")
);

