import React, { createRef } from "react";

class DatesBox extends React.Component {

    resizeObserver = new ResizeObserver((entries) => {
        // You can iterate all of the element entries observed
        for (const entry of entries) {
            if (entry.target === this.box && this.listener) {
                console.log('DatesBox calls listener with width:', entry.contentRect.width);
                this.listener(entry.contentRect.width);
            }
        }
    });

    constructor(props) {
        super(props);
        this.listener = props.listener;
    }

    componentDidMount() {
        console.log("DatesBox - componentDidMount");
        this.resizeObserver.observe(this.box);
    }
  
    componentWillUnmount() {
        console.log("DatesBox - componentWillUnmount");
        this.resizeObserver.unobserve(this.box);
    }
  
    // Use a class arrow function (ES7) for the handler. In ES6 you could bind()
    // a handler in the constructor.
    handleResizeEvent = (event) => {
        console.log("ResizeEvent:", event);
    }
  
    render() {
        console.log('DatesBox render()');
        // Here we render a single <div> and toggle the "aria-nv-el-current" attribute
        // using the attribute spread operator. This way only a single <div>
        // is ever mounted and we don't have to worry about adding/removing
        // a DOM listener every time the current index changes. The attrs 
        // are "spread" onto the <div> in the render function: {...attrs}
        /// const attrs = this.props.index === 0 ? {"aria-nv-el-current": true} : {};
    
        // Finally, render the div using a "ref" callback which assigns the mounted 
        // elem to a class property "nv" used to add the DOM listener to.
        return (
            <div ref={elem => this.box = elem} >
                { this.props.children }
            </div>
        );
    }
  
  }
  
  export default DatesBox;