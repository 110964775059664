import * as React from 'react';
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Typography, styled } from "@mui/material";
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import CloseIcon from '@mui/icons-material/Close';
import { useMutation, useQueryClient } from 'react-query';
import { logAndReset } from '../../../utils/requestUtils';
import REQUESTS from '../../../net/requests';
import QUERY_KEYS from '../../../net/query-keys';
import styles from "./EditFakeDialog.module.css"
import { validateEmail, validatePhone } from '../../../utils/validateUtils';
import SourcePlatform from '../../../constants/sourcePlatform';

dayjs.extend(isToday);

const BlurryDialog = styled(Dialog)(({ theme }) => ({
  backdropFilter: "blur(5px)"
}));

const EditFakeDialog = ({ open, onClose, targetUser }) => {

  console.log("user", targetUser);
  const [name, setName] = React.useState(targetUser.fakeName)
  const [phone, setPhone] = React.useState(targetUser.phone)
  const [email, setEmail] = React.useState(targetUser.email)
  const [sourcePlatform, setSourcePlatform] = React.useState(targetUser.sourcePlatform ?? SourcePlatform.UNKNOWN)
  const [wrongEmail, setWrongEmail] = React.useState(false)
  const [wrongPhone, setWrongPhone] = React.useState(false)

  const renameUserMutation = useMutation(REQUESTS.renameUser(targetUser.id))
  const queryClient = useQueryClient();

  const handleClose = () => {
    onClose();
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setWrongEmail(false);
  }

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
    setWrongPhone(false);
  }

  const handleSave = () => {
    const data = {
      id: targetUser.id,
      fakeName: name,
      phone: phone === "" ? undefined : phone.replaceAll(" ", ""),
      email: email === "" ? undefined : email,
      sourcePlatform
    }

    if (data.phone && !validatePhone(data.phone)) {
      setWrongPhone(true);
      return;
    }

    if (data.email && !validateEmail(data.email)) {
      setWrongEmail(true);
      return;
    }

    renameUserMutation.mutate(data, {
      onSuccess: (data, error, variables, context) => {
        console.log(`User was removed successfully!`)
        queryClient.invalidateQueries(QUERY_KEYS.USERS);
        
        setTimeout(() => {
          onClose();
        }, 3000)
      },
      onError: (error, variables, context) => {
        console.log(`onError:`, JSON.stringify(error))
        logAndReset(renameUserMutation);
      }
    });
  }

  const handleSourceChange = (event) => {
    setSourcePlatform(event.target.value);
  }

  return (
    <BlurryDialog onClose={handleClose} open={open}  maxWidth="sm">
      <DialogTitle sx={{ m: 0, paddingRight:7 }} id="customized-dialog-title">
        Edit
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 12,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ paddingTop: 0}}>
        <div>
          <TextField sx={{margin: 1}} label="Name" value={name} onChange={event => setName(event.target.value)}></TextField>
          <TextField error={wrongPhone} sx={{margin: 1}} label="Phone" value={phone} onChange={handlePhoneChange} helperText={ wrongPhone ? "Incorrect phone" : "format: +48 XXX XXX XXX" }></TextField>
          <TextField error={wrongEmail} sx={{margin: 1}} label="Email" value={email} onChange={handleEmailChange} helperText={ wrongEmail ? "Incorrect email" : "" }></TextField>
          
          <FormControl sx={{m:1, width: 222}}>
            <InputLabel id="source-label">Источник</InputLabel>
            <Select
              labelId="source-label"
              value={sourcePlatform}
              label="Источник"
              onChange={handleSourceChange}
            >
              <MenuItem value={SourcePlatform.UNKNOWN}>Нет</MenuItem>
              <MenuItem value={SourcePlatform.INST}>Instagram</MenuItem>
              <MenuItem value={SourcePlatform.GOOGLE}>Google</MenuItem>
              <MenuItem value={SourcePlatform.OTHER}>Другой</MenuItem>
            </Select>
          </FormControl>
          {
            renameUserMutation.isLoading ? <Typography>Loading...</Typography> : 
              renameUserMutation.isError ? <Typography>Error: { renameUserMutation.error.response.status === 403 ? renameUserMutation.error.response.data.error.message : renameUserMutation.error.message}</Typography> : 
                renameUserMutation.isSuccess ? <Typography>User was updated!</Typography> : 
                  <div className={styles.buttons}>
                    <Button variant="outlined" onClick={handleSave}>Save</Button>
                    <Button variant="outlined" onClick={handleClose} sx={{ marginLeft: 2}}>Cancel</Button>
                  </div>
          }
        </div>
      </DialogContent>
    </BlurryDialog>
  );
};

export default EditFakeDialog;
