const LANGS = {
    EN: {
        code: 44,
        title: "EN",
        abbr: "GB"
    },
    PL: {
        code: 48,
        title: "PL",
        abbr: "PL"
    },
    RU: {
        code: 7,
        title: "RU",
        abbr: "RU"
    },
  };
  
  export default LANGS;
  