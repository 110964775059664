import ACTION_CHANGE_LANG from "../actions/changeLang";

export default function lang(state = null, action) {
  switch (action.type) {
    case ACTION_CHANGE_LANG: {
      return action.lang;
    }

    default:
      return state;
  }
}
