import React from "react";
import { useQuery } from "react-query";
import { CircularProgress, Typography } from "@mui/material";
import QUERY_KEYS from "../../../../../../net/query-keys";
import REQUESTS from "../../../../../../net/requests";
import ServerError from "../../../../ServerError/ServerError";
import { PieChart } from '@mui/x-charts/PieChart';
import styles from './AnalyticsPopular.module.css';
import { useSelector } from "react-redux";

const AnalyticsPopular = ({ startTime, endTime }) => {
  const palette = [
    '#E6B89C', '#7A9CC6', '#ADF1D2', '#E072A4', '#E072A4', '#E072A4', '#703D57', '#685044', '#3A405A', '#FFAFF0', 
    '#417B5A', '#4B4E6D', '#474973', '#DBEBC0', '#29524A', '#A1683A', '#C2C1A5', '#36453B', '#57886C', '#373E40', 
    '#A9FFCB', '#B33951', '#D4CBE5', '#BEA8AA', '#31081F', '#093A3E', '#407076', '#81559B', '#8C86AA', '#28536B', 
  ];
  const pieParams = { height: 200 };
  const lang = useSelector(state => state.lang).title.toLowerCase();

  const metricsRequest = useQuery(QUERY_KEYS.getPopularAnalytics(startTime, endTime), REQUESTS.getPopularAnalytics(startTime, endTime), { refetchOnMount: true });

  if (metricsRequest.isLoading) {
    return <CircularProgress />
  }
  if (metricsRequest.isError) {
    return <ServerError/>
  }

  const metrics = metricsRequest.data;
  const popular = metrics.popular;

  const findText = (id, items) => items.find(item => item.itemId === id && item.lang === lang)?.text ?? "???"
  
  return (
    <div className={styles.box}>
      <Typography>Сколько было записей на каждую процедуру в выбранный период времени:</Typography>
      <PieChart
        colors={palette}
        series={[
          { 
            arcLabel: (item) => `${item.label}`,
            arcLabelMinAngle: 20,
            data: popular.map(item => ({ value: item.count, label: `${item.itemId}` })) 
          }
        ]}
        {...pieParams}
      />
      
      <Typography>Где:</Typography>
      {
        metrics.servicesIds.sort((a, b) => a - b).map(id => <div key={id}>
            <Typography>{ id } - { findText(id, metrics.titles) } // { findText(id, metrics.subtitles) }</Typography>
          </div>
        )
      }
    </div>
  );
}

export default AnalyticsPopular;