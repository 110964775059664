import { Card, CircularProgress, styled, TextField, Typography } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import ServerError from "../../components/widgets/ServerError/ServerError";
import QUERY_KEYS from "../../net/query-keys";
import REQUESTS from "../../net/requests";
import styles from "./ConsentPage.module.css"
import { useParams } from 'react-router';
import { useSelector } from "react-redux";
import TranslationTypes from "../../constants/translationTypes";
import PageLayout from "../../components/layouts/PageLayout";


const ConsentPage = () => {
  const { id } = useParams();
  const consentRequest = useQuery(QUERY_KEYS.getConsent(id), REQUESTS.getConsent(id));
  const lang = useSelector(state => state.lang).title.toLowerCase();

  if (consentRequest.isLoading) {
    return <CircularProgress />
  }
  if (consentRequest.isError) {
    return <ServerError/>
  }

  const consent = consentRequest.data;
  const isForType = (item, type) => item.itemId === Number(id) && item.type === type && item.lang === lang
  const pageTitle = consent.translations.find(v => isForType(v, TranslationTypes.CONSENT_PAGE_TITLE))?.text
  const text = consent.translations.find(v => isForType(v, TranslationTypes.CONSENT_TEXT))

  return (
    <PageLayout>
      <div className={styles.content}>
        <Typography align="center" variant="h5" sx={{ marginTop: 1 }}>{ pageTitle ?? "" }</Typography>
        <div className={styles.center}>
          <Card sx={{ padding: 2, margin: 2, minWidth: 220, maxWidth: 1600 }}>
            <Typography display="block" style={{ wordWrap: "break-word", "white-space": "pre-wrap" }}>
              { text.text }
            </Typography>
          </Card>
        </div>
      </div>
    </PageLayout>
  );
};

export default ConsentPage;
