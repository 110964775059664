import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { IconButton, Dialog, DialogTitle, DialogContent, CircularProgress, Button, Divider, styled, FormControl, InputLabel, Select, MenuItem, TextField } from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import dayjs, { Dayjs } from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import QUERY_KEYS from '../../../net/query-keys';
import REQUESTS from '../../../net/requests';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { logAndReset } from '../../../utils/requestUtils';
import { useState } from 'react';
import ServerError from '../../widgets/ServerError/ServerError';

dayjs.extend(isToday);

const BlurryDialog = styled(Dialog)(({ theme }) => ({
  backdropFilter: "blur(5px)"
}));

const AddMasterDialog = ({ onClose, open, selectedUser }) => {
  const user = useSelector((state) => state?.user);
  const lang = useSelector((state) => state?.lang);

  const [name, setName] = useState("")
  const [surname, setSurname] = useState("")
  const [file, setFile] = useState()

  const queryClient = useQueryClient();
  const addMasterMutation = useMutation(REQUESTS.addMaster)

  const handleAddMaster = () => {
    const data = {
      userId: selectedUser.id,
      name,
      surname,
      file
    }
    addMasterMutation.mutate(data, {
      onSuccess: (data, error, variables, context) => {
        console.log(`new master was added successfully!`)
        queryClient.invalidateQueries(QUERY_KEYS.USERS);
        
        setTimeout(() => {
          addMasterMutation.reset();
          onClose();
        }, 3000)
      },
      onError: () => {
        logAndReset(addMasterMutation)
      }
    });
  }

  const handleClose = (event) => {
    onClose();
  };

  const handleFileChange = (event) => {
    if (event.target.files) {
      console.log(event.target.files[0].name);
      setFile(event.target.files[0]);
    }
  }

  return (
    <>
      <BlurryDialog onClose={handleClose} open={open} fullWidth={true} maxWidth="sm">
        <DialogTitle sx={{ m: 0, paddingRight:7 }}>
          Cteate Master
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 12,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent >
          <div>
            <FormControl fullWidth>
              <TextField value={name} onChange={event => setName(event.target.value)} label="name"/>
              <TextField value={surname} onChange={event => setSurname(event.target.value)} label="surname"/>
              <input accept="image/*" type="file" onChange={handleFileChange} />
            </FormControl>

            {
              addMasterMutation.isLoading ? ('Adding master...') :
                addMasterMutation.isError ? <div>An error occurred: { addMasterMutation.error.message }</div> : 
                  addMasterMutation.isSuccess ? <div>Master was added!</div> : 
                    <Button variant='outlined' onClick={handleAddMaster}>Add</Button>
            }
            
          </div>
        </DialogContent>
      </BlurryDialog>
    </>
  );
};

export default AddMasterDialog;
