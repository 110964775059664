import React from "react";
import "./ServiceListItem.css";
import { Button, TextField, ThemeProvider, createTheme } from '@mui/material';
import { useSelector } from "react-redux";
import LANGS from "../../../constants/langs";
import { useTranslation } from "react-i18next";
import { getPrice, getTimeRequirements } from "../../dialogs/utils";

const blackTheme = createTheme({
  palette: {
    type: 'dark',
    button: {
      main: '#ffffff'
    }
  }
});

const ServiceListItem = ({ service, onBook, children }) => {

  const lang = useSelector(state => state.lang);
  const { t } = useTranslation()
  const title = service.titles.find((v, i, a) => v.lang === lang.title.toLowerCase())?.text ?? ''
  const description = service.descriptions.find((v, i, a) => v.lang === lang.title.toLowerCase())?.text ?? ''

  return (
    <>
      <div className="service-list-item">
        <img src={service.imageUrl} />

        <div className="service-list-item-info">
          <p className="service-list-item-title">{title}</p>
          <p className="service-list-item-desc">{description}</p>
        </div>

        <div className="service-list-item-pricebox">
          <div className="service-list-item-values">
            <div className="service-list-item-values-box">
              <p className="service-list-item-values-title">{ t('serviceItemTime') }</p>
              <p className="service-list-item-values-value">{ getTimeRequirements(service.timeRequirementsMin, service.timeRequirementsMax) } { t('serviceItemTimeMin') }</p>
            </div>
            <div className="service-list-item-values-box">
              <p className="service-list-item-values-title">{ t('serviceItemPrice') }</p>
              <p className="service-list-item-values-value">{ getPrice(service.minPrice, service.maxPrice) }</p>
            </div>
          </div>

          <ThemeProvider theme={blackTheme}>
            <Button onClick={onBook} color="button" variant="contained" style={{ borderRadius: 50 }}>Order</Button>
          </ThemeProvider>
          
        </div>

      </div>
    </>
  );
};

export default ServiceListItem;