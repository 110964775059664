import React, { Children, useCallback, useEffect, useState } from "react";
import AppLayout from "./components/layouts/AppLayout";
import "./App.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import restClient, { RestService } from "./net/RestService";
import signIn from "./redux/actionCreators/signIn";
import QUERY_KEYS from "./net/query-keys";
import REQUESTS from "./net/requests";
import trackPathForAnalytics from "./ga";
import { useLocation } from "react-router";
import { loginUser } from "./utils/loginUtils";
import { useTranslation } from "react-i18next";
import firebaseHelper from "./components/firebase"
import TimesInMS from "./constants/times";
import LocalStorageKeys from "./constants/localStorageKeys";
import ENDPOINTS from "./net/endpoints";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
})

const auth = getAuth(firebaseHelper.firebaseApp);

const App = ({ children }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch()
  const { pathname, search } = useLocation();
  const lang = useSelector((state) => state?.lang);

  const analytics = useCallback(() => {
      trackPathForAnalytics({ path: pathname, title: pathname.split("/")[1] });
  }, [pathname, search]);

  useEffect(() => {
      analytics();
  }, [analytics]);
  
  useEffect(() => {
    i18n.changeLanguage(lang.abbr.toLowerCase())
    localStorage.setItem(LocalStorageKeys.LANG, lang.title.toLowerCase());
  }, [lang.abbr]);

  const prefetchData = async () => {
    await queryClient.prefetchQuery({queryKey: QUERY_KEYS.CATEGORIES, queryFn: REQUESTS.getCategories, staleTime: TimesInMS.HOUR })
    await queryClient.prefetchQuery({queryKey: QUERY_KEYS.FEATURED_SERVICES, queryFn: REQUESTS.getFeaturedServices, staleTime: TimesInMS.HOUR })
  }

  const firstLoad = async () => {
    await restClient.get(ENDPOINTS.SESSION);
  }

  useEffect(() => {
    onAuthStateChanged(auth, (authUser) => {
      console.log('auth state changed', authUser)
      if (authUser) {
        const passProvider = authUser.providerData.find(
          (provider) => provider.providerId === "password"
        );
        const googleProvider = authUser.providerData.find(
          (provider) => provider.providerId === "google.com"
        );
        console.log('providers', passProvider, googleProvider)
        authUser.getIdTokenResult().then((idTokenResult) => {
          const currentProvider = idTokenResult.signInProvider;
          console.log('currentProvider', currentProvider)
          console.log('idTokenResult', idTokenResult.token)
          
          loginUser(dispatch, idTokenResult.token, queryClient).then(() => {
            console.log("login from effect");
          }).catch(console.log)
        });
      }
    });

    prefetchData()

    firstLoad()
  }, []);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <AppLayout>
          {children}
        </AppLayout>
      </QueryClientProvider>
    </>
  );
};

export default App;
