import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";

import App from "./App";
import URLS from "./constants/urls";
import { Redirect } from "react-router";
import NotFoundPage from "./pages/NotFoundPage";
import {isUrlWrong} from "./utils/isUrlWrong";
import MainPage from "./pages/MainPage";
import ServicesPage from "./pages/ServicesPage";
import ProfilePage from "./pages/ProfilePage";
import ContactsPage from "./pages/ContactsPage";
import CategoriesPage from "./pages/CategoriesPage";
import LoginPage from "./pages/LoginPage";
import RegistrationPage from "./pages/RegistrationPage";
import RestorePage from "./pages/RestorePage";
import ConsentPage from "./pages/ConsentPage";
import QuestionnairePage from "./pages/QuestionnairePage";
import SubscriptionsPage from "./pages/SubscriptionsPage";
import GiftCardsPage from "./pages/GiftCardsPage";
import CodeConfirmationPage from "./pages/CodeConfirmationPage";
import AdvicesPage from "./pages/AdvicesPage";
import advices from "./constants/advices";
import AdvicePage from "./pages/AdvicesPage/AdvicePage";
import ScrollToTop from "./utils/scrollToTop";
import RecordsPage from "./pages/RecordsPage";

const Routers = () => {
  const location = useLocation();
  const history = useHistory();
  const user = useSelector((state) => state?.user);

  const routes = [
    { path: URLS.PROFILE, component: ProfilePage, component2: <ProfilePage/>, exact: false },
    { path: URLS.SERVICES, component: CategoriesPage, component2: <CategoriesPage/>, exact: true },
    { path: URLS.SUBSCRIPTIONS, component: SubscriptionsPage, component2: <SubscriptionsPage/>, exact: true },
    { path: URLS.GIFTS, component: GiftCardsPage, component2: <GiftCardsPage/>, exact: true },
    { path: URLS.CONTACTS, component: ContactsPage, component2: <ContactsPage/>, exact: true },
    { path: URLS.CONSENTS, component: ConsentPage, component2: <ConsentPage/>, exact: true },
    { path: URLS.CATEGORY, component: ServicesPage, component2: <ServicesPage/>, exact: false },
    { path: URLS.LOGIN, component: LoginPage, component2: <LoginPage/>, exact: true },
    { path: URLS.RESTORE_PASSWORD, component: RestorePage, component2: <RestorePage/>, exact: true },
    { path: URLS.REGISTRATION, component: RegistrationPage, component2: <RegistrationPage/>, exact: true },
    { path: URLS.QUESTIONNAIRE, component: QuestionnairePage, component2: <QuestionnairePage/>, exact: false },
    { path: URLS.NOT_FOUND, component: NotFoundPage, component2: <NotFoundPage/>, exact: true },
    { path: URLS.CODE_CONFIRM, component: CodeConfirmationPage, component2: <CodeConfirmationPage confirm={true}/>, exact: true },
    { path: URLS.CODE_REJECT, component: CodeConfirmationPage, component2: <CodeConfirmationPage confirm={false}/>, exact: true },
    ...(advices.map(advice => ({ path: URLS.advicePage(advice.urlPart), component: AdvicePage, component2: <AdvicePage advice={advice}/>, exact: true }))),
    { path: URLS.ADVICES, component: AdvicesPage, component2: <AdvicesPage/>, exact: true },
    { path: URLS.RECORDS_HISTORY, component: RecordsPage, component2: <RecordsPage/>, exact: true },
    { path: URLS.ROOT_URL, component: MainPage, component2: <MainPage/>, exact: true },
    { path: URLS.ANY, component: NotFoundPage, component2: <NotFoundPage/>, exact: true },
  ];

  const renderRouters = routes.map((route, idx) => (
    <Route
      exact={route.exact}
      path={route.path}
      key={route.path || idx}
    >
      { route.component2 }
    </Route>
  ));

  return (
    <>
      {/* {isUrlWrong(location) && <Redirect to={URLS.NOT_FOUND} push/>} */}
      <App>
        <ScrollToTop />
        <Switch>{renderRouters}</Switch>
      </App>
      
    </>
  );
};

export default Routers;
