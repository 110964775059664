import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { IconButton, Dialog, DialogTitle, DialogContent, CircularProgress, Button, Divider, styled, FormControl, InputLabel, Select, MenuItem, TextField } from "@mui/material";
import styles from './CreateFeedbackDialog.module.css';
import CloseIcon from '@mui/icons-material/Close';
import dayjs, { Dayjs } from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import QUERY_KEYS from '../../../net/query-keys';
import REQUESTS from '../../../net/requests';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { logAndReset } from '../../../utils/requestUtils';
import { useState } from 'react';
import ServerError from '../../widgets/ServerError/ServerError';
import { useTranslation } from 'react-i18next';
import { validateEmail } from '../../../utils/validateUtils';

dayjs.extend(isToday);

const BlurryDialog = styled(Dialog)(({ theme }) => ({
  backdropFilter: "blur(5px)"
}));

const CreateFeedbackDialog = ({ onClose, open, selectedUser }) => {
  const user = useSelector((state) => state?.user);
  const { t } = useTranslation();

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [text, setText] = useState("")
  const [showDialog, setShowDialog] = useState(false)
  const [errorName, setErrorName] = useState(false)
  const [errorEmail, setErrorEmail] = useState(false)
  const [errorText, setErrorText] = useState(false)

  const queryClient = useQueryClient();
  const createFeedbackMutation = useMutation(REQUESTS.createFeedback())

  const handleCreateFeedback = () => {
    const data = {
      userId: user?.id ?? undefined,
      name,
      email,
      phone,
      text
    }
    var hasError = false;
    if (name.length === 0) {
      setErrorName(true)
      hasError = true;
    }
    if (email.length === 0 || !validateEmail(email)) {
      setErrorEmail(true)
      hasError = true;
    }
    if (text.length === 0) {
      setErrorText(true)
      hasError = true;
    }
    if (hasError) return;

    createFeedbackMutation.mutate(data, {
      onSuccess: (data, error, variables, context) => {
        setTimeout(() => {
          createFeedbackMutation.reset()
          setShowDialog(false);
        }, 3000)
      },
      onError: () => {
        logAndReset(createFeedbackMutation)
      }
    });
  }

  const handleClose = (event) => {
    setShowDialog(false);
  };

  const handleFileChange = (event) => {
    if (event.target.files) {
      console.log(event.target.files[0].name);
    }
  }

  return (
    <>
      <Button color="feedbackButton" variant="outlined" sx={{ borderRadius: 12, textTransform: 'none', whiteSpace: 'nowrap' }} onClick={() => setShowDialog(true)} >
        { t('feedbackDialogCreate') }
      </Button>
      {
        showDialog && <BlurryDialog onClose={handleClose} open={true} fullWidth={true} maxWidth="sm">
        <DialogTitle sx={{ m: 0, paddingRight:7 }}>
          { t('feedbackDialogCreate') }
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 12,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent >
          <div>
            <FormControl fullWidth sx={{ mt: 0 }}>
              <div className={styles.fields}>
                <div className={styles.namePhone}>
                  <TextField value={name} onChange={event => { setName(event.target.value); setErrorName(false); }} label={ t('name') + ' *' } error={errorName}/>
                  <TextField value={phone} onChange={event => setPhone(event.target.value)} label={ t('profilePhone') }/>
                </div>
                <TextField value={email} onChange={event => { setEmail(event.target.value); setErrorEmail(false); }} label={ t('email') + ' *' } error={errorEmail}/>
                <TextField value={text} onChange={event => { setText(event.target.value); setErrorText(false); }} label={ t('text') + ' *' } error={errorText} fullWidth multiline={true} rows={3}/>
              </div>
            </FormControl>

            <div className={styles.send}>
            {
              createFeedbackMutation.isLoading ? t('sending') :
                createFeedbackMutation.isError ? <div>An error occurred: { createFeedbackMutation.error.message }</div> : 
                  createFeedbackMutation.isSuccess ? <div>Feedback was added!</div> : 
                  <Button variant='outlined' onClick={handleCreateFeedback}>{ t('send') }</Button>
            }
            </div>
            
          </div>
        </DialogContent>
      </BlurryDialog>
      }
      
    </>
  );
};

export default CreateFeedbackDialog;
