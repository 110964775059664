import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { loginUser } from "../../utils/loginUtils";
import URLS from "../../constants/urls";
import { getAnalytics } from "firebase/analytics";

const app = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

const analytics = getAnalytics(app);
console.log("process.env.REACT_APP_FIREBASE_APP_ID", process.env.REACT_APP_FIREBASE_APP_ID)

const handleGoogleLoginAndRedirect = async (history, dispatch, redirectCallback, queryClient) => {
  const auth = getAuth();
  const googleProvider = new GoogleAuthProvider();
  const firebaseResponse = await signInWithPopup(auth, googleProvider);
  const token = await firebaseResponse.user.getIdToken();
  await loginUser(dispatch, token, queryClient);
  console.log("login from google button")
  if (redirectCallback) {
    redirectCallback();
  } else {
    history.replace(URLS.ROOT_URL);
  }
};


const firebaseHelper = {
  firebaseApp: app,
  googleLogin: handleGoogleLoginAndRedirect
}

export default firebaseHelper;