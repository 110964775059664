import * as React from 'react';
import { CircularProgress, Link, Typography, styled } from "@mui/material";
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import URLS from '../../../../constants/urls';
import styles from './BookFinalPage.module.css'
import QuestionsView from './QuestionsView';
import { useTranslation } from 'react-i18next';
import { masterDisplayName } from '../../../../utils/formatUtils';
import { useSelector } from 'react-redux';

dayjs.extend(isToday);

const BookFinalPage = ({ targetUser, selectedTime, service, master }) => {
  const { t } = useTranslation();
  const lang = useSelector((state) => state?.lang).title.toLowerCase();

  const handleInviteClick = () => {
    navigator.clipboard.writeText(URLS.invitePage(targetUser.inviteCode))
  }

  return (
    <div className={styles.box}>
      <Typography>{ t('bookDialogFinalYouHaveRecord') }:</Typography>
      <div className={styles.time}>
        <Typography variant='h4'>{ dayjs(selectedTime).format('HH:mm') }</Typography>
        <Typography variant='h6'>{ dayjs(selectedTime).format('DD.MM.YYYY') }</Typography>
      </div>
      <div className={styles.master}>
        <Typography variant='h6'>{ t('bookDialogFinalMaster') }: { masterDisplayName(master, lang) }</Typography>
      </div>
      <QuestionsView profile={targetUser}/>
      {
        targetUser && targetUser.inviteCode && <Typography variant='a' onClick={handleInviteClick} sx={{ marginTop: 2, textDecoration: 'underline', cursor: 'pointer' }}>{ URLS.invitePage(targetUser.inviteCode)}</Typography>
      }
    </div>
  );
};

export default BookFinalPage;
