import React, { useRef, useState } from "react";
import "./Consents.css";
import ExpandMoreItem from "../ExpandMoreItem";
import { Button, Card, Checkbox, MenuItem, Select, TextField } from "@mui/material";
import Text from "../../Text";
import ConsentTypes from "../../../../constants/consentTypes";
import { useMutation, useQueryClient } from "react-query";
import REQUESTS from "../../../../net/requests";
import { logAndReset } from "../../../../utils/requestUtils";
import QUERY_KEYS from "../../../../net/query-keys";
import ConsentsList from "../ConsentsList";

const Consents = () => {

  const [showCreatePanel, setShowCreatePanel] = useState(false);
  const [type, setType] = useState(ConsentTypes.RECORD);
  const keyRef = useRef();
  const requiredRef = useRef();

  const createConsentMutation = useMutation(REQUESTS.postConsent);
  const queryClient = useQueryClient();
  
  const handleCreateConsent = () => {
    const data = {
      key: keyRef.current.value,
      type: type,
      required: requiredRef.current.checked
    }
    console.log('create consent:', data);
    createConsentMutation.mutate(data, {
      onSuccess: (data, error, variables, context) => {
        console.log('consent is created!')
        queryClient.invalidateQueries(QUERY_KEYS.CONSENTS);
        
      },
      onError: () => {
        logAndReset(createConsentMutation)
      }
    });
  }

  const handleChange = (event) => {
    setType(event.target.value);
  }

  return (
    <>
      <ExpandMoreItem text="Consents">
        <ConsentsList />
        
        <Button variant="outlined" onClick={() => setShowCreatePanel(!showCreatePanel)}>{showCreatePanel ? 'Hide' : 'Add'} new Consent</Button>
        {
          showCreatePanel && <Card sx={{ marginTop: 0, padding: 2 }} >
            <div id="consents-panel-box">
            <TextField label="key" inputRef={keyRef} />

            <div className="consents-panel-required">
              <Checkbox label="key" inputRef={requiredRef}/>
              <Text>Required</Text>
            </div>

            <Select
              value={type}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value={ConsentTypes.RECORD}>{ConsentTypes.RECORD}</MenuItem>
              <MenuItem value={ConsentTypes.REGISTRATION}>{ConsentTypes.REGISTRATION}</MenuItem>
            </Select>

            {
              createConsentMutation.isLoading ? ('Adding consent...') : (
                <>
                  { createConsentMutation.isError ? <div>An error occurred: {createConsentMutation.error.message}</div> : null }
                  { createConsentMutation.isSuccess ? <div>Consent was added!</div> : null }
                </>
              )
            }
            <Button variant="outlined" onClick={handleCreateConsent} sx={{ marginTop: 2 }}>Create Consent</Button></div>
          </Card>
        }

      </ExpandMoreItem>
    </>
  );
}

export default Consents;