import { Typography, useMediaQuery } from "@mui/material";
import React from "react";
import styles from "./SubscriptionsPage.module.css"
import SubscriptionItem from "./SubscriptionItem";
import PageLayout from "../../components/layouts/PageLayout";
import { useTranslation } from "react-i18next";


const SubscriptionsPage = () => {
  const { t } = useTranslation();

  const maxW900 = useMediaQuery('(max-width:1300px)');

  return (
    <PageLayout fullscreen={true} >
      <div className={styles.videoBox}>
        <video className={styles.bgVideo} preload="auto" playsInline autoPlay={true} loop muted={true}>
            <source src="/face_time_intro.mp4" type="video/mp4" />
        </video>
        <div className={styles.videoContent}>
          <Typography sx={{ fontSize: maxW900 ? 18 : 24 }} align="center">
            { t('giftCardsTitle1') }
            <br/>
            { t('giftCardsTitle2') }
            <br/>
            { t('giftCardsTitle3') }
          </Typography>
        </div>
      </div>
    </PageLayout>
  );
};

export default SubscriptionsPage;
