import { Button, CircularProgress, List, ListItem, Paper, styled, Typography } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useParams } from 'react-router';
import { useSelector } from "react-redux";
import dayjs, { Dayjs } from 'dayjs';
import TranslationTypes from "../../../../constants/translationTypes";
import styles from "./UnconfirmedRecord.module.css"
import ConfirmationDialog from "../../../../components/dialogs/ConfirmationDialog";
import REQUESTS from "../../../../net/requests";
import QUERY_KEYS from "../../../../net/query-keys";
import { useMutation, useQueryClient } from "react-query";
import { logAndReset } from "../../../../utils/requestUtils";
import MasterWidget from "../../../../components/widgets/profile/MasterWidget";
import TimeWidget from "../../../../components/widgets/profile/TimeWidget";
import ServiceWidget from "../../../../components/widgets/profile/ServiceWidget";
import UserConfirmInfoWidget from "./UserConfirmInfoWidget";
import ConfirmActionWidget from "../../../../components/widgets/profile/ConfirmActionWidget";
import UserWidget from "./UserWidget";


const UnconfirmedRecord = ({ record }) => {
  const master = useSelector((state) => state?.master);

  const [showDialog, setShowDialog] = useState(false);

  const removeMutation = useMutation(REQUESTS.removeRecord(record.id))
  const confirmMutation = useMutation(REQUESTS.confirmRecord(record.id))
  const queryClient = useQueryClient();

  const handleDelete = () => {
    removeMutation.mutate({}, {
      onSuccess: (data, error, variables, context) => {
        console.log(`Removed!`);
        
        setTimeout(() => {
          queryClient.invalidateQueries(QUERY_KEYS.getUserRecords(record.clientId));
          if (master) {
            queryClient.invalidateQueries(QUERY_KEYS.getUnconfirmedRecords(master.id));
          }
          removeMutation.reset()
        }, 3000)
      },
      onError: (error, variables, context) => {
        console.log(`onError:`, JSON.stringify(error))
        logAndReset(removeMutation);
      }
    });
  }
  const handleConfirm = () => {
    confirmMutation.mutate({}, {
      onSuccess: (data, error, variables, context) => {
        queryClient.invalidateQueries(QUERY_KEYS.getUserRecords(record.clientId));
        if (master) {
          queryClient.invalidateQueries(QUERY_KEYS.getUnconfirmedRecords(master.id));
        }
        confirmMutation.reset()
      },
      onError: (error, variables, context) => {
        console.log(`onError:`, JSON.stringify(error))
        logAndReset(confirmMutation);
      }
    });
  }

  return (
    <ListItem sx={{ padding: 0, marginTop: 1 }}>
      <div className={styles.box}>
        <div className={styles.row}>
          <UserWidget record={record} />
          <ServiceWidget record={record} centerOnSmall={true}/>
          <TimeWidget record={record} />
          <UserConfirmInfoWidget record={record} />
          {
            confirmMutation.isLoading || confirmMutation.isSuccess ? <CircularProgress /> :
              <ConfirmActionWidget record={record} onConfirm={handleConfirm} onDelete={() => setShowDialog(true)} askConfirmation={!record.hasMasterConfirmation} showTitle={false} />
          }

          {
            removeMutation.isLoading ? <Typography>Loading...</Typography> : 
              removeMutation.isError ? <Typography>Error: { removeMutation.error.response.status === 403 ? removeMutation.error.response.data.error.message : removeMutation.error.message}</Typography> : 
                removeMutation.isSuccess ? <Typography>Done!</Typography> : 
                  showDialog && <div><ConfirmationDialog open={showDialog} onClose={() => setShowDialog(false)} text="Are you sure you want to delete the record?" onConfirm={handleDelete} /></div>
          }
          
        </div>
      </div>
    </ListItem>
  );
};

export default UnconfirmedRecord;
