
const advices = [{
  key: 1,
  imageUrl: "/img/a/skin_care1.webp",
  titleKey: "advicesItem1Title",
  urlPart: "how-to-take-care-of-your-facial-skin-in-the-spring-summer-period",
  text: "advice1Text"
}, {
  key: 2,
  imageUrl: "/img/a/pilling2.webp",
  titleKey: "advicesItem2Title",
  urlPart: "what-are-the-benefits-of-enzymatic-peeling",
  text: "advice2Text"
}, {
  key: 3,
  imageUrl: "/img/a/spf3.webp",
  titleKey: "advicesItem3Title",
  urlPart: "how-to-choose-the-right-spf-cream",
  text: "advice3Text"
}, {
  key: 4,
  imageUrl: "/img/a/skin_type4.webp",
  titleKey: "advicesItem4Title",
  urlPart: "how-to-determine-your-skin-type-with-a-paper-tissue",
  text: "advice4Text"
}, {
  key: 5,
  imageUrl: "/img/a/stages5.webp",
  titleKey: "advicesItem5Title",
  urlPart: "stages-of-basic-skin-care",
  text: "advice5Text"
}, {
  key: 6,
  imageUrl: "/img/a/around_eyes6.webp",
  titleKey: "advicesItem6Title",
  urlPart: "skin-care-around-the-eyes",
  text: "advice6Text"
}]

export default advices;