import React, { useEffect, useRef, useState } from "react";
import ExpandMoreItem from "../ExpandMoreItem";
import { Avatar, Button, Card, Checkbox, CircularProgress, Divider, IconButton, ListItem, ListItemIcon, Menu, MenuItem, Select, TextField } from "@mui/material";
import Text from "../../Text";
import ConsentTypes from "../../../../constants/consentTypes";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ROLES from "../../../../constants/roles";
import styles from "./UserListItem.module.css"
import AddMasterDialog from "../../../dialogs/AddMasterDialog";
import { useSelector } from "react-redux";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LinkFakeUsersDialog from "../../../dialogs/LinkFakeUsersDialog";
import ConfirmDeleteFakeDialog from "../../../dialogs/ConfirmDeleteFakeDialog";
import EditFakeDialog from "../../../dialogs/EditFakeDialog";
import URLS from "../../../../constants/urls";
import { Link } from "react-router-dom";
import { userDisplayName } from "../../../../utils/formatUtils";

const UserListItem = ({ user }) => {
  const currentUser = useSelector(state => state.user);
  
  const [masterDialogOpen, setMasterDialogOpen] = useState(false)
  const [showLinkDialog, setShowLinkDialog] = useState(false)
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false)
  const [showEditFakeDialog, setShowEditFakeDialog] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const [menuAnchor, setMenuAnchor] = useState(null)

  const queryClient = useQueryClient();
  

  const handleCloseDialog = () => {
    setMasterDialogOpen(false);
    setShowLinkDialog(false);
    setShowConfirmDeleteDialog(false);
    setShowEditFakeDialog(false);
  }

  const handleMenuClick = (event) => {
    setMenuAnchor(event.currentTarget);
    setMenuOpen(true);
  }

  const handleLinkToFake = () => {
    setShowLinkDialog(true);
    setMenuOpen(false);
  }

  const handleSetMaster = () => {
    setMasterDialogOpen(true);
    setMenuOpen(false);
  }

  const handleDeleteFake = () => {
    setShowConfirmDeleteDialog(true);
    setMenuOpen(false);
  }

  const handleEditFake = () => {
    setShowEditFakeDialog(true);
    setMenuOpen(false);
  }

  const canBeEdited = user.role === ROLES.USER && user.isFake === true && currentUser.role !== ROLES.USER;
  const canBeLinkedToFake = user.role === ROLES.USER && user.isFake === false && currentUser.role !== ROLES.USER;
  const canBeMaster = user.role === ROLES.USER && user.isFake === false && currentUser.role === ROLES.ADMIN;

  const showMenuButton = canBeLinkedToFake || canBeMaster || canBeEdited;

  return (
    <div>
      <ListItem 
        sx={{ padding: 0, marginTop: 1 }} 
        secondaryAction={
          showMenuButton && 
            <IconButton edge="end" onClick={handleMenuClick}>
              <MoreVertIcon />
            </IconButton>
        }
      >
        <div className={styles.userItem}>
          <div className={styles.userAvatar}>
            <Avatar alt={user?.displayName} src={ user?.avatar ? user.avatar : "/img/no_photo.png" } sx={{ width: 36, height: 36 }} />
            
            { 
              user.role !== ROLES.USER &&
                <Text>{ user.role === ROLES.MODERATOR ? "(Master)" : "(Admin)" }</Text> 
            }
          </div>


          <div className={styles.userInfo}>
            <Text>id: { user.id }</Text>
            <Text sx={{ paddingTop: 0, paddingBottom: 0 }}>email: { user.email }</Text>
            <Text>name: <Link to={URLS.profileOf(user.id)}>{ userDisplayName(user, false) }</Link></Text>
          </div>

          {
            showMenuButton &&
              <Menu
                id="basic-menu"
                anchorEl={menuAnchor}
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                { canBeLinkedToFake && <MenuItem onClick={handleLinkToFake}>Link to Fake User</MenuItem> }
                { canBeMaster && <MenuItem onClick={handleSetMaster}>Set as master</MenuItem> }
                { canBeEdited && <MenuItem onClick={handleEditFake}>Edit</MenuItem> }
                { canBeEdited && <MenuItem onClick={handleDeleteFake}>Delete user</MenuItem> }
              </Menu>
          }

          {
            showLinkDialog && <LinkFakeUsersDialog open={showLinkDialog} onClose={handleCloseDialog} targetUser={user} />
          }

          {
            showConfirmDeleteDialog && <ConfirmDeleteFakeDialog open={showConfirmDeleteDialog} onClose={handleCloseDialog} targetUser={user} />
          }

          {
            showEditFakeDialog && <EditFakeDialog open={showEditFakeDialog} onClose={handleCloseDialog} targetUser={user} />
          }
          
        </div>
      </ListItem>
      <Divider light />
      
      <AddMasterDialog selectedUser={user} open={masterDialogOpen} onClose={handleCloseDialog}/>
    </div>
  );
}

export default UserListItem;