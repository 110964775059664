import React, { useRef, useState } from "react";
import "./EditConsentItemText.css";
import ExpandMoreItem from "../ExpandMoreItem";
import { Button, Card, Checkbox, CircularProgress, MenuItem, Select, TextField } from "@mui/material";
import Text from "../../Text";
import ConsentTypes from "../../../../constants/consentTypes";
import { useMutation, useQuery } from "react-query";
import REQUESTS from "../../../../net/requests";
import { logAndReset } from "../../../../utils/requestUtils";
import QUERY_KEYS from "../../../../net/query-keys";
import ServerError from "../../ServerError/ServerError";
import TranslationTypes from "../../../../constants/translationTypes";

const EditConsentItemText = ({ lang, consentId }) => {
  const titleRef = useRef()
  const textRef = useRef()
  const pageTitleRef = useRef()

  const consentsRequest = useQuery(QUERY_KEYS.getConsentWithText(consentId), REQUESTS.getConsentWithText(consentId))
  
  const updateConsentTextMutation = useMutation(REQUESTS.postConsentText(consentId))
  
  const handleSendConsentText = () => {
    const data = {
      text: textRef.current.value,
      title: titleRef.current.value,
      pageTitle: pageTitleRef.current.value,
      lang
    }
    console.log('create consent:', data);
    updateConsentTextMutation.mutate(data, {
      onSuccess: (data, error, variables, context) => {
        console.log('consent is updated!')
        setTimeout(() => {
          updateConsentTextMutation.reset()
        }, 3000)
      },
      onError: () => {
        logAndReset(updateConsentTextMutation)
      }
    });
  }

  if (consentsRequest.isLoading) {
    return <CircularProgress />
  }
  if (consentsRequest.isError) {
    return <ServerError />
  }

  const consent = consentsRequest.data.consents.length > 0 ? consentsRequest.data.consents[0] : null
  const consentTitle = consentsRequest.data.translations.find((v, i, a) => v.type === TranslationTypes.CONSENT_TITLE && v.lang === lang)
  const consentText = consentsRequest.data.translations.find((v, i, a) => v.type === TranslationTypes.CONSENT_TEXT && v.lang === lang)
  const pageTitle = consentsRequest.data.translations.find((v, i, a) => v.type === TranslationTypes.CONSENT_PAGE_TITLE && v.lang === lang)

  return (
    <>
      <Text>Lang: { lang }</Text>
      <div><TextField inputRef={titleRef} label="title" defaultValue={consentTitle ? consentTitle.text : ''} /></div>
      <div><TextField inputRef={textRef} label="text" multiline rows={4} defaultValue={consentText ? consentText.text : ''}/></div>
      <div><TextField inputRef={pageTitleRef} label="page title" multiline rows={4} defaultValue={pageTitle ? pageTitle.text : ''}/></div>
      
      {
        updateConsentTextMutation.isLoading ? ('Updating consent...') : (
          <>
            { updateConsentTextMutation.isError ? <div>An error occurred: {updateConsentTextMutation.error.message}</div> : null }
            { updateConsentTextMutation.isSuccess ? <div>Consent was updated!</div> : <Button variant="outlined" onClick={handleSendConsentText}>Save</Button> }
          </>
        )
      }
      
    </>
  );
}

export default EditConsentItemText;