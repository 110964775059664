import React, { useEffect, useRef } from "react";
import styles from "./MenuFullscreen.module.css";
import URLS from "../../../../constants/urls";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from "@mui/material";
import TopMenuButton from "../button/TopMenuButton";
import Header from "../../Header";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import QUERY_KEYS from "../../../../net/query-keys";
import REQUESTS from "../../../../net/requests";
import { useLocation } from "react-router-dom";
import LANGS from "../../../../constants/langs";
import BookButtonWhite from "../../BookButtonWhite";

const MenuFullscreen = ({ isOpen, onClose, onBookClicked }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const lang = useSelector(state => state.lang)
  const currentPage = useRef(null);
  const location = useLocation(); 

  const categoriesRequest = useQuery(QUERY_KEYS.CATEGORIES, REQUESTS.getCategories)

  useEffect(() => {
    if (currentPage.current) {
      onClose(false);
    }
    if (!currentPage.current) {
      currentPage.current = location.pathname
    }
  }, [location])

  const handleClickConsultations = () => {

  }

  return (
    <div className={styles.box}>
      <div className={styles.header}>
        <TopMenuButton isOpen={true} onOpen={() => onClose(false)}/>
      </div>
      <div className={styles.content}>
        <ul className={styles.topMenuBox}>
          <li className={styles.topMenuItem}><Link to={URLS.ROOT_URL}>{t('topMenuMain')}</Link></li>
          <li className={styles.topMenuItem}><Link to={URLS.SERVICES}>{t('topMenuServices')}</Link></li>
          {
            categoriesRequest.isLoading === false && categoriesRequest.isError === false && 
              categoriesRequest.data.map(category => 
                category.translations.find(translation => translation.lang === LANGS.EN.title.toLowerCase())?.text.indexOf('Consultation') !== -1 ?
                  <li className={styles.topMenuItem}><Link to={URLS.categoryOf(category.id)}>{ category.translations.find(translation => translation.lang === lang.title.toLowerCase())?.text }</Link></li> : null
              )
          }
          <li className={styles.topMenuItem}><Link to={URLS.SUBSCRIPTIONS}>{t('topMenuSubscriptions')}</Link></li>
          <li className={styles.topMenuItem}><Link to={URLS.GIFTS}>{t('topMenuGiftCards')}</Link></li>
          <li className={styles.topMenuItem}><Link to={URLS.ADVICES}>{t('topMenuAdvices')}</Link></li>
          <li className={styles.topMenuItem}><Link to={URLS.CONTACTS}>{t('topMenuContacts')}</Link></li>
          <li className={styles.topMenuItem}><BookButtonWhite onClick={onBookClicked} sx={{ marginTop: 2 }}/></li>
        </ul>
      </div>
    </div>
  );
};

export default MenuFullscreen;