import React from "react";
import styles from "./CountrySelect.module.css";
import { Autocomplete, Avatar, Box, Button, ClickAwayListener, Fade, IconButton, ListItemIcon, MenuItem, MenuList, Paper, Popper, TextField, Typography, styled } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import Text from "../Text";
import ROLES from "../../../constants/roles";
import LANGS from "../../../constants/langs";
import changeLang from "../../../redux/actionCreators/changeLang";

const countries = [
  { code: 'AD', label: 'Andorra', phone: '376' },
  {
    code: 'AE',
    label: 'United Arab Emirates',
    phone: '971',
  },
  { code: 'AF', label: 'Afghanistan', phone: '93' },
];

const CountrySelect = ({ profile }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user);
  const lang = useSelector((state) => state?.lang);
  console.log("lang:", lang)
  
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const handleSelect = (lang) => {
    dispatch(changeLang(lang));
    setOpen(false);
  }

  return (
    <>
      {/* <IconButton variant="outlined"
        ref={anchorRef}
        aria-label="country" 
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle} 
      >
        <img
          id="country-select-flag"
          srcSet={`https://flagcdn.com/w40/${lang.abbr.toLowerCase()}.png 2x`}
          src={`https://flagcdn.com/w40/${lang.abbr.toLowerCase()}.png`}
          alt=""
        />
      </IconButton> */}
      <div className={styles.selectedText}>
        <Typography ref={anchorRef} onClick={handleToggle} variant="inherit">{lang.title}</Typography>
      </div>
      

      <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-end"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={() => handleSelect(LANGS.EN)}>
                      {/* <ListItemIcon>
                        <img
                          id="country-select-list-item"
                          srcSet={`https://flagcdn.com/w40/${LANGS.EN.abbr.toLowerCase()}.png 2x`}
                          src={`https://flagcdn.com/w20/${LANGS.EN.abbr.toLowerCase()}.png`}
                          alt=""
                        />
                      </ListItemIcon> */}
                      <Typography variant="inherit">{LANGS.EN.title}</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => handleSelect(LANGS.PL)} >
                      {/* <ListItemIcon>
                        <img
                          id="country-select-list-item"
                          srcSet={`https://flagcdn.com/w40/${LANGS.PL.abbr.toLowerCase()}.png 2x`}
                          src={`https://flagcdn.com/w20/${LANGS.PL.abbr.toLowerCase()}.png`}
                          alt=""
                        />
                      </ListItemIcon> */}
                      <Typography variant="inherit">{LANGS.PL.title}</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => handleSelect(LANGS.RU)}>
                      {/* <ListItemIcon>
                        <img
                          id="country-select-list-item"
                          srcSet={`https://flagcdn.com/w40/${LANGS.RU.abbr.toLowerCase()}.png 2x`}
                          src={`https://flagcdn.com/w20/${LANGS.RU.abbr.toLowerCase()}.png`}
                          alt=""
                        />
                      </ListItemIcon> */}
                      <Typography variant="inherit">{LANGS.RU.title}</Typography>
                    </MenuItem>
                    
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </Popper>
    </>
  );
};

export default CountrySelect;