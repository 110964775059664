import { Button, Card, CardActions, CardContent, Stack, styled, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { blue, red } from '@mui/material/colors';
import { useQueryClient } from "react-query";
import styles from "./LoginPage.module.css"
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom/cjs/react-router-dom";
import URLS from "../../constants/urls";
import GoogleIcon from "../../svg/ic_google.svg"
import { useHistory } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { loginUser } from "../../utils/loginUtils";
import { useDispatch } from "react-redux";
import firebaseHelper from "./../../components/firebase"
import RegisterConsent from "./RegisterConsent";
import PageLayout from "../../components/layouts/PageLayout";

const Text = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.onBackground,
  padding: theme.spacing(1)
}));

const GoogleButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(blue[500]),
  backgroundColor: blue[500],
  '&:hover': {
    backgroundColor: blue[700],
  },
}));

const LoginPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const queryClient = useQueryClient();

  const redirectCallback = () => {
    if (history.location.state && history.location.state.from) {
      history.replace(history.location.state.from)
    } else {
      history.replace(URLS.ROOT_URL)
    }
  }

  const handleSignIn = async () => {
    const auth = getAuth();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password)
      const user = userCredential.user;
      console.log("logged user:", user);
      
      const token = await user.getIdToken()
      await loginUser(dispatch, token);
      redirectCallback();
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      
      console.log("errorCode:", errorCode,", errorMessage:", errorMessage);
      if (errorCode === 'auth/wrong-password') {
        setError("wrong password")
      }
      if (errorCode === 'auth/invalid-email') {
        setError("invalid email")
      }
      setTimeout(() => {
        setError(null)
      }, 3000)
    }
  }

  const handleGoogleLogin = async () => {
    await firebaseHelper.googleLogin(history, dispatch, redirectCallback, queryClient);
  };

  return (
    <PageLayout fullscreen={true} >
      <div className={styles.loginPage}>
        <Card sx={{ minWidth: 275, padding: 2 }}>
          <CardContent>
            <Stack
              component="form"
              spacing={2}
              noValidate
              autoComplete="off"
            >
              <Typography align='center'>
                <Text to={URLS.RESTORE_PASSWORD}>{ t('authLogin') }</Text>
              </Typography>
              <TextField type="email" label={ t('authFormEmail') } value={email} onChange={event => setEmail(event.target.value)}/>
              <TextField type="password" label={ t('authFormPassword') } value={password} onChange={event => setPassword(event.target.value)} 
                onKeyDown={(e) => {
                  if (e.key === 'Enter') handleSignIn();
                }}/>
              {
                error && <Typography sx={{ color: red[500] }}>{ error }</Typography>
              }
              <Typography align='center'>
                <Link to={URLS.RESTORE_PASSWORD}>{ t('authRestorePassword') }</Link>
              </Typography>
              <Button size="large" variant="outlined" onClick={handleSignIn}>{ t('authSignIn') }</Button>
              <Button size="large" variant="outlined" onClick={() => history.replace(URLS.REGISTRATION)}>{ t('authSignUp') }</Button>
              <Typography align='center'>or</Typography>
              <GoogleButton size="large" startIcon={<img src={GoogleIcon} width={24} variant="contained"/>} onClick={handleGoogleLogin}>{ t('authWithGoogle') }</GoogleButton>
              <RegisterConsent />
            </Stack>
          </CardContent>
        </Card>
      </div>
    </PageLayout>
  );
};

export default LoginPage;
