import { CircularProgress, Link, styled, Typography } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import MasterListItem from "../../components/widgets/MasterListItem";
import ServerError from "../../components/widgets/ServerError/ServerError";
import QUERY_KEYS from "../../net/query-keys";
import REQUESTS from "../../net/requests";
import styles from "./CategoriesPage.module.css"
import CategoryItem from "../../components/widgets/CategoryItem";
import { useTranslation } from "react-i18next";
import PageLayout from "../../components/layouts/PageLayout";

const Text = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.onBackground,
  padding: theme.spacing(1)
}));


const CategoriesPage = () => {
  const categoriesRequest = useQuery(QUERY_KEYS.CATEGORIES, REQUESTS.getCategories)
  const { t } = useTranslation();

  if (categoriesRequest.isLoading) {
    return <CircularProgress />
  }
  if (categoriesRequest.isError) {
    return <ServerError/>
  }

  const categories = categoriesRequest.data.sort((a, b) => a.id - b.id)

  return (
    <PageLayout>
      <Typography className={styles.title} variant="h4" align="center" >{ t('topMenuServices') }</Typography>

      <div className={styles.list}>
        { categories.map(category => <CategoryItem key={category.id} category={category} />) }
      </div>
    </PageLayout>
  );
};

export default CategoriesPage;
