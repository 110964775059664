import styles from './RegistrationCard.module.css';
import 'moment/locale/ru';
import 'moment/locale/pl';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import URLS from '../../../constants/urls';
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { useDispatch } from "react-redux";
import Link from '@mui/material/Link';
import { Button, Card, CardActions, CardContent, Stack, styled, TextField, Typography } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { blue } from '@mui/material/colors';
import BookSteps from '../../../constants/bookSteps';
import { loginUser } from '../../../utils/loginUtils';
import firebaseHelper from '../../firebase';
import RegisterConsent from '../../../pages/LoginPage/RegisterConsent';
import GoogleIcon from "../../../svg/ic_google.svg"
import { useQueryClient } from 'react-query';

const GoogleButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(blue[500]),
  backgroundColor: blue[500],
  '&:hover': {
    backgroundColor: blue[700],
  },
}));

const RegistrationCard = ({ successCallback, backCallback, restoreCallback }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState()
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [bookState, setBookState] = useState({ step: BookSteps.SELECT_SERVICE })

  useEffect(() => {
    const params = new URL(document.location).searchParams;
    const code = params.get("code");
    if (code) {
      sessionStorage.setItem("inviteCode", code);
    }
  }, [])

  const redirectCallback = () => {
    if (successCallback) {
      successCallback();
    } else {
      history.replace(URLS.ROOT_URL)
    }
  }

  const handleRegister = () => {
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed up 
        const user = userCredential.user;
        console.log("new user:", user);
        
        user.getIdToken().then(token => {
          loginUser(dispatch, token).then(() => {
            redirectCallback();
          })
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("errorCode:", errorCode,"errorMessage:", errorMessage);
        
        setError(error.code)
        setTimeout(() => {
          setError(null);
        }, 3000);
      });
  }

  const handleGoogleLogin = async () => {
    await firebaseHelper.googleLogin(history, dispatch, redirectCallback, queryClient);
  };

  const handleBack = async () => {
    if (backCallback) {
      backCallback();
    } else {
      history.replace(URLS.LOGIN)
    }
  };

  const handleRestore = async () => {
    if (restoreCallback) {
      restoreCallback();
    } else {
      history.replace(URLS.RESTORE_PASSWORD);
    }
  };

  return (
    <div className={styles.box}>
      <Card sx={{ minWidth: 275, padding: 2 }}>
        <CardContent>
          <Stack
            component="form"
            spacing={2}
            noValidate
            autoComplete="off"
          >
            <Typography align='center'>{ t('authRegistration') }</Typography>
            <TextField type="email" label={ t('authFormEmail') } value={email} onChange={event => setEmail(event.target.value)}/>
            <TextField type="password" label={ t('authFormPassword') } value={password} onChange={event => setPassword(event.target.value)}/>
            <TextField type="password" label={ t('authFormRepeatPassword') }/>
            <Typography align='center'>
              <Link component="button" onClick={handleRestore}>{ t('authRestorePassword') }</Link>
            </Typography>
            <Button size="large" variant="outlined" onClick={handleRegister}>{ t('authRegister') }</Button>
            {
              error && <Typography align='center'>{ error }</Typography>
            }
            <Button size="large" variant="outlined" onClick={handleBack}>{ t('authBackToLogin') }</Button>
            <Typography align='center'>{ t('or') }</Typography>
            <GoogleButton size="large" startIcon={<img src={GoogleIcon} width={24} variant="contained"/>} onClick={handleGoogleLogin}>{ t('authWithGoogle') }</GoogleButton>
            <RegisterConsent />
          </Stack>
        </CardContent>
      </Card>
    </div>
  );
};

export default RegistrationCard;