const SourcePlatform = {
  UNKNOWN: 0,
  INST: 1,
  GOOGLE: 2,
  FRIENDS: 3,
  FB: 4,
  TIKTOK: 5,
  OTHER: 6,
};

export const sourcePlatformName = (sourcePlatform) => {
  switch (sourcePlatform) {
    case SourcePlatform.UNKNOWN: 
      return "SourcePlatformUnknown"
    case SourcePlatform.INST: 
      return "SourcePlatformInst"
    case SourcePlatform.GOOGLE: 
      return "SourcePlatformGoogle"
    case SourcePlatform.OTHER: 
      return "SourcePlatformOther"
  }
}

export default SourcePlatform;