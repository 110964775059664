import React, { useRef, useState } from "react";
import styles from "./MasterSchedule.module.css"
import { CircularProgress, Paper, styled } from "@mui/material";
import ExpandMoreItem from "../../../components/widgets/ControlPanelItems/ExpandMoreItem2";
import { useQuery } from "react-query";
import QUERY_KEYS from "../../../net/query-keys";
import REQUESTS from "../../../net/requests";
import MasterSelect from "./MasterSelect";
import ServerError from "../../../components/widgets/ServerError/ServerError";
import SelectDay from "./SelectDay";
import dayjs from "dayjs";
import DaySchedule from "./DaySchedule";
import { useTranslation } from "react-i18next";

const ProfilePaper = styled(Paper)(({ theme }) => ({
  maxWidth: 1100,
  width: "100%",
  paddingLeft: 16,
  paddingRight: 16,
  padding: theme.spacing(2),
  marginTop: 16,
  ...theme.typography.body2,
  textAlign: 'center',
}));

const ONE_DAY = 24 * 60 * 60 * 1000;

const rangeToDaysFormatted = (range) => {
  const set = new Set();
  for (let start = range.startTime; start < range.endTime; start += ONE_DAY) {
    set.add(dayjs(start).format("YYYY-MM-DD"))
  }
  set.add(dayjs(range.endTime).format("YYYY-MM-DD"))
  return set;
}

// const rangeToDaysFormatted = (range) => {
//   return Array.from(rangeToDays(range)).map(v => v.format("YYYY-MM-DD"))
// }



const MasterSchedule = ({ profile }) => {
  const { t } = useTranslation();
  const [master, setMaster] = useState(null)
  const [day, setDay] = useState(dayjs().startOf("day"))
  
  const schedulesRequest = useQuery(QUERY_KEYS.ALL_MASTERS_SCHEDULES, REQUESTS.getAllSchedules)

  if (schedulesRequest.isLoading) {
    return <CircularProgress />
  }
  if (schedulesRequest.isError) {
    return <ServerError/>
  }
  const data = schedulesRequest.data
  data.masters = data.masters.filter(master => !master.wasDeleted)
  if (!master && data.masters.length > 0) {
    setMaster(data.masters[0])
  }

  const handleChangeDay = (selectedDay) => {
    setDay(selectedDay)
  }

  const findSchedulesIds = (schedules, day, master) => {
    const masterSchedules = schedules.filter(item => item.masterId === master.id)
    const result = masterSchedules.filter(item => Array.from(rangeToDaysFormatted(item)).find(item => item === day.format("YYYY-MM-DD"))).map(item => item.id)
    console.log("result", result);
    return result;
  }

  const buildDays = (ranges) => {
    const set = new Set();
    ranges.forEach(range => {
      rangeToDaysFormatted(range).forEach(day => set.add(day))
    });
    console.log("set", set);
    return Array.from(set);
  }

  return (
    <div className={styles.box}>
      <ProfilePaper variant="outlined" sx={{ borderRadius: 2 }}>
        <ExpandMoreItem text={ t('profileMasterSchedule') } sx={{ padding: 0}}>
          <div>
            <MasterSelect masters={data.masters} value={master} onChange={(master) => setMaster(master)}/>
            {
              master && <SelectDay value={day} onChange={handleChangeDay} highlightedDays={buildDays(data.schedule.filter(item => item.masterId === master.id))} />
            }
            {
              day && master && <DaySchedule scheduleIds={findSchedulesIds(data.schedule, day, master)}/>
            }
          </div>
        </ExpandMoreItem>
      </ProfilePaper>
    </div>
  );
}

export default MasterSchedule;