import * as React from 'react';
import styles from "./ScheduleRecords.module.css";
import { CircularProgress, Divider, Typography, styled } from "@mui/material";
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import QUERY_KEYS from '../../../net/query-keys';
import REQUESTS from '../../../net/requests';
import { useQuery } from 'react-query';
import ServerError from '../../widgets/ServerError/ServerError';
import ScheduleRecordsItem from '../ScheduleRecordsItem';

dayjs.extend(isToday);

const ScheduleRecords = ({ scheduleId }) => {

  const recordsRequest = useQuery(QUERY_KEYS.recordsOfSchedule(scheduleId), REQUESTS.getRecordsOfSchedule(scheduleId), { refetchOnMount: true });

  if (recordsRequest.isLoading) {
    return <CircularProgress />
  }
  if (recordsRequest.isError) {
    return <ServerError/>
  }
  const records = recordsRequest.data.records.sort((a, b) => a.startTime - b.startTime);
  const clients = recordsRequest.data.clients
  const services = recordsRequest.data.services
  const recordNotes = recordsRequest.data.recordNotes

  const separator = () => <Divider orientation="horizontal" variant="middle" flexItem />

  const recordDetails = (id) => {
    const record = records.find((v, i, a) => v.id === id)
    const client = clients.find((v, i, a) => v.id === record.clientId)
    const service = services.find((v, i, a) => v.id === record.serviceId)
    return <ScheduleRecordsItem record={record} client={client} service={service} scheduleId={scheduleId} recordNotes={recordNotes.filter(note => note.recordId === id)}/>
  }

  return (
    <div className={styles.scheduleRecords}>
      {
        records.length > 0 ? 
          records.map(record => <React.Fragment key={record.id}>
            { record !== records[0] && separator() }
            { recordDetails(record.id) }
          </React.Fragment>) 
          : <Typography align='center'>No records here</Typography>
      }
    </div>
  );
};

export default ScheduleRecords;
