import * as React from 'react';
import styles from "./AddCategoryDialog.module.css";
import { useSelector, useDispatch } from "react-redux";
import { IconButton, Dialog, DialogTitle, DialogContent, CircularProgress, Button, Divider, styled, FormControl, InputLabel, Select, MenuItem, Typography } from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import dayjs, { Dayjs } from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import QUERY_KEYS from '../../../net/query-keys';
import REQUESTS from '../../../net/requests';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { logAndReset } from '../../../utils/requestUtils';
import { useState } from 'react';
import ServerError from '../../widgets/ServerError/ServerError';

dayjs.extend(isToday);

const BlurryDialog = styled(Dialog)(({ theme }) => ({
  backdropFilter: "blur(5px)"
}));

const AddCategoryDialog = ({ onClose, open, service }) => {
  const user = useSelector((state) => state?.user);
  const lang = useSelector((state) => state?.lang);
  const [category, setCategory] = useState(null)
  const queryClient = useQueryClient();


  const addCategoryMutation = useMutation(REQUESTS.addToCategory(category ? category.id : 0));
  const deleteCategoryMutation = useMutation(REQUESTS.deleteFromCategory(service.id));

  const serviceCategoriesRequest = useQuery(QUERY_KEYS.getServiceCategories(service.id), REQUESTS.getServiceCategories(service.id), { refetchOnMount: true })
  const categoriesRequest = useQuery(QUERY_KEYS.CATEGORIES, REQUESTS.getCategories)

  if (serviceCategoriesRequest.isLoading || categoriesRequest.isLoading) {
    return <CircularProgress />
  }
  if (serviceCategoriesRequest.isError || categoriesRequest.isError) {
    return <ServerError/>
  }

  const serviceCategories = serviceCategoriesRequest.data;
  const allCategories = categoriesRequest.data;

  const handleAddSchedule = () => {
    if (!category) {
      return;
    }
    const data = {
      serviceId: service.id
    }
    addCategoryMutation.mutate(data, {
      onSuccess: (data, error, variables, context) => {
        console.log(`new schedule was added successfully!`)
        queryClient.invalidateQueries(QUERY_KEYS.SERVICES);
        queryClient.invalidateQueries(QUERY_KEYS.getServiceCategories(service.id));
        
        setTimeout(() => {
          addCategoryMutation.reset()
        }, 3000)
      },
      onError: () => {
        logAndReset(addCategoryMutation)
      }
    });
  }

  const handleClose = (event) => {
    onClose();
  };

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  const handleDeleteCategory = (categoryId) => {
    console.log('delete category:', service.id);
    deleteCategoryMutation.mutate({ categoryId }, {
      onSuccess: (data, error, variables, context) => {
        console.log('category is deleted!')
        queryClient.invalidateQueries(QUERY_KEYS.SERVICES);
        queryClient.invalidateQueries(QUERY_KEYS.getServiceCategories(service.id));
        setTimeout(() => {
          deleteCategoryMutation.reset()
        }, 3000)
      },
      onError: () => {
        logAndReset(deleteCategoryMutation)
      }
    });
  };

  return (
    <>
      <BlurryDialog onClose={handleClose} open={open} fullWidth={true} maxWidth="sm">
        <DialogTitle sx={{ m: 0, paddingRight:7 }} id="customized-dialog-title">
          Categories
        </DialogTitle>
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 12,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ paddingTop: 0 }}>
          {
            serviceCategories.map(category => <div key={category.id} className={styles.categoryItem}>
                <Typography>"{ category.translations.find(v => v.lang === lang.title.toLowerCase())?.text ?? category.id }"</Typography>
                <Button variant="outlined" onClick={() => handleDeleteCategory(category.id)}>Delete</Button>
              </div>
            )
          }

          <Divider sx={{ marginTop: 2 }} />

          <Typography sx={{ marginTop: 2 }}>Add service to category:</Typography>
          <div className={styles.content}>
            <FormControl fullWidth sx={{ marginTop: 2 }}>
              <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={category}
                label="Category"
                onChange={handleChange}
              >
                {
                  allCategories.map(category => <MenuItem value={category}>{ category.translations.find(translation => translation.lang === lang.title.toLowerCase())?.text }</MenuItem>)
                }
              </Select>
            </FormControl>

            {addCategoryMutation.isLoading ? ('Adding service to category...') : (
              <>
              {addCategoryMutation.isError ? (
                <div>An error occurred: {addCategoryMutation.error.message}</div>
              ) : null}

              {addCategoryMutation.isSuccess ? <div>Service was added!</div> : null}
              </>
            )}
            <Button variant='outlined' onClick={handleAddSchedule}>Add</Button>
          </div>
        </DialogContent>
      </BlurryDialog>
    </>
  );
};

export default AddCategoryDialog;
