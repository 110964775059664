
import dayjs from 'dayjs';
import styles from './TimeWidget.module.css'
import { Typography } from '@mui/material';

const TimeWidget = ({ record }) => {
  
  
  return <div className={styles.timeBox}>
    <Typography>{dayjs(record.startTime).format("DD.MM.YYYY")}</Typography>
    <Typography>{dayjs(record.startTime).format("HH:mm")} - {dayjs(record.endTime).format("HH:mm")}</Typography>
  </div>;
}

export default TimeWidget;