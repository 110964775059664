import styles from './RestoreCard.module.css';
import 'moment/locale/ru';
import 'moment/locale/pl';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import URLS from '../../../constants/urls';
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useDispatch } from "react-redux";
import Link from '@mui/material/Link';
import { Button, Card, CardActions, CardContent, Stack, styled, TextField, Typography } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { blue } from '@mui/material/colors';
import BookSteps from '../../../constants/bookSteps';
import { loginUser } from '../../../utils/loginUtils';
import firebaseHelper from '../../firebase';
import RegisterConsent from '../../../pages/LoginPage/RegisterConsent';
import GoogleIcon from "../../../svg/ic_google.svg"
import { useQueryClient } from 'react-query';

const GoogleButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(blue[500]),
  backgroundColor: blue[500],
  '&:hover': {
    backgroundColor: blue[700],
  },
}));

const RestoreCard = ({ successCallback, backCallback, restoreCallback }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [email, setEmail] = useState("")
  const [emailSent, setEmailSent] = useState(false)
  const [error, setError] = useState()
  const dispatch = useDispatch();

  const [bookState, setBookState] = useState({ step: BookSteps.SELECT_SERVICE })
  const emailRef = useRef();
  const passwordRef = useRef();

  const handleRestore = async () => {
    const auth = getAuth();
    try {
      await sendPasswordResetEmail(auth, email);
      setEmailSent(true);
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log("errorCode:", errorCode,"errorMessage:", errorMessage);
      
      setError(error.code)
      setTimeout(() => {
        setError(null);
      }, 3000);
    }
  }

  const handleBack = async () => {
    if (backCallback) {
      backCallback();
    } else {
      history.replace(URLS.LOGIN)
    }
  };

  return (
    <div className={styles.box}>
      <Card sx={{ minWidth: 275, padding: 2 }}>
        <CardContent>
          <Stack
            component="form"
            spacing={2}
            noValidate
            autoComplete="off"
          >
            <Typography align='center'>{ t('authRestorePassword') }</Typography>
            <TextField type="email" label={ t('authFormEmail') } value={email} onChange={event => setEmail(event.target.value)}/>
            {
              emailSent ? 
                <Typography>{ t('authRestoreEmailSent') }</Typography> :
                <Button size="large" variant="outlined" onClick={handleRestore}>{ t('authRestore') }</Button>
            }
            {
              error && <Typography align='center'>{ error }</Typography>
            }
            {
              backCallback ? null : <Button size="large" variant="outlined" onClick={handleBack}>{ t('authBackToLogin') }</Button>
            }
            
          </Stack>
        </CardContent>
      </Card>
    </div>
  );
};

export default RestoreCard;