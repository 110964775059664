import { Checkbox, CircularProgress, List, Paper, styled, Typography } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import styles from "./FeedbacksList.module.css"
import QUERY_KEYS from "../../../../net/query-keys";
import REQUESTS from "../../../../net/requests";
import ServerError from "../../../../components/widgets/ServerError/ServerError";
import FeedbackItem from "./FeedbackItem";
import { useTranslation } from "react-i18next";


const FeedbacksList = ({ profile }) => {  
  const [showAll, setShowAll] = useState(false)
  const { t } = useTranslation();

  const key = showAll ? QUERY_KEYS.getFeedbacks() : QUERY_KEYS.getNewFeedbacks()
  const request = showAll ? REQUESTS.getFeedbacks() : REQUESTS.getNewFeedbacks()
  const feedbacksRequest = useQuery(key, request, { refetchOnMount: true })
  
  if (feedbacksRequest.isLoading) {
    return <CircularProgress />
  }
  if (feedbacksRequest.isError) {
    return <ServerError/>
  }

  const feedbacks = feedbacksRequest.data.sort((a, b) => a.createdAt - b.createdAt)

  return (
    <div className={styles.box}>
      <div className={styles.controls}>
        <Typography>{ t('showAllFeedbacks') }:</Typography>
        <Checkbox checked={showAll} onChange={e => setShowAll(e.target.checked)}/>
      </div>
      {
        feedbacks && feedbacks.length === 0 ? <div className={styles.empty}><Typography>{ t('noNewFeedbacks') }</Typography></div> : <Typography>{ showAll ? t('allFeedbacks') : t('newFeedbacks') }</Typography>
      }

      {
        feedbacks && feedbacks.length > 0 &&
        <List dense={true}>
          { feedbacks.map(feedback => <FeedbackItem key={feedback.id} feedback={feedback} showAnswered={showAll}/>) }
        </List>
      }
    </div>
  );
};

export default FeedbacksList;
