import React, { useEffect, useRef, useState } from "react";
import styles from "./CategoriesListItem.module.css";
import ExpandMoreItem from "../ExpandMoreItem";
import { Button, Card, Checkbox, CircularProgress, FormControlLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import Text from "../../Text";
import ConsentTypes from "../../../../constants/consentTypes";
import { useMutation, useQuery, useQueryClient } from "react-query";
import REQUESTS from "../../../../net/requests";
import { logAndReset } from "../../../../utils/requestUtils";
import QUERY_KEYS from "../../../../net/query-keys";
import ServerError from "../../ServerError/ServerError";
import EditConsentItemText from "../EditConsentItemText";
import LANGS from "../../../../constants/langs";

const CategoriesListItem = ({ category }) => {
  console.log('CategoriesListItem', category);
  const translationEn = category.translations.find(v => v.lang === LANGS.EN.title.toLowerCase())
  const translationPl = category.translations.find(v => v.lang === LANGS.PL.title.toLowerCase())
  const translationRu = category.translations.find(v => v.lang === LANGS.RU.title.toLowerCase())
  const [enText, setEnText] = useState(translationEn?.text ?? '')
  const [plText, setPlText] = useState(translationPl?.text ?? '')
  const [ruText, setRuText] = useState(translationRu?.text ?? '')
  const [isPrivate, setPrivate] = useState(category.private ?? false)
  const [published, setPublished] = useState(category.published ?? false)
  
  console.log('CategoriesListItem en', enText, translationEn.text);

  useEffect(() => {
    console.log('CategoriesListItem useEffect', enText, translationEn.text);
    if (enText !== translationEn.text) {
      setEnText(translationEn.text)
    }
  }, [category])

  const updateCategoryTextMutation = useMutation(REQUESTS.putCategoryText(category.id))
  const queryClient = useQueryClient();
  
  const handleSendCategoryText = () => {
    const data = {
      nameEn: enText,
      namePl: plText,
      nameRu: ruText,
      published,
      private: isPrivate
    }
    console.log('create category:', data);
    updateCategoryTextMutation.mutate(data, {
      onSuccess: (data, error, variables, context) => {
        console.log('category is updated!')
        queryClient.invalidateQueries(QUERY_KEYS.CATEGORIES);
        setTimeout(() => {
          updateCategoryTextMutation.reset()
        }, 3000)
      },
      onError: () => {
        logAndReset(updateCategoryTextMutation)
      }
    });
  }

  return (
    <>
      <div className={styles.items}>
        <Typography>id: { category.id }</Typography>
        
        <TextField label="en" value={enText} onChange={event => setEnText(event.target.value)} />
        <TextField label="pl" value={plText} onChange={event => setPlText(event.target.value)} />
        <TextField label="ru" value={ruText} onChange={event => setRuText(event.target.value)} />

        <div className={styles.checks}>
          <FormControlLabel control={<Checkbox checked={isPrivate} onChange={event => setPrivate(event.target.checked)} />} label="Private" />
          <FormControlLabel control={<Checkbox checked={published} onChange={event => setPublished(event.target.checked)} />} label="Published" />
        </div>
        {
          updateCategoryTextMutation.isLoading ? ('Updating category...') : (
            <>
              { updateCategoryTextMutation.isError ? <div>An error occurred: {updateCategoryTextMutation.error.message}</div> : null }
              { updateCategoryTextMutation.isSuccess ? <div>Category was updated!</div> : <Button variant="outlined" onClick={handleSendCategoryText}>Save</Button> }
            </>
          )
        }
      </div>
    </>
  );
}

export default CategoriesListItem;