import React from "react";
import { useSelector } from "react-redux";
import Header from "../../widgets/Header/index.js";
import styles from  "./AppLayout.module.css";

const AppLayout = ({ children }) => {

  return (
    <>
      <div className={styles.layoutVertical} >
        <Header />
        <div id="app-layout-page" className={styles.page}>
          { children }
        </div>
      </div>
    </>
  );
};

export default AppLayout;
