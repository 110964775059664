import * as React from 'react';
import styles from "./PrivacyPolicyLink.module.css";
import { Checkbox, CircularProgress, Typography } from "@mui/material";
import { useQuery } from 'react-query';
import QUERY_KEYS from '../../../../net/query-keys';
import ConsentTypes from '../../../../constants/consentTypes';
import REQUESTS from '../../../../net/requests';
import ServerError from '../../ServerError/ServerError';
import { Link } from 'react-router-dom';
import TranslationTypes from '../../../../constants/translationTypes';
import { useSelector } from 'react-redux';
import URLS from '../../../../constants/urls';

const PrivacyPolicyLink = ({ color, fontSize }) => {
  const lang = useSelector(state => state.lang).title.toLowerCase();
  const consentsRequest = useQuery(QUERY_KEYS.PRIVACY_POLICY_LINK, REQUESTS.getPrivacyPolicyLink(ConsentTypes.REGISTRATION))
  
  if (consentsRequest.isLoading) {
    return <CircularProgress />
  }
  if (consentsRequest.isError) {
    return <ServerError/>
  }

  console.log(consentsRequest);
  const translation = consentsRequest.data.translations.find(v => v.type === TranslationTypes.CONSENT_PAGE_TITLE && v.lang === lang)?.text ?? "";

  return (
    <Link to={URLS.consentPage(consentsRequest.data.consents[0].id)} className={ styles.box }>
      <Typography sx={{ color, fontSize, textDecoration: 'underline' }}>{ translation }</Typography>
    </Link>
  );
};

export default PrivacyPolicyLink;
