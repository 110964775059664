import React, { useRef, useState } from "react";
import { Card, Typography } from "@mui/material";

const MasterCard = ({ title, first, firstValue, second, secondValue }) => {

  return (
    <>
      <Card sx={{ padding: 2, minWidth: 200 }}>
        <Typography sx={{ fontSize: 18 }}>{ title }</Typography>
        <Typography sx={{ fontSize: 16 }}>{ `${first}: ${firstValue}` }</Typography>
        <Typography sx={{ fontSize: 16 }}>{ `${second}: ${secondValue}` }</Typography>
      </Card>
    </>
  );
}

export default MasterCard;