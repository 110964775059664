import * as React from 'react';
import "./AddScheduleDialog.css";
import { useSelector, useDispatch } from "react-redux";
import { IconButton, Dialog, DialogTitle, DialogContent, CircularProgress, Button, Divider, styled, FormControl, InputLabel, Select, MenuItem } from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import DateItem from '../DateItem';
import dayjs, { Dayjs } from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import QUERY_KEYS from '../../../net/query-keys';
import REQUESTS from '../../../net/requests';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { logAndReset } from '../../../utils/requestUtils';
import { useState } from 'react';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ServerError from '../../widgets/ServerError/ServerError';
import 'dayjs/locale/ru';
import { masterDisplayName } from '../../../utils/formatUtils';
import { useTranslation } from 'react-i18next';

dayjs.extend(isToday);

const SIX_HOURS = 6 * 60 * 60 * 1000;
const DAY = 24 * 60 * 60 * 1000;
const TIME_STEPS = 30

const dates = [Date.now(), Date.now() + 1 * DAY, Date.now() + 2 * DAY, Date.now() + 3 * DAY, Date.now() + 4 * DAY, Date.now() + 5 * DAY, Date.now() + 6 * DAY]

const BlurryDialog = styled(Dialog)(({ theme }) => ({
  backdropFilter: "blur(5px)"
}));

const buildTimes = (selectedDayData) => {
  if (!selectedDayData) {
    return [];
  }
  const day = selectedDayData.day;
  const items = selectedDayData.times;
  const times = [];
  items.forEach(item => {
    if (item >= day && item < day + DAY) {
      times.push(item);
    }
  });
  return times;
}


const AddScheduleDialog = ({ onClose, open }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state?.user);
  const lang = useSelector(state => state.lang).title.toLowerCase();
  const [startDate, setStartDate] = useState(dayjs().startOf("day"))
  const [endDate, setEndDate] = useState(dayjs().startOf("day"))
  const [master, setMaster] = useState(null)
  const queryClient = useQueryClient();
  const addScheduleMutation = useMutation(REQUESTS.addSchedule)

  const mastersRequest = useQuery(QUERY_KEYS.MASTERS, REQUESTS.getMasters, { refetchOnMount: true });

  if (mastersRequest.isLoading) {
    return <CircularProgress />
  }
  if (mastersRequest.isError) {
    return <ServerError/>
  }

  const masters = mastersRequest.data.filter(master => !master.wasDeleted);

  const handleAddSchedule = () => {
    if (!master) {
      return;
    }
    const data = {
      "masterId": master.id,
      "startTime": startDate ? startDate.valueOf() : 0,
      "endTime": endDate ? endDate.valueOf() : 0
    }
    addScheduleMutation.mutate(data, {
      onSuccess: (data, error, variables, context) => {
        console.log(`new schedule was added successfully!`)
        queryClient.invalidateQueries(QUERY_KEYS.getMasterSchedules(master.id));
        queryClient.invalidateQueries(QUERY_KEYS.ALL_MASTERS_SCHEDULES);
        
        setTimeout(() => {
          addScheduleMutation.reset()
        }, 3000)
      },
      onError: () => {
        //logAndReset(addScheduleMutation)
      }
    });
  }



  const handleClose = (event) => {
    onClose();
  };

  const buildMasterName = (master) => masterDisplayName(master, lang);

  return (
    <>
      <BlurryDialog onClose={handleClose} open={open} fullWidth={true} maxWidth="sm">
        <DialogTitle sx={{ m: 0, paddingRight:7 }} id="customized-dialog-title">
          { t('addWorkingTime') }
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 12,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent >
          <div id='add-schedule-dialog'>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">{ t('cosmetologist') }</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={master}
                label={ t('cosmetologist') }
                onChange={event => setMaster(event.target.value)}
              >
                {
                  masters.map(master => <MenuItem value={master}>{ `${master.id}: ${buildMasterName(master)}` }</MenuItem>)
                }
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
              <DateTimePicker label={ t('startLabel') } disablePast={true} ampm={false} timeSteps={{ minutes: TIME_STEPS }} value={startDate} onChange={(time) => {
                setStartDate(time)
                setEndDate(time.add(SIX_HOURS, 'ms'))
                console.log("setStartDate: " + time)
              }}/>
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
              <DateTimePicker label={ t('endLabel') } disablePast={true} ampm={false} timeSteps={{ minutes: TIME_STEPS }} value={endDate} onChange={(time) => {
                setEndDate(time)
                console.log("setEndDate: " + time)
              }}/>
            </LocalizationProvider>

            {addScheduleMutation.isLoading ? ('Adding schedule...') : (
              <>
              {addScheduleMutation.isError ? (
                <div>Error: {addScheduleMutation.error.response.data.error.message ? addScheduleMutation.error.response.data.error.message : addScheduleMutation.error.message}</div>
              ) : null}

              {addScheduleMutation.isSuccess ? <div>{ t('timeWasAdded') }</div> : null}
              </>
            )}
            <Button variant='outlined' onClick={handleAddSchedule}>{ t('add') }</Button>
          </div>
        </DialogContent>
      </BlurryDialog>
    </>
  );
};

export default AddScheduleDialog;
