import { Card, CircularProgress, styled, TextField, Typography } from "@mui/material";
import React from "react";
import styles from "./RecordsPage.module.css"
import PageLayout from "../../components/layouts/PageLayout";


const RecordsPage = () => {
  const records = 0;

  return (
    <PageLayout>
    </PageLayout>
  );
};

export default RecordsPage;
