import React, { useState } from "react";
import styles from "./CreateFakeUserDialog.module.css";
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, List, ListItem, ListItemButton, ListItemText, MenuItem, Select, TextField, Typography, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useMutation, useQuery } from "react-query";
import QUERY_KEYS from "../../../net/query-keys";
import REQUESTS from "../../../net/requests";
import ServerError from "../../widgets/ServerError/ServerError";
import { useSelector } from "react-redux";
import { logAndReset } from "../../../utils/requestUtils";
import { validateEmail, validatePhone } from "../../../utils/validateUtils";
import SourcePlatform from "../../../constants/sourcePlatform";

const BlurryDialog = styled(Dialog)(({ theme }) => ({
  backdropFilter: "blur(5px)"
}));

const CreateFakeUserDialog = ({ open, onClose, onChange }) => {

  const user = useSelector(state => state.user);
  const [name, setName] = useState("")
  const [phone, setPhone] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [sourcePlatform, setSourcePlatform] = React.useState(SourcePlatform.UNKNOWN)
  const [wrongPhone, setWrongPhone] = React.useState(false)
  const [wrongEmail, setWrongEmail] = React.useState(false)
  const createMutation = useMutation(REQUESTS.createFakeUser)

  const handleClose = () => {
    onClose();
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setWrongEmail(false);
  }

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
    setWrongPhone(false);
  }

  const handleSourceChange = (event) => {
    setSourcePlatform(event.target.value);
  }

  const handleCreate = () => {
    const data = {
      authorId: user.id,
      name,
      phone: phone === "" ? undefined : phone.replaceAll(" ", ""),
      email: email === "" ? undefined : email,
      sourcePlatform
    }

    if (data.phone && !validatePhone(data.phone)) {
      setWrongPhone(true);
      return;
    }

    if (data.email && !validateEmail(data.email)) {
      setWrongEmail(true);
      return;
    }

    createMutation.mutate(data, {
      onSuccess: (data, error, variables, context) => {
        console.log('created!');
        onChange(data);
        onClose();
      },
      onError: () => {
        logAndReset(createMutation)
      }
    });
  }

  return (
    <>
      <BlurryDialog onClose={handleClose} open={open} fullWidth={true} maxWidth="sm">
        <DialogTitle sx={{ m: 0, paddingRight:7 }} id="customized-dialog-title">
          Create fake user
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 12,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent >
          <div className={styles.box}>
            <TextField sx={{margin: 1}} value={name} onChange={event => setName(event.target.value)} label="Name" />
            <TextField error={wrongPhone} sx={{margin: 1}} label="Phone" value={phone} onChange={handlePhoneChange} helperText={ wrongPhone ? "Incorrect phone" : "format: +48 XXX XXX XXX" }></TextField>
            <TextField error={wrongEmail} sx={{margin: 1}} label="Email" value={email} onChange={handleEmailChange} helperText={ wrongEmail ? "Incorrect email" : "" }></TextField>
            
            <FormControl sx={{m:1}}>
              <InputLabel id="source-label">Источник</InputLabel>
              <Select
                labelId="source-label"
                value={sourcePlatform}
                label="Источник"
                onChange={handleSourceChange}
              >
                <MenuItem value={SourcePlatform.UNKNOWN}>Нет</MenuItem>
                <MenuItem value={SourcePlatform.INST}>Instagram</MenuItem>
                <MenuItem value={SourcePlatform.GOOGLE}>Google</MenuItem>
                <MenuItem value={SourcePlatform.OTHER}>Другой</MenuItem>
              </Select>
            </FormControl>
            {
              createMutation.isLoading ? ('Loading...') : 
                createMutation.isError ? <div>An error occurred: {createMutation.error.message}</div> :
                  createMutation.isSuccess ? <div>User is created!</div> :
                    <Button variant="outlined" sx={{ marginTop: 2}} onClick={handleCreate}>Create</Button>
            }
          </div>
        </DialogContent>
      </BlurryDialog>
    </>
  );
};

export default CreateFakeUserDialog;