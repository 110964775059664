import React from "react";
import styles from "./Header.module.css";
import { Button, TextField } from '@mui/material';
import AccountInfo from "../AccountInfo";
import TopMenu from "../TopMenu/TopMenu";
import CountrySelect from "../CountrySelect";
import Logo from "../../../svg/facetime_logo.svg"
import { Link } from "react-router-dom";
import URLS from "../../../constants/urls";

const Header = ({ children }) => {
  return (
    <>
      <div className={styles.header}>
        <div className={styles.headerMenu}>
          <TopMenu/>
        </div>

        <div className={styles.headerLogo} >
          <Link to={URLS.ROOT_URL}><img src={Logo} className={styles.logoImage}/></Link>
        </div>
        
        <div className={styles.headerActions}>
          <CountrySelect />
          <AccountInfo />
        </div>
      </div>
    </>
  );
};

export default Header;