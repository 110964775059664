import { Button, CircularProgress, List, ListItem, Paper, styled, Typography } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ServerError from "../../components/widgets/ServerError/ServerError";
import ServiceListItem from "../../components/widgets/ServiceListItem";
import QUERY_KEYS from "../../net/query-keys";
import REQUESTS from "../../net/requests";
import { useParams, useHistory } from 'react-router';
import { useSelector } from "react-redux";
import QuestionTypes from "../../constants/questionTypes";
import SimpleTextView from "../../components/widgets/Questionnaire/SimpleTextView";
import styles from "./QuestionnairePage.module.css"
import TextWithInputView from "../../components/widgets/Questionnaire/TextWithInputView";
import TextWithYesNoView from "../../components/widgets/Questionnaire/TextWithYesNoView";
import { logAndReset } from "../../utils/requestUtils";
import PageLayout from "../../components/layouts/PageLayout";

const ProfilePaper = styled(Paper)(({ theme }) => ({
  maxWidth: 1100,
  width: "100%",
  paddingLeft: 16,
  paddingRight: 16,
  padding: theme.spacing(2),
  margin: 16,
  ...theme.typography.body2,
  textAlign: 'center',
}));

const QuestionnairePage = () => {
  const user = useSelector((state) => state?.user);
  const { id } = useParams();
  const answersRef = useRef({})
  const [highlighted, setHighlighted] = useState([])
  const history = useHistory();
  const queryClient = useQueryClient();

  const questionnaireRequest = useQuery(QUERY_KEYS.getQuestionnaire(id), REQUESTS.getQuestionnaire(id), { refetchOnMount: true })
  
  const sendAnswersMutation = useMutation(REQUESTS.postAnswers)

  if (!user) {
    return <div className={styles.box}>
      <div className={styles.content}>
        <ProfilePaper variant="outlined" sx={{ borderRadius: 2 }}>
          <Typography>Please, login to see your Questionnaire</Typography>
        </ProfilePaper>
      </div>
    </div>
  }

  if (questionnaireRequest.isLoading) {
    return <CircularProgress />
  }
  if (questionnaireRequest.isError) {
    return <ServerError/>
  }

  const questionnaireData = questionnaireRequest.data

  const answers = answersRef.current;
  const save = (id, value) => {
    //setAnswers(prev => ({ ...prev, [id]: value }))
    answersRef.current[id] = value
  }

  const findAnswer = (questionId) => {
    return questionnaireData.answers.find(v => v.questionId === questionId)
  }

  const hasPreviousAnswer = (questionId) => {
    const answer = findAnswer(questionId);
    return answer && answer?.payload?.value !== undefined
  }

  const hasCurrentAnswer = (question) => {
    return answers[question.id] !== undefined;
    // return (question.type === QuestionTypes.TEXT_WITH_YES_NO && answers[question.id] !== undefined) || 
    //   (question.type === QuestionTypes.TEXT_WITH_INPUT && answers[question.id] !== undefined)
  }

  const currentValueIsCorrect = (question) => {
    switch (question.type) {
      case QuestionTypes.SIMPLE_TEXT:
        return true;
      case QuestionTypes.TEXT_WITH_INPUT:
        return answers[question.id] !== undefined && answers[question.id].length > 0
      case QuestionTypes.TEXT_WITH_YES_NO:
        return answers[question.id] !== undefined && typeof answers[question.id] === 'boolean'
    }
  }

  const buildQuestionView = (question) => {
    const answer = findAnswer(question.id);

    switch (question.type) {
      case QuestionTypes.SIMPLE_TEXT:
        return <ListItem key={question.id}><SimpleTextView question={question} answer={answer}/></ListItem>
      case QuestionTypes.TEXT_WITH_INPUT: 
        return <ListItem key={question.id}><TextWithInputView question={question} answer={answer} onChange={value => save(question.id, value)} enabled={Number(id) === user.id} /></ListItem>
      case QuestionTypes.TEXT_WITH_YES_NO: 
        return <ListItem key={question.id}><TextWithYesNoView question={question} answer={answer} onChange={value => save(question.id, value)} enabled={Number(id) === user.id} /></ListItem>
    }
  }

  const handleSave = () => {
    const data = [];
    var hasEmpty = false;
    questionnaireData.questions
      .filter(v => v.type === QuestionTypes.TEXT_WITH_INPUT || v.type === QuestionTypes.TEXT_WITH_YES_NO)
      .forEach(question => {
        console.log("questionnaireData item", question);
        console.log("questionnaireData answers", answers[question.id]);
        if ((!hasPreviousAnswer(question.id) && !hasCurrentAnswer(question)) || (!currentValueIsCorrect(question) && !hasPreviousAnswer(question.id))) {
          setHighlighted(prev => [...prev, question.id]);
          hasEmpty = true;
          console.log("questionnaireData no answer:", question, currentValueIsCorrect(question), hasPreviousAnswer(question.id));
        } else {
          if (hasCurrentAnswer(question)) {
            data.push({
              id: question.id,
              type: question.type,
              value: answers[question.id]
            })
          } else {
            const answer = findAnswer(question.id);
            data.push({
              id: answer.questionId,
              type: answer.type,
              value: answer.payload.value
            })
          }
        }
      });
    
      if (!hasEmpty) {
        sendAnswersMutation.mutate({ answers: data }, {
          onSuccess: (data, error, variables, context) => {
            console.log('answers are sent!')
            queryClient.invalidateQueries(QUERY_KEYS.getQuestionnaire(id))
            history.goBack()
            setTimeout(() => {
              sendAnswersMutation.reset()
            }, 3000)
          },
          onError: () => {
            logAndReset(sendAnswersMutation)
          }
        });
      }
  }

  if (!user) {
    <div className={styles.box}>
      <div className={styles.content}>
        <ProfilePaper variant="outlined" sx={{ borderRadius: 2 }}>
          <Typography>Please, login to see your Questionnaire</Typography>
        </ProfilePaper>
      </div>
    </div>
  }

  return (
    <PageLayout>
      <div className={styles.content}>
        <ProfilePaper variant="outlined" sx={{ borderRadius: 2 }}>
          <div className={styles.questions}>
            <List>
            {
              questionnaireData.questions.map(question => buildQuestionView(question))
            }
            </List>
          </div>

          {
            user && user.id === Number(id) && <div className={styles.saveButton}>
              <Button variant="contained" onClick={handleSave}>Save</Button>
            </div>
          }
        </ProfilePaper>
      </div>
    </PageLayout>
  );
};

export default QuestionnairePage;
