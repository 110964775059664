import React from "react";
import { useSelector } from "react-redux";
import styles from  "./PageLayout.module.css";
import Footer from "../../widgets/Footer/index.js";

const PageLayout = ({ children, fullscreen }) => {

  return (
    <div className={fullscreen ? styles.pageLayoutFullscreen : styles.pageLayout} >
      <div className={ fullscreen ? styles.pageFullscreen : "" }>
        { children }
      </div>
      
      <Footer />
    </div>
  );
};

export default PageLayout;
