import { CircularProgress, Link, styled, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import MasterListItem from "../../components/widgets/MasterListItem";
import ServerError from "../../components/widgets/ServerError/ServerError";
import QUERY_KEYS from "../../net/query-keys";
import REQUESTS from "../../net/requests";
import styles from "./ContactsPage.module.css"
import GoogleMapReact from 'google-map-react';
import PageLayout from "../../components/layouts/PageLayout";
import InstagramLink from "./InstagramLink";
import EmailLink from "./EmailLink";
import PhoneLink from "./PhoneLink";

const Text = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.onBackground,
  padding: theme.spacing(1)
}));


const ContactsPage = () => {
  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627
    },
    zoom: 11
  };
  const maxW900 = useMediaQuery('(max-width:900px)');

  return (
    <PageLayout fullscreen={!maxW900} >
      <div className={styles.mapBox}>
        <div className={styles.map}>
          <iframe className={styles.mapFrame}
            allowfullscreen
            loading="lazy"
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBC3QnjFmdSZlVM6bL8N1S47CKp3F2ZuV4&q=52.24797139552485+21.057095393253004">
          </iframe>
        </div>

        <div className={styles.contactsBox}>
          <div className={styles.contacts}>
            <Typography variant="h4">Contacts</Typography>
            <Typography variant="p" sx={{ marginTop: 1 }}>Warszawa, Minska 9</Typography>
            <PhoneLink color="primary.main" />
            <EmailLink color="primary.main" />
            <InstagramLink color="primary.main" />
          </div>
          <div>
            <video width={maxW900 ? "160" : "320" } height={maxW900 ? "285" : "569" } controls allowFullScreen>
              <source src="/contacts_video_1.mp4" type="video/mp4"/>
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default ContactsPage;
