import InstagramIcon from '@mui/icons-material/Instagram';
import styles from "./InstagramLink.module.css"
import { Link, Typography } from "@mui/material";

const InstagramLink = ({ color, fontSize }) => {
  return (
    <Link href="https://www.instagram.com/face_time_cosmetology/" className={styles.box}>
      <InstagramIcon sx={{ color }}/>
      <Typography sx={{ color, fontSize, textDecoration: 'underline' }}>face_time_cosmetology</Typography>
    </Link>
  );
};

export default InstagramLink;