import React from "react";
import { useQuery } from "react-query";
import { CircularProgress } from "@mui/material";
import QUERY_KEYS from "../../../../../../net/query-keys";
import REQUESTS from "../../../../../../net/requests";
import ServerError from "../../../../ServerError/ServerError";
import MetricCard from "../MetricCard";

const AnalyticsCards = ({ startTime, endTime }) => {
  const metricsRequest = useQuery(QUERY_KEYS.getGeneralAnalytics(startTime, endTime), REQUESTS.getGeneralAnalytics(startTime, endTime), { refetchOnMount: true });

  if (metricsRequest.isLoading) {
    return <CircularProgress />
  }
  if (metricsRequest.isError) {
    return <ServerError/>
  }

  const metrics = metricsRequest.data;

  return (
    <>
      <MetricCard title="Новых записей" value={metrics.newRecords} />
      <MetricCard title="Записей отменено" value={metrics.canceledRecords} />
      <MetricCard title="Новых пользователей" value={metrics.newUsers} />
      <MetricCard title="Всего посещений" value={metrics.totalVisitors} />
      <MetricCard title="Новых записей, когда пользователь записался сам" value={metrics.newSelfRecords} />
      <MetricCard title="Всего пользователей" value={metrics.totalUsers} />
    </>
  );
}

export default AnalyticsCards;