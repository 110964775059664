import * as React from 'react';
import styles from "./EditServiceDialog.module.css";
import { useSelector, useDispatch } from "react-redux";
import { IconButton, Dialog, DialogTitle, DialogContent, CircularProgress, Button, Divider, styled, FormControl, InputLabel, Select, MenuItem, Typography, TextField, FormControlLabel, Checkbox } from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import dayjs, { Dayjs } from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import QUERY_KEYS from '../../../net/query-keys';
import REQUESTS from '../../../net/requests';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { logAndReset } from '../../../utils/requestUtils';
import { useState } from 'react';
import ServerError from '../../widgets/ServerError/ServerError';
import LANGS from '../../../constants/langs';
import RisksFields from './RisksFields';

dayjs.extend(isToday);

const BlurryDialog = styled(Dialog)(({ theme }) => ({
  backdropFilter: "blur(5px)"
}));

const EditServiceDialog = ({ onClose, open, service }) => {

  const findText = (items, lang) => items.find(v => v.lang === lang)?.text ?? "";

  const enLang = LANGS.EN.title.toLowerCase();
  const plLang = LANGS.PL.title.toLowerCase();
  const ruLang = LANGS.RU.title.toLowerCase();

  const [enTitle, setEnTitle] = useState(findText(service.titles, enLang));
  const [plTitle, setPlTitle] = useState(findText(service.titles, plLang));
  const [ruTitle, setRuTitle] = useState(findText(service.titles, ruLang));
  const [enSubTitle, setEnSubTitle] = useState(findText(service.subtitles, enLang));
  const [plSubTitle, setPlSubTitle] = useState(findText(service.subtitles, plLang));
  const [ruSubTitle, setRuSubTitle] = useState(findText(service.subtitles, ruLang));
  const [enText, setEnText] = useState(findText(service.descriptions, enLang));
  const [plText, setPlText] = useState(findText(service.descriptions, plLang));
  const [ruText, setRuText] = useState(findText(service.descriptions, ruLang));
  const recomendationsRef = React.useRef({});
  const [file, setFile] = useState()
  const [isPrivate, setPrivate] = useState(service.private ?? false)
  const [published, setPublished] = useState(service.published ?? false)

  const risksRequest = useQuery(QUERY_KEYS.risks(service.id), REQUESTS.getRisks(service.id));
  const updateServiceMutation = useMutation(REQUESTS.putService)
  const queryClient = useQueryClient();

  const handleClose = (event) => {
    onClose();
  };

  const handleChange = (event) => {
  };

  const field = (key, value, defaultValue) => {
    if (value !== null && value !== defaultValue) {
      return { [key]: value }
    } else {
      return {}
    }
  }
  
  const handleCreateService = () => {
    const data = {
      id: service.id,
      published,
      private: isPrivate,
      
      ...field("titleEn", enTitle, findText(service.titles, enLang)),
      ...field("titlePl", plTitle, findText(service.titles, plLang)),
      ...field("titleRu", ruTitle, findText(service.titles, ruLang)),
      
      ...field("subtitleEn", enSubTitle, findText(service.subtitles, enLang)),
      ...field("subtitlePl", plSubTitle, findText(service.subtitles, plLang)),
      ...field("subtitleRu", ruSubTitle, findText(service.subtitles, ruLang)),
      
      ...field("descriptionEn", enText, findText(service.descriptions, enLang)),
      ...field("descriptionPl", plText, findText(service.descriptions, plLang)),
      ...field("descriptionRu", ruText, findText(service.descriptions, ruLang)),
      
      ...field("recomendationsEn", recomendationsRef.current?.recomendationsEn, findText(risksRequest.data.recomendations, enLang)),
      ...field("recomendationsPl", recomendationsRef.current?.recomendationsPl, findText(risksRequest.data.recomendations, plLang)),
      ...field("recomendationsRu", recomendationsRef.current?.recomendationsRu, findText(risksRequest.data.recomendations, ruLang)),
      
      ...field("risksEn", recomendationsRef.current?.risksEn, findText(risksRequest.data.risks, enLang)),
      ...field("risksPl", recomendationsRef.current?.risksPl, findText(risksRequest.data.risks, plLang)),
      ...field("risksRu", recomendationsRef.current?.risksRu, findText(risksRequest.data.risks, ruLang)),
      
      ...field("afterEn", recomendationsRef.current?.afterEn, findText(risksRequest.data.recsAfter, enLang)),
      ...field("afterPl", recomendationsRef.current?.afterPl, findText(risksRequest.data.recsAfter, plLang)),
      ...field("afterRu", recomendationsRef.current?.afterRu, findText(risksRequest.data.recsAfter, ruLang)),

      ...field("file", file, undefined),
    }
    
    console.log('update service:', data);
    updateServiceMutation.mutate(data, {
      onSuccess: (data, error, variables, context) => {
        console.log('category is updated!')
        queryClient.invalidateQueries(QUERY_KEYS.SERVICES);
        queryClient.invalidateQueries(QUERY_KEYS.risks(service.id));
        setTimeout(() => {
          updateServiceMutation.reset()
        }, 3000)
      },
      onError: () => {
        logAndReset(updateServiceMutation)
      }
    });
  }

  const handleFileChange = (event) => {
    if (event.target.files) {
      console.log(event.target.files[0].name);
      setFile(event.target.files[0]);
    }
  }

  return (
    <>
      <BlurryDialog onClose={handleClose} open={open} fullWidth={true} maxWidth="md">
        <DialogTitle sx={{ m: 0, paddingRight: 7 }}>
          Edit Service
        </DialogTitle>
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 12,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ paddingTop: 0 }}>
          <div>
            <div>
              <div className={styles.checks}>
                <FormControlLabel control={<Checkbox checked={isPrivate} onChange={event => setPrivate(event.target.checked)} />} label="Private" />
                <FormControlLabel control={<Checkbox checked={published} onChange={event => setPublished(event.target.checked)} />} label="Published" />
              </div>
              <Typography>Titles</Typography>
              <Divider />
              <TextField sx={{margin: 2}} label="Title [en]" value={enTitle} onChange={event => setEnTitle(event.target.value)} />
              <TextField sx={{margin: 2}} label="Title [pl]" value={plTitle} onChange={event => setPlTitle(event.target.value)} />
              <TextField sx={{margin: 2}} label="Title [ru]" value={ruTitle} onChange={event => setRuTitle(event.target.value)} />

              <Typography sx={{marginTop: 2}}>SubTitles</Typography>
              <Divider />
              <TextField sx={{margin: 2}} label="SubTitle [en]" value={enSubTitle} onChange={event => setEnSubTitle(event.target.value)} />
              <TextField sx={{margin: 2}} label="SubTitle [pl]" value={plSubTitle} onChange={event => setPlSubTitle(event.target.value)} />
              <TextField sx={{margin: 2}} label="SubTitle [ru]" value={ruSubTitle} onChange={event => setRuSubTitle(event.target.value)} />
              
              <Typography sx={{marginTop: 2}}>Descriptions</Typography>
              <Divider />
              <TextField sx={{marginTop: 2, width: "100%"}} multiline label="Text [en]" value={enText} onChange={event => setEnText(event.target.value)} />
              <br/>
              <TextField sx={{marginTop: 2, width: "100%"}}  multiline label="Text [pl]" value={plText} onChange={event => setPlText(event.target.value)} />
              <br/>
              <TextField sx={{marginTop: 2, width: "100%"}}  multiline label="Text [ru]" value={ruText} onChange={event => setRuText(event.target.value)} />
              
              {
                risksRequest.isLoading ? <CircularProgress /> :
                  risksRequest.isError ? <ServerError/> : 
                    <RisksFields data={risksRequest.data} inputRef={recomendationsRef} />
              }
              
              
              <Typography sx={{marginTop: 2}}>Image</Typography>
              <Divider sx={{marginBottom: 2}}/>
              <input accept="image/*" type="file" onChange={handleFileChange} />
              
              {
                updateServiceMutation.isLoading ? ('Updating...') : (
                  <>
                    { updateServiceMutation.isError ? <div>An error occurred: {updateServiceMutation.error.message}</div> : null }
                    { updateServiceMutation.isSuccess ? <div>Service was updated!</div> : <div align="center"><Button sx={{marginTop: 2}} variant="outlined" onClick={handleCreateService}>Update Service</Button></div> }
                  </>
                )
              }
            </div>
          </div>
        </DialogContent>
      </BlurryDialog>
    </>
  );
};

export default EditServiceDialog;
