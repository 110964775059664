import restClient from "../net/RestService";
import QUERY_KEYS from "../net/query-keys";
import saveMasterData from "../redux/actionCreators/saveMasterData";
import signIn from "../redux/actionCreators/signIn";


export const loginUser = async (dispatch, token, queryClient) => {
    console.log("loginUser");
    const inviteCode = sessionStorage.getItem("inviteCode");
    const hasConsent = true;
    const serverResponse = await restClient.login(token, inviteCode, hasConsent);
    const masterResponse = await restClient.getMasterData(serverResponse.data.id);
    dispatch(signIn(serverResponse.data));
    dispatch(saveMasterData(masterResponse.data.success === false ? null : masterResponse.data));
    if (queryClient) {
      console.log("invalidateQueries");
      queryClient.invalidateQueries({
        predicate: (query) => {
          console.log("predicate", query.queryKey);
          return query.queryKey.indexOf(`${QUERY_KEYS.SERVICES}/forCategory`) !== -1 || query.queryKey === QUERY_KEYS.CATEGORIES || query.queryKey === QUERY_KEYS.FEATURED_SERVICES
        }
      });
    }
};