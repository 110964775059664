import React, { useEffect, useRef, useState } from "react";
import { Button, Checkbox, CircularProgress, Typography } from "@mui/material";
import styles from "./DisableNextDayRecords.module.css";
import { DatePicker, DateTimePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import REQUESTS from "../../../../../net/requests";
import QUERY_KEYS from "../../../../../net/query-keys";
import ServerError from "../../../ServerError/ServerError";
import { useMutation, useQuery } from "react-query";
import dayjs from "dayjs";
import { logAndReset } from "../../../../../utils/requestUtils";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc)
dayjs.extend(timezone)

const POLAND_TZ = "Europe/Warsaw"
const MINUTE = 60 * 1000;

const DisableNextDayRecords = () => {
  const [checkpointTime, setCheckpointTime] = useState(null)
  const [checked, setChecked] = useState(true)
  const settingsRequest = useQuery(QUERY_KEYS.SETTINGS, REQUESTS.getSettings(), { refetchOnMount: true });
  const updateSettingsMutation = useMutation(REQUESTS.updateSettings())

  const handleTimeChanged = (time) => {
    setCheckpointTime(time)
  }

  const handleSave = () => {
    const currentMillis = checkpointTime ? checkpointTime.hour() * 60 * MINUTE + checkpointTime.minute() * MINUTE : 0;
    const data = {
      morningCheckpoint: checked ? currentMillis : 0
    }
    console.log('update question item:', data);
    updateSettingsMutation.mutate(data, {
      onSuccess: (data, error, variables, context) => {
        setTimeout(() => {
          updateSettingsMutation.reset();
        }, 3000);
      },
      onError: () => {
        logAndReset(updateSettingsMutation);
      }
    });
  }
  
  if (settingsRequest.isLoading) {
    return <CircularProgress />
  }
  if (settingsRequest.isError || settingsRequest.data.length === 0) {
    return <ServerError/>
  }

  const settings = settingsRequest.data[0];
  if (checkpointTime == null) {
    setChecked(settings.morningCheckpoint !== 0)
    setCheckpointTime(dayjs.utc().tz(POLAND_TZ).startOf('day').add(settings.morningCheckpoint, 'millisecond'))
  }

  console.log("checkpointTime:", checkpointTime)
  return (
    <div className={styles.box}>
      <div className={styles.row}>
        <Checkbox checked={checked} onChange={event => setChecked(event.target.checked)}/>
        <Typography align="start">Отключить возможность записи на завтра после ({POLAND_TZ}):</Typography>
      </div>
      
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
        <TimePicker disabled={!checked} label="time" ampm={false} timeSteps={{ minutes: 15 }} value={checkpointTime} onChange={handleTimeChanged}/>
      </LocalizationProvider>
      {
        updateSettingsMutation.isLoading ? "Saving..." :
          updateSettingsMutation.isError ? "Error" :
            updateSettingsMutation.isSuccess ? "Saved!" :
              <Button variant="outlined" onClick={handleSave}>Save</Button>
      }
    </div>
  );
}

export default DisableNextDayRecords;