import React, { useState } from "react";
import styles from "./ActionConfirmationDialog.module.css";
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, TextField, Typography, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";

const BlurryDialog = styled(Dialog)(({ theme }) => ({
  backdropFilter: "blur(5px)"
}));

const ActionConfirmationDialog = ({ open, onClose, text, onConfirm, isLoading }) => {

  const { t } = useTranslation();

  const handleConfirm = () => {
    onConfirm();
  }

  const handleClose = () => {
    onClose();
  }

  return (
    <>
      <BlurryDialog onClose={handleClose} open={open} fullWidth={true} maxWidth="sm">
        <DialogTitle sx={{ m: 0, paddingRight:7 }} id="customized-dialog-title">
          { t('profileConfirmationTitle') }
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 12,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ pt: 0 }}>
          <Typography>{ text }</Typography>
          <div className={styles.actions}>
            {
              isLoading ? <CircularProgress /> : <>
                <Button variant="outlined" onClick={handleConfirm}>{ t('yes') }</Button>
                <Button variant="outlined" onClick={handleClose} sx={{ marginLeft: 2}}>{ t('no') }</Button>
              </>
            }
          </div>
        </DialogContent>
      </BlurryDialog>
    </>
  );
};

export default ActionConfirmationDialog;