import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styles from "./SimpleTextView.module.css"

const SimpleTextView = ({ question, answer }) => {

  const lang = useSelector(state => state.lang);

  const text = question.translations.find(v => v.lang === lang.title.toLowerCase())?.text ?? ""

  return (
    <div className={styles.box}>
      <Typography>{ text }</Typography>
    </div>
  );
};

export default SimpleTextView;