import * as React from 'react';
import styles from "./ChangeScheduleDialog.module.css";
import { useSelector, useDispatch } from "react-redux";
import { IconButton, Dialog, DialogTitle, DialogContent, CircularProgress, Button, Divider, styled, FormControl, InputLabel, Select, MenuItem, Typography } from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import DateItem from '../DateItem';
import dayjs, { Dayjs } from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import QUERY_KEYS from '../../../net/query-keys';
import REQUESTS from '../../../net/requests';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { logAndReset } from '../../../utils/requestUtils';
import { useState } from 'react';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ServerError from '../../widgets/ServerError/ServerError';
import 'dayjs/locale/ru';
import { useTranslation } from 'react-i18next';

dayjs.extend(isToday);

const SIX_HOURS = 6 * 60 * 60 * 1000;
const DAY = 24 * 60 * 60 * 1000;
const TIME_STEPS = 30

const dates = [Date.now(), Date.now() + 1 * DAY, Date.now() + 2 * DAY, Date.now() + 3 * DAY, Date.now() + 4 * DAY, Date.now() + 5 * DAY, Date.now() + 6 * DAY]

const BlurryDialog = styled(Dialog)(({ theme }) => ({
  backdropFilter: "blur(5px)"
}));

const buildTimes = (selectedDayData) => {
  if (!selectedDayData) {
    return [];
  }
  const day = selectedDayData.day;
  const items = selectedDayData.times;
  const times = [];
  items.forEach(item => {
    if (item >= day && item < day + DAY) {
      times.push(item);
    }
  });
  return times;
}


const ChangeScheduleDialog = ({ onClose, open, title, startTime, endTime, masterId, scheduleId }) => {
  const user = useSelector((state) => state?.user);
  const [startDate, setStartDate] = useState(dayjs(startTime))
  const [endDate, setEndDate] = useState(dayjs(endTime))
  const queryClient = useQueryClient();
  const changeScheduleMutation = useMutation(REQUESTS.changeSchedule(masterId, scheduleId))
  const { t } = useTranslation();


  const handleChangeSchedule = () => {
    const data = {
      scheduleId,
      startTime: startDate ? startDate.valueOf() : 0,
      endTime: endDate ? endDate.valueOf() : 0
    }
    changeScheduleMutation.mutate(data, {
      onSuccess: (data, error, variables, context) => {
        console.log(`new schedule was added successfully!`)
        queryClient.invalidateQueries(QUERY_KEYS.getMasterSchedules(masterId));
        queryClient.invalidateQueries(QUERY_KEYS.ALL_MASTERS_SCHEDULES);
        
        setTimeout(() => {
          changeScheduleMutation.reset()
        }, 3000)
      },
      onError: () => {
        //logAndReset(changeScheduleMutation)
      }
    });
  }



  const handleClose = (event) => {
    onClose();
  };


  return (
    <>
      <BlurryDialog onClose={handleClose} open={open} fullWidth={true} maxWidth="sm">
        <DialogTitle sx={{ m: 0, paddingRight:7 }} id="customized-dialog-title">{ title }</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 12,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ pt: 0 }} >
          <div className={styles.addScheduleDialog}>
            <div>
              <Typography sx={{ pb: 1 }}>{ t('currentTime') }: {dayjs(startTime).format('HH:mm')} - {dayjs(endTime).format('HH:mm')}</Typography>
            </div>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
              <DateTimePicker label="start time" disablePast={true} ampm={false} timeSteps={{ minutes: TIME_STEPS }} value={startDate} onChange={(time) => {
                setStartDate(time)
                setEndDate(time.add(SIX_HOURS, 'ms'))
                console.log("setStartDate: " + time)
              }}/>
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
              <DateTimePicker label="end time" disablePast={true} ampm={false} timeSteps={{ minutes: TIME_STEPS }} value={endDate} onChange={(time) => {
                setEndDate(time)
                console.log("setEndDate: " + time)
              }}/>
            </LocalizationProvider>

            {changeScheduleMutation.isLoading ? ('Adding schedule...') : (
              <>
              {changeScheduleMutation.isError ? (
                <div>Error: {changeScheduleMutation.error.response.data.error.message ? changeScheduleMutation.error.response.data.error.message : changeScheduleMutation.error.message}</div>
              ) : null}

              {changeScheduleMutation.isSuccess ? <div>Time was changed!</div> : null}
              </>
            )}
            <Button variant='outlined' onClick={handleChangeSchedule}>{ t('change') }</Button>
          </div>
        </DialogContent>
      </BlurryDialog>
    </>
  );
};

export default ChangeScheduleDialog;
