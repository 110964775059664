import { useQuery } from "react-query";
import QUERY_KEYS from "../../../../net/query-keys";
import REQUESTS from "../../../../net/requests";
import { Button, CircularProgress, Typography } from "@mui/material";
import ServerError from "../../../../components/widgets/ServerError/ServerError";
import { useSelector } from "react-redux";
import styles from './MasterNotes.module.css';
import { useState } from "react";
import MasterNoteDialog from "./MasterNoteDialog";
import MasterNote from "./MasterNote";
import { useTranslation } from "react-i18next";

const MasterNotes = ({ profile }) => {
  const user = useSelector((state) => state?.user);
  const [open, setOpen] = useState(false)
  const { t } = useTranslation();

  const notesRequest = useQuery(QUERY_KEYS.getMasterNote(profile.id), REQUESTS.getMasterNote(profile.id))

  if (notesRequest.isLoading) {
    return <CircularProgress />
  }
  if (notesRequest.isError) {
    return <ServerError/>
  }
  const notes = notesRequest.data.sort((a, b) => {
    if (a.userId === user.id) {
      return -1
    }
    if (b.userId === user.id) {
      return 1
    }
    return a.id - b.id;
  });

  const createNote = () => {
    setOpen(true)
  }

  return <div className={styles.box}>
    { notes.map(note => <MasterNote key={note.id} note={note} profileId={profile.id} />) }
    { !notes.find(note => note.userId === user.id) && <Button variant="contained" sx={{ borderRadius: 12, textTransform: 'none', mt: 2, ml: 3, mr: 3 }} onClick={createNote}>{ t('profileNoteCreate') }</Button>}
    { open && <MasterNoteDialog open={open} onClose={() => setOpen(false)} note={null} profileId={profile.id}/> }
  </div>
}

export default MasterNotes;