import React, { useRef, useState } from "react";
import { Button, Card, CircularProgress, TextField, Typography } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "react-query";
import styles from "./MasterServicesItem.module.css"
import { useSelector } from "react-redux";
import QUERY_KEYS from "../../../../../net/query-keys";
import REQUESTS from "../../../../../net/requests";
import ServerError from "../../../ServerError/ServerError";
import { getPrice } from "../../../../dialogs/utils";
import { logAndReset } from "../../../../../utils/requestUtils";

const MasterServicesItem = ({ service }) => {
  const lang = useSelector((state) => state?.lang).title.toLowerCase();

  const serviceRequest = useQuery(QUERY_KEYS.getService(service.serviceId), REQUESTS.getService(service.serviceId), { refetchOnMount: true });
  const deleteMutation = useMutation(REQUESTS.deleteMasterService);
  const queryClient = useQueryClient();

  if (serviceRequest.isLoading) {
    return <CircularProgress />
  }
  if (serviceRequest.isError) {
    return <ServerError/>
  }

  const handleDelete = () => {
    console.log('delete service:', service);
    const data = {
      masterId: service.masterId,
      serviceId: service.serviceId
    };
    deleteMutation.mutate(data, {
      onSuccess: (data, error, variables, context) => {
        console.log('done!')
        queryClient.invalidateQueries(QUERY_KEYS.getMasterServices(service.masterId));
        setTimeout(() => {
          deleteMutation.reset()
        }, 3000)
      },
      onError: () => {
        logAndReset(deleteMutation)
      }
    });
  }

  const serviceData = serviceRequest.data;

  const findText = (titles) => titles.find(translation => translation.lang === lang)?.text;

  console.log("MasterServicesItem", service);

  const price = getPrice(service.minPrice, service.maxPrice);

  return (
    <div className={styles.box}>
      <img src={serviceData.imageUrl} className={styles.image}/>
      <div className={styles.info}>
        <Typography>Id: { service.id }</Typography>
        <Typography>Title: { findText(serviceData.titles) }</Typography>
        <Typography>SubTitle: { findText(serviceData.subtitles) }</Typography>
        <Typography>Time: { service.timeRequirements } minutes</Typography>
        <Typography>Price: { price }</Typography>
        
        {
          deleteMutation.isLoading ? ('Deleting service...') :
            deleteMutation.isError ? <div>An error occurred: { deleteMutation.error.message }</div> : 
              deleteMutation.isSuccess ? <div>Service was deleted!</div> : 
                <Button variant='outlined' onClick={handleDelete} sx={{marginTop: 2}} >Delete Service</Button>
        }
      </div>
    </div>
  );
}

export default MasterServicesItem;