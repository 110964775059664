import styles from './LoginPage.module.css';
import 'moment/locale/ru';
import 'moment/locale/pl';
import { useEffect, useState } from "react";
import dayjs from 'dayjs';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import URLS from '../../../../../constants/urls';
import QuestionsView from '../../../BookDialog/BookFinalPage/QuestionsView';
import TranslationTypes from '../../../../../constants/translationTypes';
import { useSelector } from 'react-redux';
import { masterDisplayName } from '../../../../../utils/formatUtils';
import { trackBookingPage, trackConversion } from '../../../../../ga';
import LoginCard from '../../../../widgets/LoginCard';


const LoginPage = ({ navigator }) => {
  const { t } = useTranslation();
  const lang = useSelector((state) => state?.lang).title.toLowerCase();

  useEffect(() => {
    trackBookingPage("LoginPage");
  }, []);

  const successCallback = () => {
    navigator.goBack();
  }

  const registerCallback = () => {
    navigator.openRegisterPage();
  }

  const restoreCallback = () => {
    navigator.openRestorePage();
  }

  return (
    <div className={styles.box}>
      <LoginCard successCallback={successCallback} registerCallback={registerCallback} restoreCallback={restoreCallback} />
    </div>
  );
};

export default LoginPage;