import { CircularProgress, List, Paper, styled, Typography } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import styles from "./HistoryList.module.css"
import { useParams } from 'react-router';
import { useSelector } from "react-redux";
import QUERY_KEYS from "../../../../net/query-keys";
import REQUESTS from "../../../../net/requests";
import ServerError from "../../../../components/widgets/ServerError/ServerError";
import UserRecord from "../../UserRecords/UserRecord";
import HistoryItem from "./HistoryItem";


const HistoryList = ({ profile }) => {  
  const recordsRequest = useQuery(QUERY_KEYS.getUserHistory(profile.id), REQUESTS.getUserHistory(profile.id), { refetchOnMount: true })
  
  if (recordsRequest.isLoading) {
    return <CircularProgress />
  }
  if (recordsRequest.isError) {
    return <ServerError/>
  }

  const records = recordsRequest.data.sort((a, b) => a.startTime - b.startTime)

  return (
    <div className={styles.box}>
      {
        records && records.length === 0 ? <div className={styles.empty}><Typography>You have no records</Typography></div> : <Typography>Previous records:</Typography>
      }

      {
        records && records.length > 0 &&
        <List dense={true}>
          { records.map(record => <HistoryItem key={record.id} record={record} />) }
        </List>
      }
    </div>
  );
};

export default HistoryList;
