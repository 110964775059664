import * as React from 'react';
import { Button, Checkbox, CircularProgress, Dialog, DialogContent, DialogTitle, FormControlLabel, IconButton, TextField, Typography, styled } from "@mui/material";
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import QUERY_KEYS from '../../../../net/query-keys';
import REQUESTS from '../../../../net/requests';
import { useQuery } from 'react-query';
import ServerError from '../../../widgets/ServerError/ServerError';

dayjs.extend(isToday);

const ProfileNotifications = ({ viewOnly, settings, onChange }) => {
  const [emailEnabled, setEmailEnabled] = React.useState(settings.emailNotification);
  const [recordCreated, setRecordCreated] = React.useState(settings.recordCreated);
  const [recordDeleted, setRecordDeleted] = React.useState(settings.recordDeleted);
  const [recordMoved, setRecordMoved] = React.useState(settings.recordMoved);

  const notify = (data) => {
    if (onChange) {
      const state = {
        emailNotification: emailEnabled,
        recordCreated,
        recordDeleted,
        recordMoved
      }
      onChange({
        ...state,
        ...data
      })
    }
  }
  const handleEmailChange = (event) => {
    setEmailEnabled(event.target.checked);
    if (!event.target.checked) {
      setRecordCreated(settings.recordCreated);
      setRecordDeleted(settings.recordDeleted);
      setRecordMoved(settings.recordMoved);
    }
    notify({ emailNotification: event.target.checked });
  }
  const handleRecordCreated = (event) => {
    setRecordCreated(event.target.checked);
    notify({ recordCreated: event.target.checked });
  }
  const handleRecordDeleted = (event) => {
    setRecordDeleted(event.target.checked);
    notify({ recordDeleted: event.target.checked });
  }
  const handleRecordMoved = (event) => {
    setRecordMoved(event.target.checked);
    notify({ recordMoved: event.target.checked });
  }

  return (
    <div>
      <FormControlLabel disabled={viewOnly} checked={emailEnabled} onChange={handleEmailChange} control={<Checkbox />} label="Email notifications" />
      <Typography>Allow next types of notifications:</Typography>
      <FormControlLabel disabled={viewOnly || !emailEnabled} checked={recordCreated} onChange={handleRecordCreated} control={<Checkbox />} label="New Record" />
      <FormControlLabel disabled={viewOnly || !emailEnabled} checked={recordDeleted} onChange={handleRecordDeleted} control={<Checkbox />} label="Record is deleted" />
      <FormControlLabel disabled={viewOnly || !emailEnabled} checked={recordMoved} onChange={handleRecordMoved} control={<Checkbox />} label="Record time is changed" />

    </div>
  );
};

export default ProfileNotifications;
