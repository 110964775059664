import TranslationTypes from "../constants/translationTypes";

export const userDisplayName = (user, withEmail) => {
  return user.isFake ? (user.fakeName + " [fake]") : user.displayName ?? user.fakeName ?? (withEmail ? user.email : '<Empty>')
};

export const masterDisplayName = (master, lang) => {
  if (!master) {
    return "";
  }
  const name = master.translations.find(t => t.lang === lang && t.type === TranslationTypes.MASTER_NAME)?.text ?? master.name;
  const surname = master.translations.find(t => t.lang === lang && t.type === TranslationTypes.MASTER_SURNAME)?.text ?? master.surname;
  return `${name} ${surname}`;
};