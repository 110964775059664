import { CircularProgress, styled, Typography } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import ServerError from "../../components/widgets/ServerError/ServerError";
import ServiceListItem from "../../components/widgets/ServiceListItem";
import QUERY_KEYS from "../../net/query-keys";
import REQUESTS from "../../net/requests";
import styles from "./ServicesPage.module.css"
import BookDialog from "../../components/dialogs/BookDialog";
import { useParams } from 'react-router';
import { useHistory } from "react-router-dom";
import URLS from "../../constants/urls";
import { useSelector } from "react-redux";
import FeaturedService from "../../components/widgets/FeaturedService";
import OrderDialog from "../../components/dialogs/OrderDialog";
import PageLayout from "../../components/layouts/PageLayout";


const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.onBackground,
  fontSize: 24,
  padding: theme.spacing(4)
}));


const ServicesPage = () => {
  const { id } = useParams();
  const history = useHistory();

  const user = useSelector(state => state.user);
  const lang = useSelector(state => state.lang).title.toLowerCase();
  const [bookDialog, setBookDialog] = useState({ open: false, service: null })

  const servicesRequest = useQuery(QUERY_KEYS.getServicesForCategory(id), REQUESTS.getServicesForCategory(id))
  const categoriesRequest = useQuery(QUERY_KEYS.CATEGORIES, REQUESTS.getCategories)

  if (servicesRequest.isLoading) {
    return <CircularProgress />
  }
  if (servicesRequest.isError) {
    return <ServerError/>
  }

  const services = servicesRequest.data.sort((a, b) => a.id - b.id)
  console.log("services: ", services)

  let title = 'Services'
  if (!categoriesRequest.isLoading && !categoriesRequest.isError) {
    title = categoriesRequest.data.find(v => v.id == id)?.translations.find(v => v.lang === lang)?.text ?? 'Services'
  }

  const handleOnBookClicked = (service) => {
    if (user) {
      setBookDialog({ open: true, service });
    } else {
      history.push(URLS.LOGIN, { from: URLS.categoryOf(id) });
    }
  }

  const handleDialogClosed = () => {
    setBookDialog({ open: false, service: null });
  }

  return (
    <PageLayout>
      <div className={styles.servicesPage}>
        <Title align="center">{ title }</Title>

        <ul className={styles.servicesList}>
          {services.map(service => <li key={service.id}><FeaturedService key={service.id} service={service} from={URLS.categoryOf(id)}/></li>) /*<ServiceListItem service={service} onBook={() => handleOnBookClicked(service)}/>*/}
        </ul>

        {/* { bookDialog.open && <BookDialog service={bookDialog.service}  open={bookDialog.open} onClose={handleDialogClosed} startDate={Date.now()}/> } */}
          { bookDialog.open && <OrderDialog onClose={handleDialogClosed} open={bookDialog.open} /> }
      </div>
    </PageLayout>
  );
};

export default ServicesPage;
