import { Button, Divider, ListItem, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import dayjs from 'dayjs';
import styles from "./FeedbackItem.module.css"
import { useTranslation } from "react-i18next";
import ActionConfirmationDialog from "./ActionConfirmationDialog";
import REQUESTS from "../../../../../net/requests";
import { useMutation, useQueryClient } from "react-query";
import QUERY_KEYS from "../../../../../net/query-keys";
import { logAndReset } from "../../../../../utils/requestUtils";


const FeedbackItem = ({ feedback, showAnswered }) => {
  const user = useSelector((state) => state?.user);
  const lang = useSelector(state => state.lang).title.toLowerCase();
  const { t } = useTranslation();

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const answeredMutation = useMutation(REQUESTS.postFeedbackAnswered(feedback.id))
  const queryClient = useQueryClient();

  const handleConfirm = () => {
    const data = {
      value: !feedback.answered,
    }
    answeredMutation.mutate(data, {
      onSuccess: (data, error, variables, context) => {
        setShowConfirmationDialog(false)
        queryClient.invalidateQueries(QUERY_KEYS.getFeedbacks());
        queryClient.invalidateQueries(QUERY_KEYS.getNewFeedbacks());
      },
      onError: () => {
        logAndReset(answeredMutation)
        setShowConfirmationDialog(false)
      }
    });
  }


  return (<>
    <ListItem sx={{ padding: 0, marginTop: 1 }}>
      <div className={styles.column}>
        { showAnswered && <Typography>{ `${t('feedbackIsNew')}: ${ feedback.answered ? t('no') : t('yes') }` }</Typography>}
        <Typography>{ t('name') }: {feedback.name}</Typography>
        <Typography>{ t('profilePhone') }: {feedback.phone}</Typography>
        <Typography>{ t('email') }: {feedback.email}</Typography>
        <Typography>{ t('feedbackCreatedAt') }: { dayjs(feedback.createdAt).format("DD.MM.YYYY HH:mm") }</Typography>
        <Typography>{ t('text') }: {feedback.text}</Typography>
        <Button variant="contained" sx={{ mt: 0 }} onClick={() => setShowConfirmationDialog(true)}>{ !feedback.answered ? t('feedbackAnswered') : t('feedbackUnmarkAnswered')  }</Button>
      </div>
      {
        showConfirmationDialog && <ActionConfirmationDialog open={showConfirmationDialog} onClose={() => setShowConfirmationDialog(false)} text={ !feedback.answered ? t('feedbackRemoveFromNew') : t('feedbackRemoveFromAll') } onConfirm={handleConfirm} isLoading={answeredMutation.isLoading}/>
      }
    </ListItem>
    <Divider light sx={{ mt: 1 }}/>
  </>);
};

export default FeedbackItem;
