import React, { useRef, useState } from "react";
import styles from "./Categories.module.css";
import ExpandMoreItem from "../ExpandMoreItem";
import { Button, Card, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import REQUESTS from "../../../../net/requests";
import { logAndReset } from "../../../../utils/requestUtils";
import QUERY_KEYS from "../../../../net/query-keys";
import CategoriesList from "../CategoriesList";

const Categories = () => {

  const [showCreatePanel, setShowCreatePanel] = useState(false)
  
  const [enText, setEnText] = useState('')
  const [plText, setPlText] = useState('')
  const [ruText, setRuText] = useState('')
  const [file, setFile] = useState()
  const [isPrivate, setPrivate] = useState(false)
  const [published, setPublished] = useState(false)

  const createCategoryMutation = useMutation(REQUESTS.postCategory)
  const queryClient = useQueryClient();
  
  const handleCreateCategory = () => {
    const data = {
      nameEn: enText,
      namePl: plText,
      nameRu: ruText,
      file,
      published,
      private: isPrivate
    }
    console.log('create category:', data);
    createCategoryMutation.mutate(data, {
      onSuccess: (data, error, variables, context) => {
        console.log('category is created!')
        queryClient.invalidateQueries(QUERY_KEYS.CATEGORIES);
        queryClient.invalidateQueries(QUERY_KEYS.CATEGORIES_ALL);
        setTimeout(() => {
          createCategoryMutation.reset()
        }, 3000)
      },
      onError: () => {
        logAndReset(createCategoryMutation)
      }
    });
  }

  const handleFileChange = (event) => {
    if (event.target.files) {
      console.log(event.target.files[0].name);
      setFile(event.target.files[0]);
    }
  }

  return (
    <>
      <ExpandMoreItem text="Categories">
        <CategoriesList />
        
        <Button variant="outlined" onClick={() => setShowCreatePanel(!showCreatePanel)}>{showCreatePanel ? 'Hide' : 'Add'} new Category</Button>
        {
          showCreatePanel && <Card sx={{ marginTop: 0, padding: 2 }} >
            <div className={styles.categoriesPanelBox}>
              <TextField label="en" value={enText} onChange={event => setEnText(event.target.value)} />
              <TextField label="pl" value={plText} onChange={event => setPlText(event.target.value)} />
              <TextField label="ru" value={ruText} onChange={event => setRuText(event.target.value)} />
              
              <div className={styles.checks}>
                <FormControlLabel control={<Checkbox checked={isPrivate} onChange={event => setPrivate(event.target.checked)} />} label="Private" />
                <FormControlLabel control={<Checkbox checked={published} onChange={event => setPublished(event.target.checked)} />} label="Published" />
              </div>

              <input accept="image/*" type="file" onChange={handleFileChange} />
              {
                createCategoryMutation.isLoading ? ('Creating category...') : (
                  <>
                    { createCategoryMutation.isError ? <div>An error occurred: {createCategoryMutation.error.message}</div> : null }
                    { createCategoryMutation.isSuccess ? <div>Category was created!</div> : <Button variant="outlined" onClick={handleCreateCategory}>Create Category</Button> }
                  </>
                )
              }
            </div>
          </Card>
        }

      </ExpandMoreItem>
    </>
  );
}

export default Categories;