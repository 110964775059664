import { Avatar, CircularProgress, List, Paper, styled, Typography } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import styles from "./UserWidget.module.css"
import QUERY_KEYS from "../../../../../net/query-keys";
import REQUESTS from "../../../../../net/requests";
import ServerError from "../../../../../components/widgets/ServerError/ServerError";
import { userDisplayName } from "../../../../../utils/formatUtils";
import { Link } from "react-router-dom";
import URLS from "../../../../../constants/urls";


const UserWidget = ({ record }) => {
  
  const userRequest = useQuery(QUERY_KEYS.getUserProfile(record.clientId), REQUESTS.getUserProfile(record.clientId))
  
  if (userRequest.isLoading) {
    return <CircularProgress />
  }
  if (userRequest.isError) {
    return <ServerError/>
  }

  const profile = userRequest.data
  const imageUrl = profile.imageUrl;
  const name = userDisplayName(profile, true);
  
  return <div className={styles.box}>
    <Avatar alt={name} src={imageUrl} sx={{ width: 40, height: 40, marginLeft: '8px' }} />
    <Typography><Link to={URLS.profileOf(record.clientId)}>{ name }</Link></Typography>
  </div>;
};

export default UserWidget;
