import React, { useState } from "react";
import "./ExpandMoreItem.css";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CardContent, Collapse, IconButton, styled } from "@mui/material";
import Text from "../../Text";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ExpandMoreItem = ({ text, children }) => {


  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <div className="expand-more-item-title">
        <Text onClick={handleExpandClick} sx={{ cursor: "pointer" }}>{text}</Text>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more">
            
          <ExpandMoreIcon />
        </ExpandMore>
      </div>
      
      
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        { children }
      </Collapse>
      
    </>
  );
}

export default ExpandMoreItem;
