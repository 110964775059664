import styles from './SummaryItem.module.css';
import 'moment/locale/ru';
import 'moment/locale/pl';
import { useState } from "react";
import dayjs from 'dayjs';
import { Avatar, IconButton, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { masterDisplayName } from '../../../../../../utils/formatUtils';


const SummaryItem = ({ record, services, masters, onDelete }) => {

  const lang = useSelector(state => state.lang).title.toLowerCase();
  
  const service = services.find(item => item.id === record.serviceId)
  const serviceTitle = service.titles.find(v => v.lang === lang)?.text ?? "";
  const serviceSubTitle = service.subtitles.find(v => v.lang === lang)?.text ?? "";
  const master = masters.find(item => item.id === record.masterId)

  return (
    <>
      <div className={styles.box + ' ' + styles.relative}>
        {
          onDelete && 
            <IconButton
              onClick={() => onDelete(record)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        }

        <Avatar alt={ serviceTitle } src={ service.imageUrl } sx={{ width: 80, height: 80 }} />
        
        <div>
          <Typography sx={{ fontWeight: 700 }} align="center">{ serviceTitle }</Typography>
          <Typography align="center">{ serviceSubTitle }</Typography>
        </div>

        <Typography align='center' sx={{ fontWeight: 'bold'}}>{dayjs(record.time).format("DD.MM.YYYY")} - {dayjs(record.time).format("HH:mm")}</Typography>

        <div className={styles.masterBox}>
          <Avatar alt={ master.name } src={ master.imageUrl } sx={{ width: 40, height: 40 }} />
          <Typography>{ `${masterDisplayName(master, lang)}` }</Typography>
        </div>
      </div>
    </>
  );
};

export default SummaryItem;