import { Typography } from "@mui/material";
import styles from "./OptionButton.module.css"

const OptionButton = ({ title, icon, onClick }) => {

  return(
    <>
      <div className={styles.box} onClick={onClick}>
        { icon }
        <Typography sx={{ paddingLeft: 1 }}>{ title }</Typography>
      </div>
    </>
  );
}

export default OptionButton;