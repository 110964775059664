import { Button, Card, CardActions, CardContent, Stack, styled, TextField, Typography } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { blue } from '@mui/material/colors';
import { useQuery } from "react-query";
import QUERY_KEYS from "../../net/query-keys";
import REQUESTS from "../../net/requests";
import BookSteps from "../../constants/bookSteps";
import SelectService from "../../components/widgets/Book/SelectService";
import SelectMaster from "../../components/widgets/Book/SelectMaster";
import styles from "./RegistrationPage.module.css"
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom/cjs/react-router-dom";
import URLS from "../../constants/urls";
import GoogleIcon from "../../svg/ic_google.svg"
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { useDispatch } from "react-redux";
import { loginUser } from "../../utils/loginUtils";
import firebaseHelper from "../../components/firebase";
import RegisterConsent from "../LoginPage/RegisterConsent";
import PageLayout from "../../components/layouts/PageLayout";

const Text = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.onBackground,
  padding: theme.spacing(1)
}));

const GoogleButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(blue[500]),
  backgroundColor: blue[500],
  '&:hover': {
    backgroundColor: blue[700],
  },
}));

const RegistrationPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState()
  const dispatch = useDispatch();

  const [bookState, setBookState] = useState({ step: BookSteps.SELECT_SERVICE })

  useEffect(() => {
    const params = new URL(document.location).searchParams;
    const code = params.get("code");
    if (code) {
      sessionStorage.setItem("inviteCode", code);
    }
  }, [])

  const handleRegister = () => {
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed up 
        const user = userCredential.user;
        console.log("new user:", user);
        
        user.getIdToken().then(token => {
          loginUser(dispatch, token).then(() => {
            history.replace(URLS.ROOT_URL)
          })
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("errorCode:", errorCode,"errorMessage:", errorMessage);
        
        setError(error.code)
        setTimeout(() => {
          setError(null);
        }, 3000);
      });
  }

  const handleGoogleLogin = async () => {
    await firebaseHelper.googleLogin(history, dispatch);
  };


  return (
    <PageLayout fullscreen={true} >
      <div className={styles.registrationPage}>
          <Card sx={{ minWidth: 275, padding: 2 }}>
            <CardContent>
              <Stack
                component="form"
                spacing={2}
                noValidate
                autoComplete="off"
              >
                <Typography align='center'>{ t('authRegistration') }</Typography>
                <TextField type="email" label={ t('authFormEmail') } value={email} onChange={event => setEmail(event.target.value)}/>
                <TextField type="password" label={ t('authFormPassword') } value={password} onChange={event => setPassword(event.target.value)}/>
                <TextField type="password" label={ t('authFormRepeatPassword') }/>
                <Typography align='center'>
                  <Link to={URLS.RESTORE_PASSWORD}>{ t('authRestorePassword') }</Link>
                </Typography>
                <Button size="large" variant="outlined" onClick={handleRegister}>{ t('authRegister') }</Button>
                {
                  error && <Typography align='center'>{ error }</Typography>
                }
                <Button size="large" variant="outlined" onClick={() => history.replace(URLS.LOGIN)}>{ t('authBackToLogin') }</Button>
                <Typography align='center'>{ t('or') }</Typography>
                <GoogleButton size="large" startIcon={<img src={GoogleIcon} width={24} variant="contained"/>} onClick={handleGoogleLogin}>{ t('authWithGoogle') }</GoogleButton>
                <RegisterConsent />
              </Stack>
            </CardContent>
          </Card>
      </div>
    </PageLayout>
  );
};

export default RegistrationPage;
