import { Avatar, Typography, useMediaQuery } from "@mui/material";
import OptionButton from "../../OptionButton";
import styles from './SelectServicePage.module.css';
import { useSelector } from "react-redux";
import TranslationTypes from "../../../../../constants/translationTypes";
import { getPrice } from "../../../utils";
import { useEffect, useState } from "react";
import { trackBookingPage } from "../../../../../ga";

const SelectServicePage = ({ build, categories, services, categoryServices, selectedId, onClick }) => {
  console.log("render SelectServicePage");
  const lang = useSelector((state) => state?.lang).title.toLowerCase();
  const maxW700 = useMediaQuery('(max-width:700px)');
  const [availableServices, setAvailableServices] = useState(build());

  useEffect(() => {
    trackBookingPage("ServicesPage");
  }, []);

  const findCategoryName = (category) => {
    return category.translations.find(v => v.lang === lang && v.type === TranslationTypes.CATEGORY_NAME)?.text ?? ""
  }

  const findServiceTitle = (service) => {
    return service.titles.find(v => v.lang === lang && v.type === TranslationTypes.SERVICE_TITLE)?.text ?? ""
  }

  const findServiceSubtitle = (service) => {
    return service.subtitles.find(v => v.lang === lang && v.type === TranslationTypes.SERVICE_SUBTITLE)?.text ?? ""
  }

  const byIdSort = (one, two) => one.id - two.id
  const nameIf = (condition, name) => (condition ? " " + name : "")
  const isDisabled = (service) => !availableServices.find(item => item.id === service.id);

  const visibleCategories = categories.sort(byIdSort).filter((category) => categoryServices.find(v => v.categoryId === category.id) && category.id !== 1)
  return (
    <>
      <div className={styles.services}>
        {
          visibleCategories.map(category => <div key={category.id}>
            <Typography sx={{ fontWeight: 'bold', paddingTop: 1, paddingBottom: 1 }}>{ findCategoryName(category) }</Typography>
            <div>
              {
                services
                  .filter(service => categoryServices.find(v => v.categoryId === category.id && v.serviceId === service.id))
                  .sort(byIdSort)
                  .map(service => <div key={service.id} onClick={() => { if (!isDisabled(service)) onClick(service) }} className={styles.serviceItemBox + nameIf(selectedId === service.id, styles.selectedService) + nameIf(isDisabled(service), styles.disabledService)}>
                    <div className={styles.serviceInfo}>
                      <Avatar alt="Remy Sharp" src={service.imageUrl} />
                      <div className={styles.serviceTitles}>
                        <Typography>{ findServiceTitle(service) }</Typography>
                        <Typography>{ findServiceSubtitle(service) }</Typography>
                      </div>
                    </div>
                    <div className={styles.servicePrice}>
                        <Typography sx={{ fontWeight: 'bold' }}>{ getPrice(service.minPrice, service.maxPrice, maxW700) }</Typography>
                    </div>
                  </div>)
              }
            </div>
          </div>)
        }
      </div>
    </>
  );
};

export default SelectServicePage;