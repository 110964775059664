import * as React from 'react';
import styles from "./RisksFields.module.css";
import { IconButton, Dialog, Divider, styled, Typography, TextField } from "@mui/material";
import { useState } from 'react';
import LANGS from '../../../../constants/langs';
import { useTranslation } from 'react-i18next';

const RisksFields = ({ data, inputRef }) => {

  const { t } = useTranslation();

  const findText = (items, lang) => items.find(v => v.lang === lang)?.text ?? "";

  const enLang = LANGS.EN.title.toLowerCase();
  const plLang = LANGS.PL.title.toLowerCase();
  const ruLang = LANGS.RU.title.toLowerCase();

  const [recomendationsEn, setRecomendationsEn] = useState(findText(data.recomendations, enLang))
  const [recomendationsPl, setRecomendationsPl] = useState(findText(data.recomendations, plLang))
  const [recomendationsRu, setRecomendationsRu] = useState(findText(data.recomendations, ruLang))
  const [risksEn, setRisksEn] = useState(findText(data.risks, enLang))
  const [risksPl, setRisksPl] = useState(findText(data.risks, plLang))
  const [risksRu, setRisksRu] = useState(findText(data.risks, ruLang))
  const [afterEn, setAfterEn] = useState(findText(data.recsAfter, enLang))
  const [afterPl, setAfterPl] = useState(findText(data.recsAfter, plLang))
  const [afterRu, setAfterRu] = useState(findText(data.recsAfter, ruLang))
  
  
  const handleChangeRecEn = (value) => {
    setRecomendationsEn(value);
    updateRef({ recomendationsEn: value });
  }

  const handleChangeRecPl = (value) => {
    setRecomendationsPl(value);
    updateRef({ recomendationsPl: value });
  }

  const handleChangeRecRu = (value) => {
    setRecomendationsRu(value);
    updateRef({ recomendationsRu: value });
  }

  const handleChangeRisksEn = (value) => {
    setRisksEn(value);
    updateRef({ risksEn: value });
  }
  const handleChangeRisksPl = (value) => {
    setRisksPl(value);
    updateRef({ risksPl: value });
  }
  const handleChangeRisksRu = (value) => {
    setRisksRu(value);
    updateRef({ risksRu: value });
  }

  const handleChangeAfterEn = (value) => {
    setAfterEn(value);
    updateRef({ afterEn: value });
  }
  const handleChangeAfterPl = (value) => {
    setAfterPl(value);
    updateRef({ afterPl: value });
  }
  const handleChangeAfterRu = (value) => {
    setAfterRu(value);
    updateRef({ afterRu: value });
  }

  const updateRef = (data) => {
    if (inputRef) {
      inputRef.current = {
        ...inputRef.current,
        ...data
      }
    }
  }

  return (
    <>
      <Typography sx={{marginTop: 2}}>Recomendations</Typography>
      <Divider />
      <TextField sx={{marginTop: 2, width: "100%"}} multiline label="Recomendations [en]" value={recomendationsEn} onChange={event => handleChangeRecEn(event.target.value)} />
      <TextField sx={{marginTop: 2, width: "100%"}} multiline label="Recomendations [pl]" value={recomendationsPl} onChange={event => handleChangeRecPl(event.target.value)} />
      <TextField sx={{marginTop: 2, width: "100%"}} multiline label="Recomendations [ru]" value={recomendationsRu} onChange={event => handleChangeRecRu(event.target.value)} />
      
      <Typography sx={{marginTop: 2}}>Risks</Typography>
      <Divider />
      <TextField sx={{marginTop: 2, width: "100%"}} multiline label="Risks [en]" value={risksEn} onChange={event => handleChangeRisksEn(event.target.value)} />
      <TextField sx={{marginTop: 2, width: "100%"}} multiline label="Risks [pl]" value={risksPl} onChange={event => handleChangeRisksPl(event.target.value)} />
      <TextField sx={{marginTop: 2, width: "100%"}} multiline label="Risks [ru]" value={risksRu} onChange={event => handleChangeRisksRu(event.target.value)} />

      <Typography sx={{marginTop: 2}}>{ t('additionalRecomendations') }</Typography>
      <Divider />
      <TextField sx={{marginTop: 2, width: "100%"}} multiline label="After [en]" value={afterEn} onChange={event => handleChangeAfterEn(event.target.value)} />
      <TextField sx={{marginTop: 2, width: "100%"}} multiline label="After [pl]" value={afterPl} onChange={event => handleChangeAfterPl(event.target.value)} />
      <TextField sx={{marginTop: 2, width: "100%"}} multiline label="After [ru]" value={afterRu} onChange={event => handleChangeAfterRu(event.target.value)} />
    </>
  );
};

export default RisksFields;
