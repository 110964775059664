import styles from './SummaryPage.module.css';
import 'moment/locale/ru';
import 'moment/locale/pl';
import { useEffect, useState } from "react";
import dayjs from 'dayjs';
import { IconButton, Typography } from '@mui/material';
import SummaryItem from './SummaryItem';
import AddIcon from '@mui/icons-material/Add';
import ConsentsBlock from '../../../ConsentsBlock';
import { trackBookingPage } from '../../../../../ga';


const SummaryPage = ({ records, services, masters, navigator, policyRef, policyError, onDelete }) => {
  useEffect(() => {
    trackBookingPage("SummaryPage");
  }, []);

  const handleAdd = () => {
    navigator.openInitPage()
  }
  return (
    <>
      <div className={styles.records}>
        { records.map(record => <SummaryItem key={record.id} record={record} services={services} masters={masters} onDelete={ records.length > 1 ? onDelete : null }/>) }

        <div className={styles.addMore}>
          <IconButton
            onClick={handleAdd}
            sx={{
              color: (theme) => theme.palette.primary.main,
            }}
          >
            <AddIcon />
          </IconButton>
        </div>
      </div>
      
      <div className={styles.consents}>
        <ConsentsBlock policyRef={policyRef} error={policyError} />
      </div>
      
    </>
  );
};

export default SummaryPage;