import * as React from 'react';
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Typography, styled } from "@mui/material";
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import CloseIcon from '@mui/icons-material/Close';
import { useMutation, useQueryClient } from 'react-query';
import { logAndReset } from '../../../utils/requestUtils';
import REQUESTS from '../../../net/requests';
import QUERY_KEYS from '../../../net/query-keys';
import { useTranslation } from 'react-i18next';

dayjs.extend(isToday);

const BlurryDialog = styled(Dialog)(({ theme }) => ({
  backdropFilter: "blur(5px)"
}));

const ConfirmDeleteMasterDialog = ({ open, onClose, master }) => {

  const { t } = useTranslation();
  const removeUserMutation = useMutation(REQUESTS.removeMaster(master.id))
  const queryClient = useQueryClient();

  const handleClose = () => {
    onClose();
  }

  const handleDelete = () => {
    removeUserMutation.mutate({}, {
      onSuccess: (data, error, variables, context) => {
        console.log(`Master was removed successfully!`)
        queryClient.invalidateQueries(QUERY_KEYS.MASTERS);
        
        setTimeout(() => {
          onClose();
        }, 3000)
      },
      onError: (error, variables, context) => {
        console.log(`onError:`, JSON.stringify(error))
        logAndReset(removeUserMutation);
      }
    });
  }

  return (
    <BlurryDialog onClose={handleClose} open={open}  maxWidth="sm">
      <DialogTitle sx={{ m: 0, paddingRight:7 }} id="customized-dialog-title">
        { t('confirmation') }
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 12,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ paddingTop: 0}}>
        <div>
          <Typography sx={{marginBottom: 2}}>{ t('areYouSureToDeleteMaster') }</Typography>
          {
            removeUserMutation.isLoading ? <Typography>Loading...</Typography> : 
              removeUserMutation.isError ? <Typography>Error: { removeUserMutation.error.response.status === 403 ? removeUserMutation.error.response.data.error.message : removeUserMutation.error.message}</Typography> : 
                removeUserMutation.isSuccess ? <Typography>{ t('masterWasRemoved') }</Typography> : 
                  <div>
                    <Button variant="outlined" onClick={handleDelete}>{ t('delete') }</Button>
                    <Button variant="outlined" onClick={handleClose} sx={{ marginLeft: 2}}>{ t('cancel') }</Button>
                  </div>
          }
        </div>
      </DialogContent>
    </BlurryDialog>
  );
};

export default ConfirmDeleteMasterDialog;
