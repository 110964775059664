import React, { useRef, useState } from "react";
import styles from "./ShowFeedbacks.module.css"
import { Paper, styled } from "@mui/material";
import ExpandMoreItem from "../../../components/widgets/ControlPanelItems/ExpandMoreItem2";
import FeedbacksList from "./FeedbacksList";
import { useTranslation } from "react-i18next";

const ProfilePaper = styled(Paper)(({ theme }) => ({
  maxWidth: 1100,
  width: "100%",
  paddingLeft: 16,
  paddingRight: 16,
  padding: theme.spacing(2),
  marginTop: 16,
  ...theme.typography.body2,
  textAlign: 'center',
}));

const ShowFeedbacks = ({ }) => {

  const { t } = useTranslation();

  return (
    <div className={styles.box}>
      <ProfilePaper variant="outlined" sx={{ borderRadius: 2 }}>
        <ExpandMoreItem text={ t("feedbacks") } sx={{ padding: 0}}>

          <FeedbacksList />

        </ExpandMoreItem>
      </ProfilePaper>
    </div>
  );
}

export default ShowFeedbacks;