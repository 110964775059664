import { CircularProgress, styled, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import QUERY_KEYS from "../../net/query-keys";
import REQUESTS from "../../net/requests";
import styles from  "./MainPage.module.css";
import FeaturedService from "../../components/widgets/FeaturedService";
import { useTranslation } from "react-i18next";
import PageLayout from "../../components/layouts/PageLayout";

const RESET_TIMEOUT = 150;
const ITEM_WIDTH = 312;

const useContainerDimensions = myRef => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const getDimensions = () => ({
      width: myRef.current.offsetWidth,
      height: myRef.current.offsetHeight
    })

    const handleResize = () => {
      setDimensions(getDimensions())
    }

    if (myRef.current) {
      setDimensions(getDimensions())
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [myRef])

  return dimensions;
};

const MainPage = () => {
  const targetRef = useRef();
  const videoRef = useRef();
  const sourceRef = useRef();
  const timer = useRef(null);
  // const dim = useContainerDimensions(targetRef)
  // console.log(`dim: `, dim)
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const maxW1300 = useMediaQuery('(max-width:1300px)');
  const maxW700 = useMediaQuery('(max-width:700px)');
  const { t } = useTranslation();
  
  const servicesRequest = useQuery(QUERY_KEYS.FEATURED_SERVICES, REQUESTS.getFeaturedServices)

  const testDimensions = () => {
    console.log(`testDimensions: ${targetRef.current}`)
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight
      });
      console.log(`setDimensions, w: ${targetRef.current.offsetWidth}`)
    }
    if (targetRef.current && videoRef.current) {
      console.log('sourceRef.current.src', sourceRef.current.src);
      if (targetRef.current.offsetWidth > 700 && sourceRef.current.src.indexOf("/face_time_main2.mp4") === -1) {
        sourceRef.current.src = "/face_time_main2.mp4"
        videoRef.current.load()
      }
      if (targetRef.current.offsetWidth <= 700 && sourceRef.current.src.indexOf("/face_time_main.mp4") === -1) {
        sourceRef.current.src = "/face_time_main.mp4"
        videoRef.current.load()
      }
    }
  }

  const nextTick = () => {
    console.log(`nextTick`)
    if (targetRef.current) {
      console.log(`call testDimensions`)
      testDimensions()
    } else {
      setTimeout(() => nextTick(), RESET_TIMEOUT)
    }
  }

  useLayoutEffect(() => {
    console.log(`useLayoutEffect`)
    if (targetRef.current) {
      testDimensions()
    } else {
      nextTick()
    }
  }, []);

  window.addEventListener('resize', () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(testDimensions, RESET_TIMEOUT);
  });

  const infoBlocks = [
    {
      id: 1,
      title1: t('mainInfoBlock1Title1'),
      title2: t('mainInfoBlock1Title2'),
      text: t('mainInfoBlock1Text'),
      image: "/static/img_info_1.webp"
    },
    {
      id: 2,
      title1: t('mainInfoBlock2Title1'),
      title2: t('mainInfoBlock2Title2'),
      text: t('mainInfoBlock2Text'),
      image: "/static/img_info_2.webp"
    },
    {
      id: 3,
      title1: t('mainInfoBlock3Title1'),
      title2: t('mainInfoBlock3Title2'),
      text: t('mainInfoBlock3Text'),
      image: "/static/img_info_3.webp"
    }
  ]

  return (
    <PageLayout>
      <div className={styles.mainPage} ref={targetRef}>
        <div className={styles.videoBox}>
          <video className={styles.bgVideo} preload="auto" playsInline autoPlay={true} loop muted={true} ref={videoRef}>
            <source type="video/mp4" ref={sourceRef}/>
          </video>
          {/* <div className={styles.videoContent}>
            <Typography sx={{ fontSize: maxW1300 ? 44 : 90, paddingLeft: 2, paddingRight: 2 }} align="center">FaceTime Cosmetology</Typography>
            <Typography sx={{ fontSize: maxW1300 ? 18 : 24, paddingLeft: 2, paddingTop: 3, paddingRight: 2 }} align="center">
              место, где позаботятся о твоей коже
            </Typography>
          </div> */}
        </div>

        <div className={styles.about}>
          <div className={styles.aboutText}>
            <p variant="h5">{ t('mainInfoTitle') }</p>
            <p variant="h6">{ t('mainInfoSubtitle') }</p>
            <p variant="h6">{ t('mainInfoText') }</p>
          </div>
          <div className={styles.aboutImage}>
            <img src="/static/img_first.webp" />
          </div>
        </div>

        <div className={styles.infoBlocksBox}>
          {
            infoBlocks.map(infoBlock => 
              <div key={infoBlock.id} className={styles.infoBlock}>
                <Typography variant="h2" sx={{ fontSize: 26 }}>{ infoBlock.title1 }</Typography>
                <Typography variant="h2" sx={{ fontSize: 26 }}>{ infoBlock.title2 }</Typography>
                <Typography className={styles.infoBlockText} sx={{marginTop: 3}}>{ infoBlock.text }</Typography>
                <img src={infoBlock.image} className={styles.infoBlockImage} />
              </div>
            )
          }
        </div>

        <div className={styles.featuredBox}>
          {
            servicesRequest.isLoading ? <CircularProgress /> :
              servicesRequest.isError ? <Typography variant="h6">Can't load services</Typography> :
                servicesRequest.data.sort((a, b) => a.id - b.id).map(service => <FeaturedService key={service.id} service={service} />)
          }
        </div>

        <p className={styles.pageFooter}></p>
      </div>
    </PageLayout>
  );
};

export default MainPage;
