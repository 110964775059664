
const TranslationTypes = {
  CONSENT_TEXT: 'consent_text',
  CONSENT_TITLE: 'consent_title',
  CONSENT_PAGE_TITLE: 'consent_page_title',
  CATEGORY_NAME: 'category_name',
  SERVICE_TITLE: 'service_title',
  SERVICE_TEXT: 'service_text',
  SERVICE_RISKS: 'service_risks',
  SERVICE_RECOMENDATIONS: 'service_recs',
  QUESTION_TEXT: 'question_text',
  SERVICE_SUBTITLE: 'service_subtitle',
  MASTER_NAME: 'master_name',
  MASTER_SURNAME: 'master_surname',
};

export default TranslationTypes;