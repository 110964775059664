import React, { useRef, useState } from "react";
import { Button, Card, TextField } from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import { logAndReset } from "../../../../../utils/requestUtils";
import QuestionTypes from "../../../../../constants/questionTypes";
import REQUESTS from "../../../../../net/requests";
import EditSimpleText from "./EditSimpleText";
import EditTextWithInput from "./EditTextWithInput";
import EditTextWithYesNo from "./EditTextWithYesNo";

const EditQuestions = ({ question }) => {

  var contentView
  switch (question.type) {
    case QuestionTypes.SIMPLE_TEXT:
      contentView = <EditSimpleText question={question} />
      break;
    case QuestionTypes.TEXT_WITH_INPUT:
      contentView = <EditTextWithInput question={question} />
      break;
    case QuestionTypes.TEXT_WITH_YES_NO:
      contentView = <EditTextWithYesNo question={question} />
      break;
    default:
      break;
  }

  return (
    <>
      { contentView }
    </>
  );
}

export default EditQuestions;