import { useSelector } from 'react-redux';
import styles from './MasterWidget.module.css'
import { Avatar, Typography } from '@mui/material';
import { masterDisplayName } from '../../../../utils/formatUtils';

const MasterWidget = ({ record, withPadding }) => {
  const lang = useSelector((state) => state?.lang).title.toLowerCase();
  
  const masterImageUrl = record.master ? record.master.imageUrl : '';
  const masterName = masterDisplayName(record.master, lang);
  
  return <div className={styles.serviceBox}>
    <Avatar alt={masterName} src={masterImageUrl} sx={{ width: 40, height: 40, marginLeft: withPadding ? '8px' : '0px' }} />
    <Typography>{ masterName }</Typography>
  </div>;
}

export default MasterWidget;