import * as React from 'react';
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, IconButton, TextField, Typography, styled } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useMutation, useQueryClient } from 'react-query';
import styles from "./RecordNoteDialog.module.css"
import REQUESTS from '../../../../../net/requests';
import QUERY_KEYS from '../../../../../net/query-keys';
import { logAndReset } from '../../../../../utils/requestUtils';
import { useTranslation } from 'react-i18next';

const BlurryDialog = styled(Dialog)(({ theme }) => ({
  backdropFilter: "blur(5px)"
}));

const RecordNoteDialog = ({ open, onClose, note, recordId, scheduleId, cacheKey }) => {
  const [text, setText] = React.useState(note?.text ?? "")
  const createMutation = useMutation(REQUESTS.createRecordNote())
  const updateMutation = useMutation(REQUESTS.updateRecordNote())
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const handleClose = () => {
    onClose();
  }

  const mutation = note ? updateMutation : createMutation
  const handleSave = () => {
    const data = {
      recordId,
      text
    }
    mutation.mutate(data, {
      onSuccess: (data, error, variables, context) => {
        queryClient.invalidateQueries(QUERY_KEYS.recordsOfSchedule(scheduleId));
        if (cacheKey) {
          queryClient.invalidateQueries(cacheKey);
        }
        
        setTimeout(() => {
          mutation.reset();
        }, 1000)
        
      },
      onError: (error, variables, context) => {
        console.log(`onError:`, JSON.stringify(error))
        logAndReset(mutation);
      }
    });
  }

  return (
    <BlurryDialog onClose={handleClose} open={open} fullWidth={true} maxWidth="sm" >
      <DialogTitle sx={{ m: 0, paddingRight:7 }} id="customized-dialog-title">
        { note ? t('profileNoteEdit') : t('profileNoteCreate') }
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 12,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ paddingTop: 0, paddingLeft: 2, paddingEnd: 2, paddingBottom: 2 }}>
        <div className={styles.content}>
        
        <TextField sx={{ m: 1 }} label="Text" fullWidth value={text} onChange={event => setText(event.target.value)} multiline={true} />
          

          { mutation.isLoading ? <CircularProgress sx={{ marginRight: 1 }} /> : 
              mutation.isSuccess ? <Typography sx={{ marginRight: 1 }}>{ t('saved') }</Typography> :
                <Button variant='outlined' sx={{ marginRight: 1 }} disabled={text === (note?.text ?? "")} onClick={handleSave}>{ t('save') }</Button> 
          }
        </div>
      </DialogContent>
    </BlurryDialog>
  );
};

export default RecordNoteDialog;
