import React, { useRef, useState } from "react";
import "./ConsentsList.css";
import ExpandMoreItem from "../ExpandMoreItem";
import { Button, Card, Checkbox, CircularProgress, MenuItem, Select, TextField } from "@mui/material";
import Text from "../../Text";
import ConsentTypes from "../../../../constants/consentTypes";
import { useMutation, useQuery } from "react-query";
import REQUESTS from "../../../../net/requests";
import { logAndReset } from "../../../../utils/requestUtils";
import QUERY_KEYS from "../../../../net/query-keys";
import ServerError from "../../ServerError/ServerError";
import EditConsentItem from "../EditConsentItem";

const ConsentsList = () => {

  const consentsRequest = useQuery(QUERY_KEYS.CONSENTS, REQUESTS.getAllConsents, { refetchOnMount: true })

  if (consentsRequest.isLoading) {
    return <CircularProgress />
  }
  if (consentsRequest.isError) {
    return <ServerError />
  }


  return (
    <>
      <div id="consents-list-box">
        {
          consentsRequest.data.map(item => <EditConsentItem key={item.id} consent={item} />)
        }
      </div>
    </>
  );
}

export default ConsentsList;