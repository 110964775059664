import React from "react";
import { useQuery } from "react-query";
import { CircularProgress, Typography } from "@mui/material";
import QUERY_KEYS from "../../../../../../net/query-keys";
import REQUESTS from "../../../../../../net/requests";
import ServerError from "../../../../ServerError/ServerError";
import { PieChart } from '@mui/x-charts/PieChart';
import styles from './AnalyticsMasters.module.css';
import MasterCard from "./MasterCard";

const AnalyticsMasters = ({ startTime, endTime }) => {
  

  const metricsRequest = useQuery(QUERY_KEYS.getMastersAnalytics(startTime, endTime), REQUESTS.getMastersAnalytics(startTime, endTime), { refetchOnMount: true });

  if (metricsRequest.isLoading) {
    return <CircularProgress />
  }
  if (metricsRequest.isError) {
    return <ServerError/>
  }

  const metrics = metricsRequest.data;
  
  return (
    <div className={styles.box}>
      <Typography sx={{ fontSize: 20 }}>Записи мастеров:</Typography>
      {
        metrics.sort((a, b) => a.masterId - b.masterId).map(item => <div key={item.masterId}>
            <MasterCard title={item.masterFullName} first="Всего" firstValue={item.records} second="Повторных записей" secondValue={item.regularClients} />
          </div>
        )
      }
    </div>
  );
}

export default AnalyticsMasters;